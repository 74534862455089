export const softSkillSuggestionList = [
  'Excellent problem solver thanks to his structured methodology to diagnose broad technical issues',
  'Quickly adapt to new technology and concepts (think out of the box)',
  'Can easily work independently',
  'Don\'t hesitate to ask for help',
  'Be Comfortable in a collaborative environment, openly communicate and reach across functional borders',
  'Can work in a team and contribute to a common objective in multi (cultural and/or geographical) environment',
  'Strong written and verbal communication skills for Technical/ Non-technical audiences',
  'Critical Thinker',
  'Demonstrate initiative and a proactive approach to daily tasks',
  'Meticulousness & focus on the details',
  'Shows enthusiasm and commitment',
  'Is open to valuable ideas and proposals of other and actively participate to the building of them.',
  'Good judgment skills',
  'Can Work under pressure and with tight deadlines',
  'Proactive, with a serious work ethic and goal-oriented approach',
  'Able to manage sensitive and confidential information',
  'Self-motivation and take responsibility',
  'Sense of ownership and pride in your performance and its impact on company’s success',
  'Able to demonstrate a high degree of flexibility including shift and out of hours working',
  'Take the right decisions thanks to his patiences and his strong analytical skills',
  'Be curious : One day without learning is a lost day',
  'Multi tasks skills'
];
