import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CvPageIndexService {
  private readonly totalPagesSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly currentPageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public readonly totalPagesObs: Observable<number> = this.totalPagesSubject.asObservable();
  public readonly currentPageIndexObs: Observable<number> = this.currentPageSubject.asObservable();

  constructor() {}

  public updateTotalPages(index: number): void {
    if (this.totalPagesSubject.value > index && this.currentPageSubject.value === this.totalPagesSubject.value) {
      this.updateCurrentPage(index);
    }
    this.totalPagesSubject.next(index);
  }
  public updateCurrentPage(index: number): void {
    this.currentPageSubject.next(index);
  }
}
