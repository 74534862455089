<div class="page">
  <div class="header">
    <div>
      <h1>{{ 'dashboard.title' | translate }}</h1>
      <h2
        *ngIf="status === ComponentStatus.OK"
        [innerHtml]="('dashboard.description' | translate) + ' ' + name + '!'"></h2>
    </div>
  </div>
  <div class="content">
    <app-general-info-card
      [profile]="profile"
      [status]="status"
      [statusCode]="statusCode"></app-general-info-card>

    <div class="actions">
      <app-action-card
        (clicked)="goToResumePage()"
        [text]="'dashboard.actions.resumes.description'"
        [buttonText]="'dashboard.actions.resumes.buttonText' | translate"></app-action-card>
      <app-action-card
        *ngIf="showCertificates"
        (clicked)="goToCertificatePage()"
        [text]="'dashboard.actions.certificates.description'"
        [buttonText]="'dashboard.actions.certificates.buttonText' | translate"></app-action-card>
    </div>
  </div>
  <div
    *ngIf="showColleaguesInUnitAndPractice || showWhoIsWorkingAtMyClient"
    class="colleagues-container">
    <app-colleagues></app-colleagues>
  </div>
</div>
