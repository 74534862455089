<div class="page">
  <h1>{{ 'certificate.title' | translate }}</h1>
  <h2 [innerHtml]="'certificate.description' | translate"></h2>

  <div [ngSwitch]="status">
    <div
      class="loading"
      *ngSwitchCase="ComponentStatus.LOADING">
      <app-loading></app-loading>
    </div>

    <div *ngSwitchCase="ComponentStatus.ERROR">
      <p class="text-danger">{{ 'certificate.errorMessage' | translate }}</p>
    </div>

    <div *ngSwitchDefault>
      <ng-container *ngIf="certificates?.length; else noCertificates">
        <div class="certificates-list">
          <div
            class="certificate-card-container"
            *ngFor="let certificate of certificates">
            <app-certification-card [certification]="certificate"></app-certification-card>
          </div>
        </div>
      </ng-container>
      <ng-template #noCertificates>
        <div
          class="no-certifications"
          [innerHtml]="'certificate.noCertificates' | translate"></div>
      </ng-template>
    </div>
  </div>
</div>
