import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SecurityContext,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ChatbotService } from 'src/app/services/chatbot.service';

interface Message {
  from: 'user' | 'chatbot';
  text: string;
}

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  @Input()
  public show: boolean;

  @Output()
  public closeChat: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('scrollBottom', { static: true })
  private readonly scrollBottom!: ElementRef<HTMLDivElement>;

  public messages: Message[] = [];
  public text: string = '';
  public recipient: string = 'be.my@devoteam.com';

  public constructor(private readonly sanitizer: DomSanitizer, private readonly chatbotService: ChatbotService) {}

  @HostListener('keydown.escape', ['$event'])
  public onEscape(_event: KeyboardEvent): void {
    this.close();
  }

  public ngOnInit(): void {
    this.messages.push({ from: 'chatbot', text: 'Hello, how can I help you?' });
    this.scrollToBottom();
  }

  public ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  public sendMessage(): void {
    const sanitizedMessage: string | null = this.sanitizeMessage(this.text);
    if (!sanitizedMessage) return;
    this.messages.push({ from: 'user', text: sanitizedMessage });
    this.chat(sanitizedMessage);
    this.text = '';
  }

  public close(): void {
    this.closeChat.emit(false);
  }

  public sanitizeMessage(message: string): string | null {
    if (message.trim() === '') {
      return null;
    }
    return this.sanitizer.sanitize(SecurityContext.HTML, message);
  }

  public encodeMessage(message: string): string {
    return encodeURIComponent(this.sanitizeMessage(message) || '');
  }

  private chat(question: string): void {
    this.chatbotService.chat(question).subscribe({
      next: (answer: string): void => {
        this.messages.push({ from: 'chatbot', text: answer });
      },
      error: (e: Error): void => {
        console.error('Chatbot error:', e);
      }
    });
  }

  private scrollToBottom(): void {
    const el: HTMLDivElement = this.scrollBottom.nativeElement;
    el.scrollTop = el.scrollHeight;
  }

  sendEmail(): void {
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${  encodeURIComponent(this.recipient)}`;
    window.open(mailtoLink, '_blank');
  }
}
