<div [ngSwitch]="status">
  <div
    class="loading"
    *ngSwitchCase="ComponentStatus.LOADING">
    <app-loading></app-loading>
  </div>

  <div *ngSwitchCase="ComponentStatus.ERROR">
    <p class="text-danger">{{ 'dashboard.errorMessage' | translate }}</p>
  </div>

  <div *ngSwitchDefault>
    <ng-container *ngIf="colleagues?.length; else noColleagues">
      <section class="colleagues">
        <div
          class="colleague-container"
          *ngFor="let colleague of colleagues">
          <app-colleague-card
            [colleague]="colleague"
            [activeTabName]="activeTabName"
            [showUnit]="showUnit"
            [showPractice]="showPractice"
            [showClient]="showClient"></app-colleague-card>
        </div>
      </section>
    </ng-container>
    <ng-template #noColleagues>
      <p
        class="no-colleague-text"
        *ngIf="!colleagues.length">
        {{ 'dashboard.colleagues.no-colleague' | translate }}
      </p>
    </ng-template>
  </div>
</div>
