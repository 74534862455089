<section
  #editor
  class="editor-info">
  <div
    #header
    class="header-info-wrapper">
    <div class="progress-wrapper">
      <div class="progress data-progress">
        <div class="progress-bar">
          <div
            style="--progress: {{ progressPercentage / 100 }}"
            class="percentage"></div>
        </div>
        <p class="percentage-number">{{ progressPercentage }}%</p>
      </div>
      <div class="progress-icon data-progress-icon">
        <app-progress-svg
          *ngFor="let item of progressBar"
          [item]="item"
          [fillColor]="item.complete ? '#f8485e' : 'gray'"
          [tooltip]="item.icon"
          [header]="header"
          [cvEditor]="editor?.parentElement"></app-progress-svg>
      </div>
    </div>
    <div class="button-wrapper">
      <app-dropdown-button
        *ngIf="!showSpinnerDownload"
        [label]="'shared.downloadcv' | translate"
        [disabled]="progressPercentage !== 100"
        [downloadOptions]="['.docx', '.pdf']"
        (downloadSelected)="downloadCv($event)"
        [tooltip]="progressPercentage < 100 ? 'Complete all the sections to download CV' : ''"
        class="data-cv-download-button"></app-dropdown-button>
      <span
        class="loader"
        *ngIf="showSpinnerDownload"></span>
      <app-button
        *ngIf="!showSpinnerPublish"
        [label]="'shared.publishCv' | translate"
        [disabled]="progressPercentage !== 100"
        (clicked)="publishCv()"
        [tooltip]="progressPercentage < 100 ? 'Complete all the sections to publish CV' : ''"
        class="data-cv-publish-button">
      </app-button>
      <span
        class="loader"
        *ngIf="showSpinnerPublish"></span>
      <app-double-arrow-up-svg
        class="scroll-button"
        (click)="scrollToTop()"
        [tooltip]="'Scroll to top'"></app-double-arrow-up-svg>
    </div>
  </div>
  <div class="intro-wrapper">
    <ng-container *ngIf="coachees.length > 0">
      <div class="coachees">
        <h2 class="coachees-title">{{ 'cv-generator.coachee' | translate }}</h2>
        <div class="coachees-dropdown">
          <select
            [name]="'Consultant'"
            [(ngModel)]="selectedCoacheeDto"
            (change)="onSelect()">
            <option
              *ngFor="let coachee of coachees; let i = index"
              [ngValue]="coachee">
              {{ coachee.name }}
            </option>
          </select>
        </div>
      </div>
    </ng-container>
    <h2>Welcome on the CV Generator</h2>
  </div>

  <!-- Editor -->
  <form
    *ngIf="form"
    [formGroup]="form">
    <div class="photo-editor-wrapper">
      <p class="pictureTitle">Choose a picture</p>
      <form
        [formGroup]="pictureForm"
        class="pictureContainer">
        <div class="pictureButtons">
          <app-button
            [primaryColor]="'white'"
            [secondaryColor]="'#f8485e'"
            [label]="'Use google photo'"
            (clicked)="getGooglePhoto()">
          </app-button>
          <br />
          <app-button
            [primaryColor]="'white'"
            [secondaryColor]="'#f8485e'"
            [label]="'Select picture'"
            (clicked)="fileInput.click()">
          </app-button>
          <input
            type="file"
            accept="image/jpeg, image/png"
            #fileInput
            (change)="onFileSelected($event)"
            style="display: none" />
        </div>
        <img
          referrerpolicy="no-referrer"
          class="cvPicture"
          [src]="picUrl" />
      </form>
    </div>
    <div
      class="cv-editor"
      *ngFor="let section of formUiData">
      <div
        class="section"
        [id]="section.sectionId"
        [ngStyle]="{ display: 'block' }">
        <p class="title">{{ section.section }}</p>
        <p class="section-description">{{ section.description }}</p>
        <div
          class="order-experiences"
          *ngIf="section.section === 'Experiences'">
          <app-icon
            class="icon"
            *ngIf="experiencesControl.length > 1"
            (click)="orderExperiences()"
            [icon]="'arrow-down-short-wide'"
            [tooltip]="'Sort by date'"
            [size]="'24'"></app-icon>
        </div>
        <ng-container *ngFor="let row of section.fields; let fieldIndex = index">
          <div
            class="row-wrapper"
            *ngIf="!row.group">
            <!-- For personal info: Name, Role, Summary -->
            <ng-container *ngFor="let field of row['row-fields']">
              <app-text-input
                *ngIf="field.type === 'text'"
                class="input"
                [formControlName]="field.value"
                [label]="field.label"
                [placeholder]="field.label"></app-text-input>
              <app-text-area
                *ngIf="field.type === 'text-area'"
                class="input"
                [id]="'summary'"
                [formControlName]="field.value"
                [label]="field.label"
                [placeholder]="'Summary'"></app-text-area>
              <app-autocomplete-input
                *ngIf="field.type === 'auto-complete' && field.list"
                class="input"
                [placeholder]="field.label"
                [formControlName]="field.value"
                [label]="field.label"
                [list]="field.list"></app-autocomplete-input>
            </ng-container>
          </div>
          <!-- For sections that have dynamic added fields (except Skills) like: educations, languages, certifications & experiences   -->
          <div
            cdkDropList
            class="fields-wrapper"
            (cdkDropListDropped)="onDropOrder($event, section.value)"
            *ngIf="row.group && section.value && !row.inline">
            <div *ngFor="let control of fieldActions[section.value].control(); let index = index">
              <p
                class="error"
                *ngIf="section.section === 'Experiences' && getEmptyExperienceFields(control)">
                *Please make sure to fill in: <span class="empty-fields">{{ getEmptyExperienceFields(control) }} </span>
              </p>
              <div
                [ngClass]="{
                  'col-wrapper': true,
                  'error-wrapper': section.section === 'Experiences' && getEmptyExperienceFields(control)
                }"
                (mouseenter)="
                  icon.color =
                    section.value === 'experiences' && showContent(section.value + '-' + index) ? 'white' : '#3c3c3a'
                "
                (mouseleave)="icon.color = 'white'"
                [formArrayName]="section.value"
                [cdkDragDisabled]="section.value === 'experiences' && showContent(section.value + '-' + index)"
                cdkDrag>
                <div
                  cdkDragHandle
                  class="drag-handle">
                  <app-icon
                    #icon
                    [color]="'white'"
                    [icon]="'grip-vertical'"></app-icon>
                </div>
                <ng-container [formGroupName]="index">
                  <div class="toggle-wrapper">
                    <div class="toggle">
                      <p
                        (click)="toggleContent(section.value + '-' + index)"
                        class="field-title">
                        {{ sectionTitle(section.value, control.value) }}
                      </p>
                      <app-icon
                        class="icon"
                        (click)="removeField(index, section.value)"
                        [color]="'#333'"
                        [icon]="'trash'"
                        [size]="'16'"></app-icon>
                      <app-icon
                        *ngIf="!row['only-deletable']"
                        class="icon"
                        [color]="'#333'"
                        [icon]="showContent(section.value + '-' + index) ? 'chevron-up' : 'chevron-down'"
                        (click)="toggleContent(section.value + '-' + index)"
                        [size]="'16'"></app-icon>
                    </div>
                    <div
                      [id]="section.value + '-' + index"
                      [ngStyle]="{ display: 'none' }">
                      <div
                        class="row-wrapper"
                        *ngFor="let group of row.group">
                        <ng-container *ngFor="let field of group['row-fields']">
                          <app-datepicker
                            *ngIf="field.type === 'date'"
                            class="input"
                            [placeholder]="field.label"
                            [formControlName]="field.value"
                            [label]="field.label"></app-datepicker>
                          <app-year-datepicker
                            *ngIf="field.type === 'year-date'"
                            class="input"
                            [placeholder]="field.label"
                            [formControlName]="field.value"
                            [label]="field.label"></app-year-datepicker>
                          <app-text-input
                            *ngIf="field.type === 'text'"
                            class="input"
                            [placeholder]="field.label"
                            [formControlName]="field.value"
                            [label]="field.label"></app-text-input>
                          <app-text-area
                            *ngIf="field.type === 'text-area'"
                            class="input"
                            [placeholder]="field.label"
                            [formControlName]="field.value"
                            [label]="field.label"></app-text-area>
                          <div
                            class="toggle-container"
                            *ngIf="field.type === 'toggle'">
                            <label>Current project?</label>
                            <ui-switch
                              class="toggle"
                              [formControlName]="field.value"
                              color="#f8485e"
                              (click)="presentExperience(index)"></ui-switch>
                          </div>
                          <app-dropdown
                            *ngIf="field.type === 'drop-down' && field.list"
                            [formControlName]="field.value"
                            class="input"
                            [label]="field.label"
                            [list]="field.list">
                          </app-dropdown>
                          <app-multi-select
                            *ngIf="field.type === 'multi-select' && field.list"
                            [label]="field.label"
                            [formControlName]="field.value"
                            [suggestions]="field.list">
                          </app-multi-select>
                        </ng-container>
                        <!-- For nested form arrays like tasks under experiences -->
                        <div
                          *ngIf="group['category'] && group['group'] && group['value']"
                          [formArrayName]="group['value']"
                          class="nested-group">
                          <div
                            class="toggle-wrapper-nested"
                            (click)="toggleContent(section.value + '-' + index + '-' + group.value)">
                            <p class="field-title">{{ group['category'] }}</p>
                            <app-icon
                              class="icon"
                              [color]="'#333'"
                              [icon]="
                                showContent(section.value + '-' + index + '-' + group.value)
                                  ? 'chevron-up'
                                  : 'chevron-down'
                              "
                              [size]="'16'"></app-icon>
                          </div>
                          <div>
                            <div
                              cdkDropList
                              (cdkDropListDropped)="onDropOrder($event, group.value, index)"
                              [id]="section.value + '-' + index + '-' + group.value">
                              <div
                                cdkDrag
                                class="nested-content"
                                *ngFor="let task of fieldActions[group.value].control(index); let i = index">
                                <div
                                  [formGroupName]="i"
                                  *ngFor="let groupItem of group['group']">
                                  <div
                                    (mouseenter)="icon.color = '#3c3c3a'"
                                    (mouseleave)="icon.color = 'white'"
                                    *ngFor="let fieldItem of groupItem['row-fields']"
                                    class="nested-group-wrapper">
                                    <div
                                      cdkDragHandle
                                      class="drag-handle">
                                      <app-icon
                                        #icon
                                        [color]="'white'"
                                        [icon]="'grip-vertical'"></app-icon>
                                    </div>
                                    <app-text-input
                                      *ngIf="fieldItem.type === 'text'"
                                      [placeholder]="fieldItem.label"
                                      class="input"
                                      [formControlName]="fieldItem.value"></app-text-input>
                                    <app-icon
                                      class="icon"
                                      (click)="removeField(i, group.value, section.value, index)"
                                      [color]="'#333'"
                                      [icon]="'trash'"
                                      [size]="'16'">
                                    </app-icon>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            class="lighter-button"
                            (click)="addField(group.value, index, section.value)">
                            {{ group['button'] }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- For sections that have a main wrappers inside the fields like technical and soft skills -->
          <p class="categoryTitle">{{ row.category }}</p>
          <div
            cdkDropList
            (cdkDropListDropped)="onDropOrder($event, row.value)"
            class="col-wrapper-parent"
            *ngIf="row.group && row.value && !row.inline && fieldActions[row.value].control().length">
            <ng-container *ngFor="let item of progressBar">
              <ng-container *ngIf="item.linkSection === 'skills' && row.value === 'softSkills' && !item.complete">
                <p
                  class="skills-error-control"
                  *ngIf="!hasFilledSoftSkills()">
                  You have to select 5 skills
                </p>
              </ng-container>
            </ng-container>
            <div
              [ngClass]="row.value === 'softSkills' ? 'col-wrapper no-border' : 'col-wrapper'"
              (mouseenter)="icon.color = '#3c3c3a'"
              (mouseleave)="icon.color = 'white'"
              *ngFor="let control of fieldActions[row.value].control(); let index = index"
              [formArrayName]="row.value"
              cdkDrag>
              <div
                [ngClass]="{ 'drag-soft': row.value === 'softSkills' }"
                cdkDragHandle
                class="drag-handle">
                <app-icon
                  #icon
                  [color]="'white'"
                  [icon]="'grip-vertical'"></app-icon>
              </div>
              <ng-container [formGroupName]="index">
                <div class="toggle-wrapper">
                  <div
                    *ngIf="row.value !== 'softSkills'"
                    class="toggle">
                    <p
                      *ngIf="!row['only-deletable']"
                      (click)="toggleContent(row.value + '-' + index)"
                      class="field-title">
                      {{ sectionTitle(row.value, control.value) }}
                    </p>
                    <div *ngIf="row.value !== 'softSkills'">
                      <app-icon
                        class="icon"
                        (click)="removeField(index, row.value)"
                        [color]="'#333'"
                        [icon]="'trash'"
                        [size]="'16'">
                      </app-icon>
                    </div>
                    <app-icon
                      *ngIf="!row['only-deletable']"
                      class="icon"
                      [color]="'#333'"
                      [icon]="showContent(row.value + '-' + index) ? 'chevron-up' : 'chevron-down'"
                      (click)="toggleContent(row.value + '-' + index)"
                      [size]="'16'"></app-icon>
                  </div>

                  <div
                    [id]="row.value + '-' + index"
                    [ngStyle]="{ display: row.value === 'softSkills' ? 'block' : 'none' }">
                    <div
                      class="row-wrapper"
                      *ngFor="let group of row.group">
                      <ng-container *ngFor="let field of group['row-fields']">
                        <app-datepicker
                          *ngIf="field.type === 'date'"
                          class="input"
                          [formControlName]="field.value"
                          [placeholder]="field.label"
                          [label]="field.label"></app-datepicker>
                        <div
                          *ngIf="row.value === 'softSkills'; else otherSection"
                          class="soft-skills">
                          <app-autocomplete-input
                            *ngIf="field.type === 'auto-complete' && field.list"
                            (click)="updateSelectedSoftSkills(index)"
                            (valueChanged)="updateSelectedSoftSkills(index)"
                            (isFocused)="icon.color = 'white'"
                            class="input"
                            [placeholder]="'Soft Skill'"
                            [formControlName]="field.value"
                            [list]="softSkillList"></app-autocomplete-input>
                        </div>
                        <ng-template #otherSection>
                          <app-text-input
                            *ngIf="field.type === 'text'"
                            class="input"
                            [placeholder]="field.label"
                            [formControlName]="field.value"
                            [label]="field.label"></app-text-input>
                        </ng-template>

                        <app-text-area
                          *ngIf="field.type === 'text-area'"
                          class="input"
                          [placeholder]="field.label"
                          [formControlName]="field.value"
                          [label]="field.label"></app-text-area>
                        <app-dropdown
                          *ngIf="field.type === 'drop-down' && field.list"
                          class="input"
                          [label]="field.label"
                          [formControlName]="field.value"
                          [list]="field.list">
                        </app-dropdown>
                        <app-multi-select
                          *ngIf="field.type === 'multi-select' && field.list"
                          [label]="field.label"
                          [formControlName]="field.value"
                          [suggestions]="field.list">
                        </app-multi-select>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- inline sections -->
          <div
            cdkDropList
            class="fields-wrapper"
            (cdkDropListDropped)="onDropOrder($event, section.value)"
            *ngIf="row.group && section.value && row.inline">
            <div
              class="col-wrapper inline-col-wrapper"
              (mouseenter)="icon.color = '#3c3c3a'"
              (mouseleave)="icon.color = 'white'"
              *ngFor="let control of fieldActions[section.value].control(); let index = index"
              [formArrayName]="section.value"
              cdkDrag>
              <div
                cdkDragHandle
                class="drag-handle">
                <app-icon
                  #icon
                  [color]="'white'"
                  [icon]="'grip-vertical'"></app-icon>
              </div>
              <ng-container [formGroupName]="index">
                <div *ngFor="let group of row.group">
                  <div class="inline-row-wrapper">
                    <ng-container *ngFor="let field of group['row-fields']">
                      <app-datepicker
                        *ngIf="field.type === 'date'"
                        class="input"
                        [placeholder]="field.label"
                        [formControlName]="field.value"
                        [label]="field.label"></app-datepicker>
                      <app-year-datepicker
                        *ngIf="field.type === 'year-date'"
                        class="input"
                        [placeholder]="field.label"
                        [formControlName]="field.value"
                        [label]="field.label"></app-year-datepicker>
                      <app-text-input
                        *ngIf="field.type === 'text'"
                        class="input"
                        [placeholder]="field.label"
                        [formControlName]="field.value"
                        [label]="field.label"></app-text-input>
                      <app-dropdown
                        *ngIf="field.type === 'drop-down' && field.list"
                        [formControlName]="field.value"
                        class="input"
                        [label]="field.label"
                        [list]="field.list">
                      </app-dropdown>
                    </ng-container>
                    <div class="inline-row-actions">
                      <app-icon
                        class="icon"
                        (click)="removeField(index, section.value)"
                        [color]="'#333'"
                        [icon]="'trash'"
                        [size]="'16'"></app-icon>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <app-button
            *ngIf="section.fields[fieldIndex]['button']"
            [id]="row.value!.toString()"
            (clicked)="addField(row.value)"
            label="{{ section.fields[fieldIndex]['button'] }}"></app-button>
        </ng-container>
        <app-button
          *ngIf="section.button"
          (clicked)="addField(section.value)"
          label="{{ section.button }}"></app-button>
      </div>
    </div>
  </form>
</section>
