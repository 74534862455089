import { Component, OnInit, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Certification, certifications } from 'src/app/pages/certifications/certifications';
import { QuizService } from 'src/app/services/quiz.service';
import { ToastService } from '../../shared/components/toast/toast.service';

declare let Prism: any;

interface Question {
  question_text: string;
  options: { [key: string]: { option_text: string; explanation?: string } };
  correct_answers: string[];
  category: string;
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuizComponent implements OnInit, AfterViewChecked {
  public quizName: string | null = null;
  public categories: string[] = [];
  public currentQuestion: Question | null = null;
  public selectedAnswer: string = '';
  public selectedCheckboxes: string[] = [];
  public isSubmitted: boolean = false;
  public showExplanation: boolean = false;
  public correctAnswersCount: number = 0;
  public totalQuestionsAnswered: number = 0;
  public isMultipleChoice: boolean = false;
  public currentCertification: Certification | null;

  private quizId: string | null = null;
  private questions: Question[] = [];
  private filteredQuestions: Question[] = [];
  private selectedCategory: string = '';
  private answeredQuestions: string[] = [];

  currentQuestionIndex = 0;
  private quizQuestionsHistory: Question[] = [];
  private quizAnswersHistory: {
    answer: string;
    checkboxes: string[];
    isSubmitted: boolean;
  }[] = [];
  public showResetModal: boolean = false;

  public constructor(
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly toastService: ToastService,
    private readonly quizService: QuizService,
    private readonly translateService: TranslateService
  ) {}

  // Calculate the success percentage
  public get successPercentage(): number {
    return this.totalQuestionsAnswered > 0
      ? Math.floor((this.correctAnswersCount / this.totalQuestionsAnswered) * 100)
      : 0;
  }

  public get totalQuizQuestions(): number {
    return this.filteredQuestions.length;
  }

  public getCertificationInfo(id: string | null): Certification | null {
    if (!id) return null;
    const cert = certifications.find((cert) => cert.id === id);
    return cert ? cert : null;
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.quizId = id;
        this.currentCertification = this.getCertificationInfo(this.quizId);
        if (this.currentCertification) {
          this.fetchQuestions(this.quizId);
        } else {
          console.error('Certification not found');
        }
      }
    });
  }

  ngAfterViewChecked(): void {
    Prism.highlightAll();
  }

  public formatCodeText(codeText: string): string {
    return codeText.replace(/``(.*?)``/gs, (match, code) => {
      // Default to language-json if no specific match
      const languageClass = 'language-json';

      // If other languages, add here logic to detect them. Also add concerned prismjs in "styles" inside angular.json file

      return `<pre><code class="${languageClass}">${code}</code></pre><br>`;
    });
  }

  // Handle category selection change
  public handleCategoryChange(event: Event): void {
    const category: string = (event.target as HTMLSelectElement).value;

    this.storeCurrentState();
    this.selectedCategory = category;
    this.applyCategoryFilter();
    this.loadHistoryFromLocalStorage(false);
  }

  // Handle answer checkbox change for multiple-choice questions
  public handleCheckboxChange(key: string): void {
    if (this.selectedCheckboxes.includes(key)) {
      this.selectedCheckboxes = this.selectedCheckboxes.filter((answer) => answer !== key);
    } else {
      this.selectedCheckboxes.push(key);
    }
  }

  // Handle row click to select an answer
  public handleRowClick(key: string): void {
    if (this.isSubmitted) return;

    if (this.isMultipleChoice) {
      this.handleCheckboxChange(key);
    } else {
      this.selectedAnswer = key;
    }
  }

  // Handle quiz submission
  public handleSubmit(): void {
    this.isSubmitted = true;
    this.showExplanation = true;

    this.isMultipleChoice = this.currentQuestion!.correct_answers.length > 1;

    let isCorrect: boolean = false;
    if (this.isMultipleChoice) {
      const selectedCorrect: string = this.selectedCheckboxes.sort().toString();
      const correctAnswers: string = this.currentQuestion!.correct_answers.sort().toString();
      isCorrect = selectedCorrect === correctAnswers;
    } else {
      isCorrect = this.currentQuestion!.correct_answers.includes(this.selectedAnswer);
    }

    // Increment the counters only once here
    if (isCorrect) {
      this.correctAnswersCount++;
    }
    this.totalQuestionsAnswered++;
    this.updateQuizStats(isCorrect);
    this.saveHistoryToLocalStorage();
  }

  public resetQuizStats(): void {
    // Reset only local stats in the frontend (local storage)
    localStorage.removeItem(this.getLocalStorageKey('questionsHistory'));

    // Reset the local state
    this.correctAnswersCount = 0;
    this.totalQuestionsAnswered = 0;
    this.answeredQuestions = [];

    // Reset navigation history
    this.quizQuestionsHistory = [];
    this.quizAnswersHistory = [];
    this.currentQuestionIndex = 0;

    // Reset current question state
    this.selectedAnswer = '';
    this.selectedCheckboxes = [];
    this.isSubmitted = false;
    this.showExplanation = false;

    // Get a fresh question
    this.currentQuestion = this.getRandomQuestion();
  }

  // Helper method to store current question state
  private storeCurrentState(): void {
    if (this.currentQuestion) {
      this.quizQuestionsHistory[this.currentQuestionIndex] = this.currentQuestion;
      this.quizAnswersHistory[this.currentQuestionIndex] = {
        answer: this.selectedAnswer,
        checkboxes: [...this.selectedCheckboxes],
        isSubmitted: this.isSubmitted
      };
    }
  }

  // Helper method to restore question state
  private restoreState(index: number): void {
    this.currentQuestion = this.quizQuestionsHistory[index];
    const previousState = this.quizAnswersHistory[index];

    if (previousState) {
      this.selectedAnswer = previousState.answer;
      this.selectedCheckboxes = [...previousState.checkboxes];
      this.isSubmitted = previousState.isSubmitted;
      this.showExplanation = previousState.isSubmitted;
    } else {
      this.resetQuestionState();
    }
  }

  // Helper method to reset question state
  private resetQuestionState(): void {
    this.selectedAnswer = '';
    this.selectedCheckboxes = [];
    this.isSubmitted = false;
    this.showExplanation = false;
  }

  public nextQuestion(): void {
    if (!this.isSubmitted && (this.selectedAnswer || this.selectedCheckboxes.length > 0)) {
      this.handleSubmit();
    } else {
      this.storeCurrentState();
      this.currentQuestionIndex++;

      if (this.currentQuestionIndex < this.quizQuestionsHistory.length) {
        this.restoreState(this.currentQuestionIndex);
      } else {
        this.resetQuestionState();
        this.currentQuestion = this.getRandomQuestion();
      }
      this.saveHistoryToLocalStorage();
    }
  }

  public previousQuestion(): void {
    if (this.currentQuestionIndex > 0) {
      this.storeCurrentState();
      this.currentQuestionIndex--;
      this.restoreState(this.currentQuestionIndex);
      this.saveHistoryToLocalStorage();
    }
  }

  // Handle new question (reset current question and answer selections)
  public handleNewQuestion(): void {
    // Store current question and answer before moving to next
    if (this.currentQuestion) {
      this.quizQuestionsHistory.push(this.currentQuestion);
      this.quizAnswersHistory.push({
        answer: this.selectedAnswer,
        checkboxes: [...this.selectedCheckboxes],
        isSubmitted: this.isSubmitted
      });
    }

    this.selectedAnswer = '';
    this.selectedCheckboxes = [];
    this.isSubmitted = false;
    this.showExplanation = false;
    this.currentQuestionIndex++;

    // Get next question (always a new random question)
    const newQuestion = this.getRandomQuestion();
    if (newQuestion) {
      this.currentQuestion = newQuestion;
    }

    // Store current question and answer whenever the state changes in Local Storage
    this.saveHistoryToLocalStorage();
  }

  private updateQuizStats(isCorrect: boolean): void {
    if (this.currentQuestion) {
      // question is added to the answered questions
      if (!this.answeredQuestions.includes(this.currentQuestion.question_text)) {
        this.answeredQuestions.push(this.currentQuestion.question_text);
      }
      const updatePayload = {
        quizId: this.quizId!,
        isCorrect
      };

      this.quizService.updateQuizStats(updatePayload).subscribe(
        (_response) => {},
        (error) => {
          console.log('API error:', error);
        }
      );
    }
  }

  // Fetch questions for the quiz based on quizId
  private fetchQuestions(id: string): void {
    const url = `/assets/quiz/${id}.json`;
    this.http.get<Question[]>(url).subscribe(
      (data) => {
        this.questions = data;
        this.extractCategories();
        this.applyCategoryFilter();
        this.loadHistoryFromLocalStorage();
      },
      (error) => console.error('Error loading questions:', error)
    );
  }

  // Extract categories from the list of questions
  private extractCategories(): void {
    this.categories = Array.from(new Set(this.questions.map((q) => q.category)));
  }

  private applyCategoryFilter(): void {
    // Save current stats before filtering
    const savedCorrectCount = this.correctAnswersCount;
    const savedTotalAnswered = this.totalQuestionsAnswered;

    // Apply filter
    this.filteredQuestions = this.selectedCategory
      ? this.questions.filter((q) => q.category === this.selectedCategory)
      : [...this.questions];

    console.log('Filtered Questions Length:', this.filteredQuestions.length);

    // Restore stats if necessary
    if (savedTotalAnswered === 0) {
      this.loadQuizStats();
    } else {
      this.correctAnswersCount = savedCorrectCount;
      this.totalQuestionsAnswered = savedTotalAnswered;
    }

    // Ensure the current question matches the selected category
    if (!this.currentQuestion || (this.selectedCategory && this.currentQuestion.category !== this.selectedCategory)) {
      this.currentQuestion = this.getRandomQuestion();
      this.resetQuestionState();
    }
  }

  // Load quiz stats from quiz history
  private loadQuizStats(): void {
    if (this.correctAnswersCount === 0 && this.totalQuestionsAnswered === 0) {
      // Reset counters before calculating
      this.correctAnswersCount = 0;
      this.totalQuestionsAnswered = 0;

      // Calculate from all history
      this.quizAnswersHistory.forEach((answerRecord, index) => {
        if (answerRecord && answerRecord.isSubmitted) {
          this.totalQuestionsAnswered++;
          const question = this.quizQuestionsHistory[index];

          if (question) {
            if (question.correct_answers.length > 1) {
              // Multiple choice question
              const selectedCorrect: string = answerRecord.checkboxes.sort().toString();
              const correctAnswers: string = question.correct_answers.sort().toString();
              if (selectedCorrect === correctAnswers) {
                this.correctAnswersCount++;
              }
            } else if (question.correct_answers.includes(answerRecord.answer)) {
              // Single choice question
              this.correctAnswersCount++;
            }
          }
        }
      });
    }
  }

  // Get a random question from the filtered list
  private getRandomQuestion(): Question | null {
    if (this.filteredQuestions.length === 0) return null;

    // If there are questions in the history, return the next unanswered one
    if (this.currentQuestionIndex < this.quizQuestionsHistory.length) {
      return this.quizQuestionsHistory[this.currentQuestionIndex];
    }

    // If all questions from history have been answered, get a new random question
    const questionsInHistory = new Set(this.quizQuestionsHistory.map((q) => q?.question_text));
    let unansweredQuestions: Question[] = this.filteredQuestions.filter(
      (q: Question): boolean => !questionsInHistory.has(q.question_text)
    );

    // If all questions have been answered, reset
    if (unansweredQuestions.length === 0) {
      unansweredQuestions = [...this.filteredQuestions];
    }

    const randomIndex: number = Math.floor((Date.now() * Math.random()) % unansweredQuestions.length);
    const question: Question = unansweredQuestions[randomIndex];

    this.isMultipleChoice = question.correct_answers.length > 1;
    return question;
  }

  // Generate a unique key for local storage based on quizId and category
  private getLocalStorageKey(stat: string): string {
    return `${this.quizId}_${this.selectedCategory}_${stat}`;
  }

  // Save and load quiz history to local storage
  private saveHistoryToLocalStorage(): void {
    const key = this.getLocalStorageKey('questionsHistory');
    localStorage.setItem(
      key,
      JSON.stringify({
        questions: this.quizQuestionsHistory,
        answers: this.quizAnswersHistory,
        currentIndex: this.currentQuestionIndex,
        correctAnswersCount: this.correctAnswersCount,
        totalQuestionsAnswered: this.totalQuestionsAnswered
      })
    );
  }

  private loadHistoryFromLocalStorage(resetStats: boolean = true): void {
    const key = this.getLocalStorageKey('questionsHistory');
    const saved = localStorage.getItem(key);

    // Save current stats if we don't want to reset them
    const savedCorrectCount = this.correctAnswersCount;
    const savedTotalAnswered = this.totalQuestionsAnswered;

    if (saved) {
      const data = JSON.parse(saved);
      this.quizQuestionsHistory = data.questions;
      this.quizAnswersHistory = data.answers;
      this.currentQuestionIndex = data.currentIndex;

      if (resetStats) {
        this.correctAnswersCount = data.correctAnswersCount || 0;
        this.totalQuestionsAnswered = data.totalQuestionsAnswered || 0;
      }

      // Restore current question if it exists
      if (this.quizQuestionsHistory[this.currentQuestionIndex]) {
        this.currentQuestion = this.quizQuestionsHistory[this.currentQuestionIndex];
        const state = this.quizAnswersHistory[this.currentQuestionIndex];
        if (state) {
          this.selectedAnswer = state.answer;
          this.selectedCheckboxes = state.checkboxes;
          this.isSubmitted = state.isSubmitted;
          this.showExplanation = state.isSubmitted;
        }
      } else {
        // If there's no current question in history, get a new one
        this.currentQuestion = this.getRandomQuestion();
      }

      // Update the answeredQuestions array
      this.answeredQuestions = this.quizQuestionsHistory
        .filter((q, index) => this.quizAnswersHistory[index] && this.quizAnswersHistory[index].isSubmitted)
        .map((q) => q.question_text);
    } else {
      // If there's no saved history, start with a new question
      this.currentQuestion = this.getRandomQuestion();
    }

    // Restore the stats if we don't want to reset them
    if (!resetStats) {
      this.correctAnswersCount = savedCorrectCount;
      this.totalQuestionsAnswered = savedTotalAnswered;
    }
  }
  public openResetModal(): void {
    this.showResetModal = true;
  }

  public cancelReset(): void {
    this.showResetModal = false;
  }

  public confirmReset(): void {
    this.showResetModal = false;
    this.resetQuizStats();
    this.showResetTooltip();
  }

  private showResetTooltip(): void {
    try {
      this.toastService.showSuccess(this.translateService.instant('certificate.quiz.stats.reset.toast.success'));
    } catch (error) {
      console.error('Error showing reset tooltip:', error);
      this.toastService.showError(this.translateService.instant('certificate.quiz.stats.reset.toast.error'));
    }
  }
}
