<div
  class="alert alert-{{ type }}"
  role="alert"
  #alert>
  {{ title }}
  <button
    type="button"
    class="close"
    data-dismiss="alert"
    aria-label="Close"
    (click)="dismiss(alert)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
