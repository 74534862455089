<div class="timer-container">
  <div class="timer" [class.extra-time]="isTimeLimitReached">
    <img
      src="assets/quiz/timer.png"
      width="25px"/>
    <span class="time">{{ formatTime(time) }}</span>
  </div>

  <div class="button-container">
    <button (click)="pauseTimer()">
      {{ isPaused ? 'Resume' : 'Pause' }}
    </button>
    <button (click)="resetTimer()">
      Reset
    </button>
  </div>
</div>
