import { CvGuidelines } from 'src/app/models/cv-guidelines.interface';

const cvGuidelines: CvGuidelines[] = [
  {
    title: 'personal-details',
    description: `
        <p>
          The purpose of this section is to introduce yourself to the client and answer the following questions: 
          "Who are you?" and "What is your current position/role?"
        </p>
        <p>Please ensure that the font used is Arial 12.</p>
        <p>
          For your Role/Position, provide the exact name of your job position. You can find a list of available job names
          on the Intranet via the link:
        </p>
        <p>  
          <a href='https://sites.google.com/devoteam.com/boost-your-career/job-descriptions-en' target='_blank'>
          https://sites.google.com/devoteam.com/boost-your-career/job-descriptions-en
          </a>. 
        </p>
        <p>
          You can either choose a name yourself or ask your manager for guidance.
        </p>
        <p>
          If you find a job name from the list of available jobs on Intranet that suits you, that would be your 
          first choice for your job title. If none of the job names from the list is appropriate, you can select 
          a job from the SCALE nomenclature. If you're unsure, please ask your People Manager.
        </p>
      `,
    img: 'personal-details.png'
  },
  {
    title: 'degrees',
    description: `
        <p>
          The education section of your application is a critical part of the hiring process as it helps the hiring manager 
          evaluate whether you possess the necessary qualifications for the job.
        </p>
        <p>
          Please note that completing this section is mandatory.
        </p>
        <p>
          When filling out this section, please include the name of the institution, school, or university you attended, 
          as well as the start and end year of your enrollment.
        </p>
        <p>
          Additionally, it is essential to list your obtained degrees in reverse chronological order, starting with your most 
          recent degree and working your way back to your oldest. This ordering will help highlight your most relevant and recent 
          educational accomplishments.
        </p>
      `,
    img: 'degrees.png'
  },
  {
    title: 'languages',
    description: `
        <p>
          Please provide a complete list of all the languages you speak, including your proficiency level in each language.
        </p>
        <p>
          When listing your languages, please prioritize by putting your mother tongue at the top of the list, followed by 
          any languages in which you have an advanced level of proficiency, and then any languages in which you have an 
          intermediate or low level of proficiency.
        </p>
        <p>
          To assist you in accurately assessing your proficiency level, we recommend utilizing the self-assessment tool found 
          in <a href='https://docs.google.com/presentation/d/1aIU-wgOf08DLkSfEC8p7GDs0mvDn24OH/edit#slide=id.p14' target='_blank'>Annex 4</a>. 
          This tool can help you evaluate your language abilities and assign an appropriate proficiency level for each language you list.
        </p>
      `,
    img: 'languages.png'
  },
  {
    title: 'summary',
    description: `
        <p>
          The Summary section of your CV is the most crucial part as it's the first thing the hiring manager will read, 
          and it can greatly impact their perception of you. Therefore, it's essential to ensure that it's professional, 
          attractive, and compelling. Remember, the first impression lasts!
        </p>
        <p>
          The ideal length for your Summary section is between five lines and a half page.
        </p>
        <p>
          Your Summary should briefly describe your story, including your current tasks and projects, the value you bring 
          to the company, and your future plans. It's essential to avoid using personal pronouns like "I" or "me" and instead 
          focus on highlighting your achievements and contributions.
        </p>
        <p>
          To gain a better understanding of how to structure your Summary, you can review the CVs of other consultants or 
          senior managers. However, it's crucial to personalize your Summary and make it unique to you, highlighting your 
          strengths and qualifications.
        </p>
        <p>
          <b>Examples</b>
        </p>
        <p>
          <i>
            "As a Business Analyst, practice manager and project Manager in the enterprise service excellence department of 
            Devoteam, he has aided customers in public and private sectors on their quest to improve their day to day operations 
            concerning: Information Governance,Process Improvement, Case Management and Change Management. He uses his open mind 
            and curiosity to identify risks, requirements and client needs. After the elicitation and specification, he is capable 
            to translate them into creative solutions and manage the project to deliver the solution in time and within budget. 
            The pragmatic approach that he embraces make use of the BABOK, project management, change management and service management 
            processes and tool applicable and suitable for the job to be done. He has consultancy experience in sectors like: public, 
            healthcare, nonprofit, energy, manufacturing and telecommunication."
          </i>
        </p>
        <p>
          <i>
            "[NAME] has a broad familiarity with various programming utilities and languages, and possesses comprehensive knowledge 
            of both backend and frontend development requirements. He exhibits a high degree of proficiency in all aspects of the 
            development process, and works effectively as a team player. With over 5 years of related experience, he possesses excellent 
            technical abilities to bring to any project."
          </i>
        </p>
        <p>
          <i>
            "[NAME] is a meticulous and detail-oriented individual who adeptly merges her visual expertise with her proficiency in 
            clean and efficient coding practices. With a solid foundation in visual arts and significant experience as a Front End 
            Developer, she is a valuable addition to any team seeking to develop exceptional Front-end applications."
          </i>
        </p>
      `,
    img: 'summary.png'
  },
  {
    title: 'certifications',
    description: `
        <p>
          Certifications are a valuable way to demonstrate your knowledge and expertise in your field. To make the most of your certifications,
          please keep the following guidelines in mind:
        </p>
        <p>
          Ensure that the certification title is in Arial 12 font and that the content of the certifications is aligned with the information 
          you have provided in the previous sections of your CV. For example, if you're seeking a job in project management, including 
          certifications like Prince2, ITIL, or Scrum Master can be highly beneficial.
        </p>
        <p>
          Please provide all relevant certification details, including the Name of the certification, Certification ID, Issuer Name, and start 
          and end periods of the certification (if applicable). To ensure clarity and readability, list your certifications in reverse chronological 
          order, from the most recent to the oldest.
        </p>
        <p>
          When listing the Certification name, please ensure that it's capitalized correctly and validated on the official website of the 
          certification issuer. For example, use "Professional Scrum Product Owner" instead of "Scrum Product Owner."
        </p>
        <p>
          Please note that the Certifications section is optional, and if you don't have any certifications to list, you can leave this section empty.
        </p>
      `,
    img: 'certifications.png'
  },
  {
    title: 'technical-skills',
    description: `
        <p>
          The Technical Skills section of your CV is an excellent opportunity to highlight your proficiency in various technologies and programming 
          languages. Please keep the following guidelines in mind when listing your technical skills:
        </p>
        <p>
          Select at least five categories from the provided list of technical skills categories (such as Data Science, Backend Development, 
            Frontend Development, Mobile Development, and Networking). For each category you choose, describe your skills in detail.
        </p>
        <p>
          When listing technologies within a category, please use proper capitalization and ensure that the name of the technology is accurate by 
          verifying it on the official website. For example, use "Automation: Ansible, Python, OpenShift" instead of "Automation: ansible, Python, openshift."
        </p>
        <p>
          You can choose one or multiple categories from the list of technical skills categories. Be sure to specify the technologies that belong to each category.
        </p>
        <p>
          Remember to keep your technical skills section up-to-date and relevant to the job you're applying for. By following these guidelines, 
          you can showcase your expertise in a clear and professional manner.
        </p>
      `,
    img: 'technical-skills.png'
  },
  {
    title: 'functional-skills',
    description: `
        <p>
          Highlighting your soft skills in your CV is an excellent way to showcase your personality and traits that make you an ideal candidate 
          for the job. Please keep the following guidelines in mind when describing your soft skills:
        </p>
        <p>
          Avoid copying soft skills descriptions from others. Formulate individual sentences that accurately describe your skills and personality traits.
        </p>
        <p>
          Refer to the list of suggested soft skills in <a href='https://docs.google.com/presentation/d/1aIU-wgOf08DLkSfEC8p7GDs0mvDn24OH/edit#slide=id.p12' 
          target='_blank'>Annex 3</a> for inspiration. You may find it helpful to use this list to help identify and describe your own soft skills.
        </p>
        <p>
          Remember to be honest and specific when describing your soft skills. Provide examples of situations in which you have demonstrated 
          these skills to support your claims.
        </p>
        <p>
          By following these guidelines, you can ensure that your soft skills section is unique and accurately reflects your personality and traits.
        </p>
      `,
    img: 'functional-skills.png'
  },
  {
    title: 'professional-experience',
    description: `
        <p>
          Your work experience section is an opportunity to showcase your professional accomplishments and demonstrate your suitability for the job. 
          Please keep the following guidelines in mind when describing your work experience:
        </p>
        <p>
          Make sure to highlight the most relevant work experiences for the current job opportunity. Tailor your descriptions to emphasize your 
          relevant skills and achievements.
        </p>
        <p>
          If you are a new starter or have a gap in your employment history, you can mention internal projects or other relevant experiences that 
          you have worked on during the intercontract period.
        </p>
        <p>
          For each work experience, include the name of the client, your job title, and the start and end dates of the position in the 
          "MMM YYYY" format (e.g. Feb 2023).
        </p>
        <p>
          If you are currently in a position, do not include an end date, but use "Present" instead.
        </p>
        <p>
          List your work experiences in reverse chronological order, from the most recent to the oldest. 
          <a href='https://docs.google.com/presentation/d/1aIU-wgOf08DLkSfEC8p7GDs0mvDn24OH/edit#slide=id.g194b1968ef2_0_19' target='_blank'>Annex 1</a> 
          and <a href='https://docs.google.com/presentation/d/1aIU-wgOf08DLkSfEC8p7GDs0mvDn24OH/edit#slide=id.g194b1968ef2_0_31' target='_blank'>Annex 2</a> 
          in the CV guidelines document can provide further guidance on formatting and content for this section.
        </p>
      `,
    img: 'professional-experience.png'
  },
  {
    title: 'context',
    description: `
        <p>
          The context section provides an overview of the project you worked on during your previous/recent mission.
        </p>
        <p>
          Start by providing the precise name of the project and a brief introduction to the client's activities and mission.
        </p>
        <p>
          <b>Examples</b>
        </p>
        <p>
          <i>
            <b>Deploy Anise Playbooks - Continuous Tests on OpenShift</b>
            <p>
              SMALS is a cloud services provider that offers services called G-cloud to government organizations. 
              The company relies on Red Hat technologies for PaaS (OpenShift), LaaS (OpenStack), and Cloud Forms 
              as a front-end interface. These services must be maintained and comply with SLAs. To proactively detect 
              and investigate issues before clients report them, it would be useful to conduct performance tests.
            </p>
          </i>
        </p>
        <p>
          <i>
            <b>Support Tool</b>
            <p>
              A cutting-edge support tool for the Point of Sale (POS) devices utilized in McDonald's restaurants 
              throughout Belgium. The solution boasts full automation and generates detailed reports to empower 
              the support team in incident resolution. The support tool features a package manager, device monitoring 
              capabilities, and shared configuration across multiple devices, among other features, to streamline 
              device management and enhance operational efficiency.
            </p>
          </i>
        </p>
      `,
    img: 'context.png'
  },
  {
    title: 'responsibilities',
    description: `
        <p>
          Describe your role and responsibilities during the project in no more than 6 sentences by starting with 
          "[NAME] was responsible for ...". Use third-person singular pronouns (he/she) when referring to yourself. 
          Answer the following questions: "What was my role in the project?" and "What tasks was I in charge of?". 
          Be specific and provide details that showcase your skills and achievements.
        </p>
        <p>
          <b>Examples</b>
        </p>
        <p>
          <i>
            "As a Senior Software Engineer, Jane Smith was responsible for leading a team of developers in the design 
            and development of a new web application for a client in the healthcare industry. Her tasks included:
            <ul>
              <li>Collaborating with the product owner to define requirements and prioritize features</li>
              <li>Designing the application architecture and selecting appropriate technologies</li>
              <li>Developing code, conducting code reviews, and ensuring code quality</li>
              <li>Mentoring junior developers and leading agile ceremonies</li>
              <li>Troubleshooting technical issues and providing solutions to ensure successful deployment</li>
              <li>Communicating project status and risks to stakeholders.</li>
            </ul>"
          </i>
        </p>
        <p>
          <i>
            "As a Front End Developer, Jane Smith was responsible for maintaining the company's two front-end web applications 
            and developing new features to enhance their functionality. This involved monitoring the performance of the applications, 
            troubleshooting any issues that arose, and implementing bug fixes and updates as needed. In addition to maintenance tasks, 
            She was also responsible for developing new features and functionalities to improve the user experience and meet the 
            evolving needs of the company's clients."
          </i>
        </p>
      `,
    img: 'responsibilities.png'
  },
  {
    title: 'achievements',
    description: `
        <p>
          In this section, you will demonstrate how your work on the project added value to the company and contributed to achieving the client's goals.
        </p>
        <p>
          Create an indented list of different achievements using bullet points. Start each bullet point with an uppercase letter in Arial 11 font and justify the text.
        </p>
        <p>
          Use categories to group related tasks if required, and indent the list accordingly.
        </p>
        <p>
          Start your sentences with past tense, past participle verbs, infinitive verbs, or nouns.
        </p>
        <p>
          <b>Examples</b>
        </p>
        <p>
          <i>
            <ul>
              <li>Reduced bugs backlog by 95% by implementing rigorous bug resolution processes.</li>
              <li>Improved flow execution performance by using multithreading, resulting in faster response times and improved user experience.</li>
              <li>Optimized queries execution performance by using correct covering indexes, resulting in faster data retrieval times.</li>
              <li>Patched all vulnerabilities discovered after an application audit, ensuring the solution was secure and compliant with industry standards.</li>
              <li>Reduced bug backlogs by 80% through rigorous testing and debugging practices, allowing for smoother application performance and enhanced user experience.</li>
              <li>Successfully integrated Google Analytics to track user behavior and gather valuable insights, enabling data-driven decision making and informed product development.</li>
              <li>Implemented on-screen guidance feature to help users navigate through the application more easily, resulting in increased user engagement and reduced churn rates.</li>
              <li>Successfully integrated external dashboards from a third-party library, providing users with access to additional data sources and improving overall functionality of the application.</li>
              <li>Designed and developed a new funnel layout to display data and statistics in a more intuitive and visually appealing manner, improving the overall user experience and allowing for easier data analysis.</li>
            </ul>
          </i>
        </p>
      `,
    img: 'achievements.png'
  },
  {
    title: 'technical-enviroment',
    description: `
        <p>
          Describe the technical tools and systems that you used during the project.
        </p>
        <p>
          Start with an uppercase in Arial 11 and justified.
        </p>
        <p>
          For the technology, use the official name and start with an uppercase. If multiple technologies were used, separate them with a slash (/).
        </p>
        <p>
          <b>Examples</b>
        </p>
        <p>
          <i>
            HTML5 / SASS / Angular / RxJS / TypeScript / NestJS / Java / Spring / Git / GitLab / Trello / Figma / Zeplin
          </i>
        </p>
      `,
    img: 'technical-environment.png'
  },
  {
    title: 'passport-template-guidelines',
    description: `
        <p>
          Make sure that the font used in your resume is Montserrat 7.
        </p>
        <p>
          Include a professional and clear headshot that only shows your head and shoulders.
        </p>
        <p>
          List your most recent and relevant work experiences, including a brief description of your role and responsibilities during each project.
        </p>
        <p>
          If possible, include the client's logo in front of each work experience.
        </p>
        <p>
          Ensure that your summary statement matches the one in your CV.
        </p>
        <p>
          Specify your experience level (e.g. Consultant, Senior Consultant, Lead Consultant, Principal Consultant, Expert Director) 
          for each work experience. If you're unsure, check with your People Manager.
        </p>
        <p>
          List your key competencies briefly under the "Key Competencies" section, and feel free to use the list of soft skills in 
          <a href='https://docs.google.com/presentation/d/1aIU-wgOf08DLkSfEC8p7GDs0mvDn24OH/edit#slide=id.p14' target='_blank'>Annex 4</a>.
        </p>
        <p>
          Include any degrees or certificates that you've obtained which are relevant to the job description. Note that skills like Excel 
          or PowerPoint may not be a good fit depending on the client's needs.
        </p>
        <p>
          List your spoken languages in order of proficiency, from C2 (native fluency) to A1 (beginner level).
        </p>
      `,
    img: 'one-pager-sample.png'
  },
  {
    title: 'resume-example-template',
    description:
      '<p>Download the <a href=\'./assets/files/resume-example-template.docx\' target=\'_blank\'>Resume Example Template</a></p>',
    img: ['resume-example-template-1.png', 'resume-example-template-2.png', 'resume-example-template-3.png']
  }
];

export default cvGuidelines;
