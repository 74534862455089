import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { User } from '@sentry/angular';
import { environment } from '../../environments/environment';
import { Resume } from '../models/resume';
import { getFileExtension } from '../shared/utils/get-file-extension';
import { MAX_FILE_SIZE } from '../shared/utils/constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResumesService {
  private readonly apiUrl: string = environment.apiUrl;

  public constructor(private readonly httpClient: HttpClient, private readonly authService: AuthService) {}

  public getAllResumes(): Observable<Resume[]> {
    return this.httpClient.get<Resume[]>(`${this.apiUrl}/resumes`);
  }

  public uploadResume(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, this.makeFileName(file.name));
    return this.httpClient.post(`${this.apiUrl}/resumes/upload`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public updateResume(id: number, properties: Partial<Resume>): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/resumes/update`, { id, properties });
  }

  public deleteResume(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/resumes/${id}`);
  }

  public downloadFile(id: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/resumes/${id}/download`, {
      responseType: 'blob' as 'json'
    });
  }

  public isFileNameAllowed(fileName: string): boolean {
    const regex: RegExp = /^[ a-zA-Z0-9&_\.-]+$/;
    return regex.test(fileName.toLowerCase());
  }

  public isFileTypeAllowed(fileName: string): boolean {
    const regex: RegExp = /\.docx?|\.pdf?|\.pptx/i;
    const extension: string | undefined = getFileExtension(fileName);
    if (!extension) return false;
    return regex.test(extension);
  }

  /**
   @size: size in bit
   */
  public isFileSizeAllowed(size: number): boolean {
    return size > 0 && size <= (MAX_FILE_SIZE * 1024 * 1024) / 8;
  }

  private makeFileName(filename: string): string {
    const extension: string = getFileExtension(filename) || '';
    const now: Date = new Date();
    const user: User = this.authService.user || ({} as User);

    // eslint-disable-next-line dot-notation
    return `CV - ${user['family_name']} ${user['given_name']} - ${now.getFullYear()} ${now.getMonth() + 1}${extension}`;
  }
}
