import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  public constructor(private readonly authService: AuthService, public jwtHelper: JwtHelperService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token: string | Promise<string> = this.jwtHelper.tokenGetter().toString();
    if (token && !this.isOldJwt(token) && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    this.authService.login(state.url);
    return false;
  }

  private isOldJwt(token: string): boolean {
    const decoded: {
      id?: string | undefined;
      email?: string | undefined;
    } | null = this.jwtHelper.decodeToken(token);
    if (!decoded) return true;
    return !decoded.id || !decoded.email;
  }
}
