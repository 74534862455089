<div class="card-wrapper">
  <ul
    id="tab-titles"
    class="titles-wrapper">
    <li
      class="title-wrapper"
      *ngFor="let tab of tabs"
      [class.active-tab]="tab.name === activeTab.name"
      (click)="setActivateTab(tab)">
      <h2 class="title">{{ tab.title | translate }}</h2>
      <span class="consultants-number">{{ tab.counter }}</span>
    </li>
  </ul>
  <ng-container
    *ngTemplateOutlet="
      activeTab.name === ColleaguesTabNames.NEIGHBOURS ? mapTabContent : activeTabContent
    "></ng-container>
</div>

<ng-template #activeTabContent>
  <div
    *ngIf="activeTab"
    class="body">
    <app-colleague-cards
      [colleaguesData]="activeTab.colleagues"
      [status]="status"
      [activeTabName]="activeTab.name"
      [showPractice]="activeTab.showPractice"
      [showUnit]="activeTab.showUnit"
      [showClient]="activeTab.showClient"></app-colleague-cards>
  </div>
</ng-template>

<ng-template #mapTabContent>
  <div
    id="map"
    *ngIf="allColleagues && allColleagues.length > 0">
    <app-map [colleagues]="allColleagues"></app-map>
  </div>
</ng-template>
