<div class="page">
  <h1>{{ 'guidelines.title' | translate }}</h1>
  <div class="row content">
    <div class="col-lg-3 titles">
      <ul *ngFor="let section of sections; let i = index">
        <li (click)="changeColor(i)">
          <a
            [routerLink]="['/guidelines', section.title]"
            [routerLinkActive]="'active'"
            >{{ section.title | removedash }}</a
          >
        </li>
      </ul>
    </div>
    <div
      *ngIf="selectedItem.title !== 'resume-example-template'; else resumeExampleTemplate"
      class="col-lg-9">
      <div class="row">
        <div class="col-lg-7 description">
          <div id="item.name">
            <h1>{{ selectedItem.title | removedash }}</h1>
            <ul>
              <li [innerHTML]="selectedItem.description"></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 images">
          <img
            src="./assets/img/new-guidelines/{{ selectedItem.img }}"
            alt="" />
        </div>
      </div>
    </div>
    <ng-template #resumeExampleTemplate>
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-7 description">
            <div class="row">
              <div id="item.name">
                <h1>{{ selectedItem.title | removedash }}</h1>
                <ul>
                  <li [innerHTML]="selectedItem.description"></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row example-img-wrapper">
            <div
              class="col-lg-3"
              *ngFor="let img of exampleTemplateImages | keyvalue">
              <img
                src="./assets/img/new-guidelines/{{ img.value }}"
                alt="" />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
