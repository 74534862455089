/* eslint-disable */
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CvGeneratorService } from '../../../services/cv-generator.service';
import { SubscriptionBaseDirective } from '../../../shared/directives/subscription-base.directive';
import { data } from '../../../../assets/cv-editor-ui-structure/cv-editor-ui';
import { CvEditorUi } from '../../../models/cv-editor-ui';
import {
  ControlKeys,
  DEFAULT_CERTIFICATE_ROW,
  DEFAULT_EDUCATION_ROW,
  DEFAULT_EXPERIENCE_ROW,
  DEFAULT_LANGUAGE_ROW,
  DEFAULT_SOFT_SKIILS_ROW,
  DEFAULT_TASK_ROW,
  DEFAULT_TECHNICAL_SKIILS_ROW
} from './constants';
import { IconComponent } from '../../../shared/components/icons/icon.component';
import { ProgressBar } from '../../../models/progress-bar';
import { TourService } from 'src/app/services/tour.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { softSkillSuggestionList } from '../../../../assets/cv-editor-ui-structure/soft-skill-suggestions';
import { User } from 'src/app/models/user';
import { ColleaguesService } from 'src/app/services/colleagues.service';
import { ColleagueDto } from '../../../models/coalleague-dto';

export interface FieldActions {
  [fieldName: string]: {
    initialFormValue: {};
    defaultRow: {};
    sectionTitleControl: string;
    control(index?: number): FormGroup[];
    // icons used for the progress bar
    icon?: string;
  };
}
@Component({
  selector: 'app-cv-editor',
  templateUrl: './cv-editor.component.html',
  styleUrls: ['./cv-editor.component.scss']
})
export class CvEditorComponent extends SubscriptionBaseDirective implements OnInit {
  @ViewChild('icon') icon!: IconComponent;
  formUiData: CvEditorUi[] = data;
  public form: FormGroup | null = null;
  public coachees: ColleagueDto[] = [];
  public user: User | undefined = undefined;
  public selectedCoacheeDto!: ColleagueDto;
  public picUrl: string | undefined = '';
  public showSpinnerDownload: boolean = false;
  public showSpinnerPublish: boolean = false;
  public selectedSoftSkills: string[] = [];
  public softSkillList: string[] = softSkillSuggestionList;
  public pictureForm: FormGroup = new FormGroup({
    selectedPicture: new FormControl('')
  });
  @ViewChild('fileInput') fileInput: ElementRef;

  public progressBar: ProgressBar[] | null = null;
  public progressPercentage: number = 0;
  public readonly controlKeys = ControlKeys;

  private readonly FORM_DEBOUNCE_TIME_MS = 1000;
  private readonly initialEducationFormValues = {
    [this.controlKeys.school]: '',
    [this.controlKeys.degree]: '',
    [this.controlKeys.educationEndDate]: ''
  };

  private readonly initialTechnicalSkillFormValues = {
    [this.controlKeys.technicalSkillsCategory]: '',
    [this.controlKeys.technicalSkillsName]: ''
  };

  private readonly initialSoftSkillFormValues = {
    [this.controlKeys.softSkill]: ''
  };

  private readonly initialCertificationFormValues = {
    [this.controlKeys.certificationName]: '',
    [this.controlKeys.certificationDate]: '',
    [this.controlKeys.certificationId]: '',
    [this.controlKeys.certificationIssuer]: ''
  };

  private readonly initialLanguageFormValues = {
    [this.controlKeys.languageName]: '',
    [this.controlKeys.languageLevel]: ''
  };

  private readonly initialTaskFormValues = {
    [this.controlKeys.taskName]: ''
  };

  private readonly initialExperienceFormValues = {
    [this.controlKeys.companyName]: '',
    [this.controlKeys.projectName]: '',
    [this.controlKeys.projectStartDate]: '',
    [this.controlKeys.projectEndDate]: '',
    [this.controlKeys.projectPresent]: '',
    [this.controlKeys.projectDescription]: '',
    [this.controlKeys.role]: '',
    [this.controlKeys.projectRoleDescription]: '',
    [this.controlKeys.tasks]: [],
    [this.controlKeys.environments]: ''
  };

  public readonly fieldActions: FieldActions = {
    educations: {
      initialFormValue: this.initialEducationFormValues,
      defaultRow: Object.assign({}, DEFAULT_EDUCATION_ROW),
      sectionTitleControl: 'school',
      control: (): FormGroup[] => this.educationControl.controls as FormGroup[],
      icon: 'Education'
    },
    languages: {
      initialFormValue: this.initialLanguageFormValues,
      defaultRow: Object.assign({}, DEFAULT_LANGUAGE_ROW),
      sectionTitleControl: 'languageName',
      control: (): FormGroup[] => this.languagesControl.controls as FormGroup[],
      icon: 'Languages'
    },
    technicalSkills: {
      initialFormValue: this.initialTechnicalSkillFormValues,
      defaultRow: Object.assign({}, DEFAULT_TECHNICAL_SKIILS_ROW),
      sectionTitleControl: 'technicalSkillsCategory',
      control: (): FormGroup[] => this.technicalSkillsControl.controls as FormGroup[]
    },
    softSkills: {
      initialFormValue: this.initialSoftSkillFormValues,
      defaultRow: Object.assign({}, DEFAULT_SOFT_SKIILS_ROW),
      sectionTitleControl: '',
      control: (): FormGroup[] => this.softSkillsControl.controls as FormGroup[]
    },
    certifications: {
      initialFormValue: this.initialCertificationFormValues,
      defaultRow: Object.assign({}, DEFAULT_CERTIFICATE_ROW),
      sectionTitleControl: 'certificationName',
      control: (): FormGroup[] => this.certificationsControl.controls as FormGroup[],
      icon: 'Certifications'
    },
    tasks: {
      initialFormValue: this.initialTaskFormValues,
      defaultRow: Object.assign({}, DEFAULT_TASK_ROW),
      sectionTitleControl: 'tasks',
      control: (index: number): FormGroup[] => {
        return this.tasksControls[index].controls as FormGroup[];
      }
    },
    experiences: {
      initialFormValue: this.initialExperienceFormValues,
      defaultRow: Object.assign({}, DEFAULT_EXPERIENCE_ROW),
      sectionTitleControl: 'companyName',
      control: (): FormGroup[] => this.experiencesControl.controls as FormGroup[],
      icon: 'Experiences'
    }
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly cvGeneratorService: CvGeneratorService,
    private readonly colleaguesService: ColleaguesService,
    private readonly translate: TranslateService,
    private readonly toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(TourService) private tourService: TourService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.getAllCoachees();
    this.loadFormData();
  }

  ngAfterViewInit(): void {
    this.tourService.launchCVEditorTour();
  }

  override ngOnDestroy(): void {
    this.cvGeneratorService.updateCvDataOnServer(this.form?.value, this.selectedCoacheeDto?.userId);
  }

  get educationControl(): FormArray {
    return this.form?.get(this.controlKeys.educations) as FormArray;
  }

  get languagesControl(): FormArray {
    return this.form?.get(this.controlKeys.languages) as FormArray;
  }

  get softSkillsControl(): FormArray {
    return this.form?.get(this.controlKeys.softSkills) as FormArray;
  }

  get technicalSkillsControl(): FormArray {
    return this.form?.get(this.controlKeys.technicalSkills) as FormArray;
  }

  get certificationsControl(): FormArray {
    return this.form?.get(this.controlKeys.certifications) as FormArray;
  }

  get tasksControls(): FormArray[] {
    const tasksControls: FormArray[] = [];
    if (this.experiencesControl) {
      for (const experienceGroup of this.experiencesControl.controls) {
        const tasksControl = experienceGroup.get(this.controlKeys.tasks) as FormArray;
        tasksControls.push(tasksControl);
      }
    }
    return tasksControls;
  }

  get experiencesControl(): FormArray {
    return this.form?.get(this.controlKeys.experiences) as FormArray;
  }

  public getAllCoachees() {
    this.colleaguesService
      .getAllCoachees()
      .pipe()
      .subscribe({
        next: (data) => (this.coachees = data)
      });
  }

  public onSelect(): void {
    this.loadFormData();
  }

  public sectionTitle(fieldName: string, control: FormGroup): string {
    const { sectionTitleControl } = this.fieldActions[fieldName] || {};
    return control[this.controlKeys[sectionTitleControl]];
  }
  /**
   *
   * @param id will be given in the format of controlkey + '-' + index
   */
  public toggleContent(id: string): void {
    const content = document.getElementById(id);
    if (content) content.style.display = this.showContent(id) ? 'none' : 'block';
  }

  public showContent(id: string): boolean {
    const content = document.getElementById(id);
    if (!content) return false;
    return content.style.display === 'block' || !content.style.display;
  }

  public onDropOrder(event: CdkDragDrop<string[]>, fieldName: string, index?: number): void {
    const controls: FormGroup[] =
      index !== undefined ? this.fieldActions[fieldName].control(index) : this.fieldActions[fieldName].control();
    const [draggedControl] = controls.splice(event.previousIndex, 1);
    controls.splice(event.currentIndex, 0, draggedControl);
    if (index !== undefined) {
      this.form?.get(`experiences.${index}`)?.patchValue({ [fieldName]: controls }, { emitEvent: true });
      return;
    }
    // reorder the form
    this.form?.patchValue({ [fieldName]: controls }, { emitEvent: true });
  }

  /**
   *
   * @param fieldName this defines which form array will be used to add elements
   * @param index this would be used for nested form array within a form array: like experiences -> tasks
   *              so in this case we will need experiences index where tasks will be added
   * @returns void
   */
  public addField(fieldName: string | undefined, index?: number, addToFieldName?: string): void {
    if (!fieldName) return;
    const { defaultRow, control, initialFormValue } = this.fieldActions[fieldName] || {};
    const newRow = this.fb.group({ ...initialFormValue, ...defaultRow });
    this.addRow(this.controlKeys[fieldName], control(index), newRow, this.controlKeys[addToFieldName ?? ''], index);
  }

  public removeField(index: number, fieldName?: string, mainField?: string, mainFieldIndex?: number): void {
    if (!fieldName) return;
    if (mainField && mainFieldIndex !== undefined) {
      this.fieldActions[fieldName]?.control(mainFieldIndex).splice(index, 1);
      this.form
        ?.get(`${this.controlKeys[mainField]}.${mainFieldIndex}.${this.controlKeys[fieldName]}`)
        ?.patchValue(this.fieldActions[fieldName]?.control(mainFieldIndex));
      return;
    }
    this.fieldActions[fieldName]?.control().splice(index, 1);
    this.form?.get(fieldName)?.patchValue(this.fieldActions[fieldName]?.control());
    if (fieldName === 'softSkills') this.updateSelectedSoftSkills();
  }

  public updateSelectedSoftSkills(index?: number): void {
    this.selectedSoftSkills = this.softSkillsControl.value
      .filter((skill) => skill.name !== '')
      .map((skill) => skill.name.toLowerCase());

    if (index) {
      const control = this.softSkillsControl.at(index);
      if (this.selectedSoftSkills.filter((name) => name === control.value.name.toLowerCase()).length > 1) {
        control.patchValue(this.initialSoftSkillFormValues);
      }
    }

    this.softSkillList = softSkillSuggestionList.filter(
      (skill) => !this.selectedSoftSkills.includes(skill.toLowerCase())
    );
    this.changeDetectorRef.detectChanges();
  }

  public removeSoftSkill(index: number): void {
    const softSkillsControl = this.softSkillsControl as FormArray;

    if (softSkillsControl.length > 1) {
      softSkillsControl.removeAt(index);
      this.selectedSoftSkills = softSkillsControl.value
        .filter((skill) => skill.name !== '')
        .map((skill) => skill.name.toLowerCase());

      this.softSkillList = softSkillSuggestionList.filter(
        (skill) => !this.selectedSoftSkills.includes(skill.toLowerCase())
      );

      this.changeDetectorRef.detectChanges();
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      [this.controlKeys.firstName]: [''],
      [this.controlKeys.lastName]: [''],
      [this.controlKeys.jobTitle]: [''],
      [this.controlKeys.picture]: [''],
      [this.controlKeys.summary]: [''],
      [this.controlKeys.educations]: this.fb.array([]),
      [this.controlKeys.languages]: this.fb.array([]),
      [this.controlKeys.technicalSkills]: this.fb.array([]),
      [this.controlKeys.softSkills]: this.fb.array([]),
      [this.controlKeys.certifications]: this.fb.array([]),
      [this.controlKeys.experiences]: this.fb.array([])
    });
  }

  private debounceUpdate(): void {
    this.addSubscription(
      this.form!.valueChanges.pipe(debounceTime(this.FORM_DEBOUNCE_TIME_MS)).subscribe((formData) => {
        this.cvGeneratorService.updateCvDataLocal(formData);
        this.cvGeneratorService.updateCvDataOnServer(formData, this.selectedCoacheeDto?.userId);
        this.getTotalPercentage();
      })
    );
  }

  private loadFormData(): void {
    this.cvGeneratorService.loadCvData(this.selectedCoacheeDto?.userId).subscribe((data) => {
      if (data) {
        if (data.picture) {
          this.picUrl = data.picture;
        }

        if (data.softSkills && data.softSkills?.length <= 5) {
          for (let i = 0; i < 5; i++) {
            if (!data.softSkills[i]?.name) {
              data.softSkills[i] = { name: '' };
            }
          }
        } else {
          // slice the array so everyone has 5 softskills
          data.softSkills = data.softSkills?.slice(0, 5);
        }

        Object.keys(this.fieldActions).forEach((fieldName) => {
          const fieldAction = this.fieldActions[fieldName];
          const formData = data?.[fieldName];
          if (formData) {
            this.form?.setControl(
              this.controlKeys[fieldName],
              new FormArray(
                formData.map((dataItem) => {
                  if (fieldName === 'experiences') {
                    const experienceFormGroup = this.fb.group({ ...fieldAction.initialFormValue });
                    dataItem.tasks = dataItem.tasks ?? [];
                    if (dataItem.tasks) {
                      const tasksArray = dataItem.tasks.map((task) =>
                        this.fb.group({ [this.controlKeys.taskName]: task })
                      );
                      experienceFormGroup.setControl(this.controlKeys.tasks, this.fb.array(tasksArray));
                    }
                    return experienceFormGroup;
                  }

                  return this.fb.group({ ...fieldAction.initialFormValue, ...dataItem });
                })
              )
            );
          }
        });
        this.form?.patchValue(data ?? {});
        this.getTotalPercentage();
      }
    });
    this.debounceUpdate();
  }

  private addRow(
    controlKey: ControlKeys,
    control: FormGroup[],
    newRow: FormGroup<any>,
    addToFieldName?: string,
    index?: number
  ): void {
    if (controlKey === 'experiences' && newRow.value.tasks) newRow.value.tasks = this.fb.array([]);
    const updatedFormArray = new FormArray([...(control ?? []), this.fb.group(newRow.value)]);

    if (addToFieldName && index !== undefined) {
      let nestedArray = this.form?.get(
        `${this.controlKeys[addToFieldName]}.${index}.${this.controlKeys[controlKey]}`
      ) as FormArray;
      if (nestedArray.value) return nestedArray.push(this.fb.group(newRow.value));
      nestedArray.setControl(this.controlKeys[controlKey], this.fb.array([]));
      this.addRow(controlKey, control, newRow, addToFieldName, index);
    }

    this.form?.setControl(this.controlKeys[controlKey], updatedFormArray);
    if (controlKey === 'softSkills') return;

    setTimeout(() => {
      for (let i = 0; i <= control.length; i++) {
        const id = controlKey + '-' + i;
        const content = document.getElementById(id);
        if (content) content.style.display = i !== control.length ? 'none' : 'block';
      }
    }, 0);
  }

  public hasFilledTechnicalSkills(): boolean {
    const technicalSkills = this.technicalSkillsControl.value;
    return technicalSkills.every((skill) => skill.category);
  }

  public hasFilledSoftSkills(): boolean {
    let filledCount = 0;
    const controlSoftSkills = this.fieldActions['softSkills']?.control();
    for (let skill of controlSoftSkills) {
      if (skill.value.name !== '') filledCount++;
    }
    return filledCount >= 5;
  }

  public orderExperiences() {
    const experiences = this.form?.get('experiences')?.value;

    experiences.sort((a, b) => {
      if (a.end === 'Present' && b.end !== 'Present') {
        return -1;
      } else if (a.end !== 'Present' && b.end === 'Present') {
        return 1;
      } else {
        const dateA = new Date(a.end);
        const dateB = new Date(b.end);
        if (dateA > dateB) {
          return -1;
        }
        if (dateA < dateB) {
          return 1;
        }
        return 0;
      }
    });

    this.form?.get('experiences')?.patchValue(experiences);
  }

  public onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.toastService.showError(this.translate.instant('shared.uploadcv.wrongFileType'));
    } else {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.picUrl = e.target.result;
        this.form?.controls['picture'].setValue(this.picUrl);
      };
      reader.readAsDataURL(file);
    }
    // Resets the input value
    this.fileInput.nativeElement.value = '';
  }

  public async getGooglePhoto() {
    this.cvGeneratorService.getGooglePhoto().subscribe((data) => {
      this.picUrl = data.url;
      this.form?.controls['picture'].setValue(this.picUrl);
    });
  }

  public getTotalPercentage(): void {
    const getField = (fieldName: string) => this.form?.get(fieldName)?.value.trim() ?? '';
    this.progressBar = [
      {
        complete: getField('firstName') !== '' && getField('lastName') !== '' && getField('jobTitle') !== '',
        icon: 'Person',
        linkSection: 'personalDetails'
      },
      { complete: getField('summary') !== '', icon: 'Summary', linkSection: 'summary' }
    ];
    Object.keys(this.fieldActions).forEach((fieldName) => {
      if (fieldName !== 'tasks') {
        const controls = this.fieldActions[fieldName]?.control();
        // group technical and soft skills together as skills
        // soft skills should at least have 5 filled in skills to be considered filled in
        const notEmpty = this.isControlComplete(controls);
        if (fieldName === 'technicalSkills' || fieldName === 'softSkills') {
          if (fieldName === 'softSkills') return;
          this.progressBar?.push({
            complete: this.hasFilledSoftSkills() && this.hasFilledTechnicalSkills(),
            icon: 'Skills',
            linkSection: 'skills'
          });
          return;
        }
        this.progressBar?.push({
          complete: fieldName === 'certifications' ? true : notEmpty,
          icon: this.fieldActions[fieldName]?.icon || '',
          linkSection: fieldName
        });
      }
    });
    const completeObjects = this.progressBar.filter((obj) => obj.complete);

    this.progressPercentage = Math.round((completeObjects.length / this.progressBar.length) * 100);
  }

  public presentExperience(index) {
    const experience = this.experiencesControl.at(index);

    if (experience.value.present) {
      this.experiencesControl.at(index).patchValue({ end: 'Present' });
    } else {
      this.experiencesControl.at(index).patchValue({ end: '' });
    }
  }

  public getEndDate(index): boolean {
    return this.experiencesControl.at(index).get('end')?.value === 'Present';
  }

  public scrollToTop(): void {
    const scrollElement = document.getElementsByClassName('cv-edit')[0];
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    scrollElement?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  public async downloadCv(option: string): Promise<void> {
    try {
      this.showSpinnerDownload = true;
      if (option === '.pdf') {
        await this.cvGeneratorService.downloadCvAsPdf(this.selectedCoacheeDto?.userId);
      } else if (option === '.docx') {
        await this.cvGeneratorService.downloadCv(this.selectedCoacheeDto?.userId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.showSpinnerDownload = false;
    }
  }

  public async publishCv(): Promise<void> {
    try {
      this.showSpinnerPublish = true;
      await this.cvGeneratorService.publishCv(this.selectedCoacheeDto?.userId);
    } catch (err) {
    } finally {
      this.showSpinnerPublish = false;
    }
  }

  public isControlComplete(control: FormGroup[]) {
    if (!control || !control.length) return false;
    const stateArray = control.map((formGroup) => {
      // in case present is used for experiences
      const valueWithoutPresent = { ...formGroup.value };
      delete valueWithoutPresent.present;
      const isEmpty = Object.values(valueWithoutPresent).every(
        (value) =>
          Array.isArray(value)
            ? value.every((item) =>
                Object.values(item).every((nestedValue) => !nestedValue || String(nestedValue).trim() === '')
              )
            : !value || String(value).trim() === '' || String(value).trim() === 'NaN-NaN-NaN' // to check for invalid date
      );
      const isComplete = Object.values(valueWithoutPresent).every(
        (value) =>
          Array.isArray(value)
            ? value.some((item) =>
                Object.values(item).some((nestedValue) => nestedValue && String(nestedValue).trim() !== '')
              )
            : value && String(value).trim() !== '' && String(value).trim() !== 'NaN-NaN-NaN' // to check for invalid date
      );
      return isEmpty ? 'empty' : isComplete ? 'complete' : 'partial';
    });
    if (stateArray.includes('partial')) {
      return false;
    }
    if (stateArray.every((value) => value === 'empty')) {
      return false;
    }
    return true;
  }

  public getEmptyExperienceFields(experience: FormGroup): string {
    const emptyFields: string[] = [];

    if (!experience.value.company.trim()) {
      emptyFields.push('Company Name');
    }
    if (!experience.value.projectName.trim()) {
      emptyFields.push('Project Name');
    }
    if (!experience.value.start || experience.value.start === 'NaN-NaN-NaN') {
      emptyFields.push('Start date');
    }
    if (!experience.value.end || experience.value.end === 'NaN-NaN-NaN') {
      emptyFields.push('End date');
    }
    if (!experience.value.projectDescription.trim()) {
      emptyFields.push('Project Description');
    }
    if (!experience.value.role.trim()) {
      emptyFields.push('Position');
    }
    if (!experience.value.projectRoleDescription.trim()) {
      emptyFields.push('Responsibilities');
    }
    if (!experience.value.environments.trim()) {
      emptyFields.push('Environments');
    }
    if (!experience.value.tasks || !experience.value.tasks.some((task) => task.name.trim())) {
      emptyFields.push('Achievements');
    }
    if (emptyFields.length === 9) {
      return '';
    }
    return emptyFields.join(', ');
  }
}
