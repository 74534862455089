<form
  [formGroup]="textInputForm"
  class="input-wrapper">
  <label
    [for]="label"
    [ngClass]="focused ? 'active' : ''"
    >{{ label }}</label
  >
  <input
    formControlName="inputValue"
    [placeholder]="placeholder"
    id="{{ id }}"
    name="{{ label }}"
    autocomplete="off"
    (focus)="onFocus()"
    (blur)="onBlur()" />
</form>
