<app-scroll-to-top></app-scroll-to-top>
<figure class="svg-container">
  <svg
    [attr.width]="width"
    height="100%"
    [attr.viewBox]="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="cv">
      <g id="pages">
        <g id="page-2-cv-group">
          <path
            id="Vector 20"
            d="M695 804H911.5V928C755.1 952.4 702 855.5 695 804Z"
            fill="#EFEADC"
            stroke="#EFEADC"
            stroke-width="2" />
          <path
            id="Vector 21"
            d="M584 1476H439V1392.6C543.748 1376.19 579.312 1441.36 584 1476Z"
            fill="#EFEADC"
            stroke="#EFEADC"
            stroke-width="2" />
          <g id="devoteam-logo">
            <g id="Group">
              <path
                id="Vector"
                d="M540.244 855.085C538.756 855.085 537.25 855.501 537.25 858.638C537.25 859.868 537.424 860.648 537.821 861.151C538.254 861.723 538.981 861.983 540.088 861.983C540.746 861.983 541.853 861.913 542.493 861.809L542.597 861.792V860.804H540.399C539.102 860.804 538.669 860.458 538.669 859.435V859.071H542.13C542.666 859.071 542.995 858.707 542.995 858.17V857.771C543.012 855.969 542.078 855.085 540.244 855.085ZM541.628 857.962H538.669V857.702C538.669 857.061 538.894 856.281 539.967 856.281H540.503C541.455 856.281 541.645 856.853 541.645 857.719V857.962H541.628Z"
                fill="#3C3C3A" />
              <path
                id="Vector_2"
                d="M528.755 855.085H528.426C526.419 855.085 525.537 856.177 525.537 858.62C525.537 860.648 526.021 861.983 528.426 861.983H528.755C530.71 861.983 531.627 860.891 531.627 858.534C531.627 856.107 530.762 855.085 528.755 855.085ZM530.157 859.088C530.157 860.319 529.759 860.822 528.755 860.822H528.392C527.198 860.822 526.99 860.284 526.99 858.776V858.326C526.99 856.714 527.302 856.281 528.461 856.281H528.669C529.759 856.281 530.139 856.593 530.139 858.326V859.088H530.157Z"
                fill="#3C3C3A" />
              <path
                id="Vector_3"
                d="M515.778 855.085C514.29 855.085 512.785 855.501 512.785 858.638C512.785 859.868 512.958 860.648 513.356 861.151C513.789 861.723 514.515 861.983 515.623 861.983C516.28 861.983 517.388 861.913 518.028 861.809L518.132 861.792V860.804H515.934C514.636 860.804 514.204 860.458 514.204 859.435V859.071H517.664C518.201 859.071 518.529 858.707 518.529 858.17V857.771C518.547 855.969 517.612 855.085 515.778 855.085ZM517.163 857.962H514.204V857.702C514.204 857.061 514.429 856.281 515.502 856.281H516.038C516.99 856.281 517.18 856.853 517.18 857.719V857.962H517.163Z"
                fill="#3C3C3A" />
              <path
                id="Vector_4"
                d="M510.346 855.241H508.442C507.145 855.258 505.882 855.605 505.882 858.655C505.882 861.74 507.075 861.983 508.754 861.983C509.446 861.983 510.415 861.965 511.609 861.792L511.73 861.775V853.074H510.346V855.241ZM510.346 860.77H508.806C507.646 860.77 507.335 860.388 507.335 858.984V858.204C507.335 856.922 507.75 856.471 508.909 856.471H510.346V860.77Z"
                fill="#3C3C3A" />
              <path
                id="Vector_5"
                d="M546.888 855.085C546.178 855.085 545.331 855.137 544.708 855.241L544.604 855.258V856.298H546.992C547.874 856.298 548.185 856.593 548.185 857.442V857.771C547.459 857.667 546.749 857.632 546.126 857.632C545.054 857.632 543.618 857.875 543.618 859.938C543.618 861.861 544.794 862.017 546.334 862.017C547.026 862.017 548.393 861.948 549.414 861.775L549.518 861.757L549.535 857.442C549.518 855.362 548.272 855.085 546.888 855.085ZM548.168 860.77H546.005C545.313 860.77 545.054 860.544 545.054 859.955V859.712C545.054 859.106 545.365 858.828 546.075 858.828H548.168V860.77Z"
                fill="#3C3C3A" />
              <path
                id="Vector_6"
                d="M522.007 860.146L519.966 855.241H518.53L521.281 861.844H522.647L525.329 855.241H523.928L522.007 860.146Z"
                fill="#3C3C3A" />
              <path
                id="Vector_7"
                d="M534.447 859.868V856.333H536.454V855.241H534.949H534.465V853.421V853.178H533.167V853.421V855.241H532.094V855.258V855.397V855.795V855.917V856.333H533.08V859.868C533.08 860.926 533.323 862 535.088 862C535.607 862 536.143 861.931 536.714 861.792L536.818 861.775V860.787H535.434C534.672 860.787 534.447 860.579 534.447 859.868Z"
                fill="#3C3C3A" />
              <path
                id="Vector_8"
                d="M557.529 855.067C556.871 855.067 556.041 855.171 555.279 855.345C554.812 855.102 554.241 855.085 553.705 855.085C552.442 855.085 550.937 855.345 550.937 855.345V861.844H552.338V856.315H553.636C554.587 856.315 554.864 856.627 554.864 857.684V861.844H556.266V857.321C556.266 856.922 556.214 856.61 556.11 856.35H557.321C558.394 856.35 558.723 856.679 558.723 857.719V861.844H560.107V857.355C560.107 855.067 558.359 855.067 557.529 855.067Z"
                fill="#3C3C3A" />
              <path
                id="Vector_9"
                d="M487.455 844.408C480.516 844.408 474.876 850.041 474.876 857.009C474.876 863.959 480.499 869.609 487.455 869.609C494.393 869.609 500.033 863.976 500.033 857.009C500.033 850.041 494.41 844.408 487.455 844.408Z"
                fill="#F8485E" />
            </g>
            <path
              id="Vector_10"
              d="M495.846 856.055H491.036V864.86H485.327C482.23 864.86 479.444 862.988 479.444 858.967C479.444 855.986 481.001 853.074 485.327 853.074H486.659C486.659 853.074 487.195 854.53 487.386 856.09H485.396C483.614 856.09 482.593 857.061 482.593 858.967C482.593 860.77 483.544 861.844 485.396 861.844H487.939V848.603H491.019V853.074H495.829V856.055H495.846Z"
              fill="white" />
          </g>
          <text
            id="technical-environment-title"
            fill="#F8485E"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Montserrat"
            font-size="12"
            font-weight="bold"
            letter-spacing="0em">
            <tspan
              x="484"
              y="1335.3">
              Technical environment
            </tspan>
          </text>
          <text
            id="achievements-title"
            fill="#F8485E"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Montserrat"
            font-size="12"
            font-weight="bold"
            letter-spacing="0em">
            <tspan
              x="484"
              y="1211.3">
              Achievements
            </tspan>
          </text>
          <text
            id="responsibilities-title"
            fill="#F8485E"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Montserrat"
            font-size="12"
            font-weight="bold"
            letter-spacing="0em">
            <tspan
              x="484"
              y="1097.3">
              Responsibilities
            </tspan>
          </text>
          <text
            id="context-title"
            fill="#F8485E"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Montserrat"
            font-size="12"
            font-weight="bold"
            letter-spacing="0em">
            <tspan
              x="484"
              y="977.302">
              Context
            </tspan>
          </text>
          <text
            id="recent-professional-experience-title"
            fill="#F8485E"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Montserrat"
            font-size="12"
            font-weight="bold"
            letter-spacing="0em">
            <tspan
              x="484"
              y="913.302">
              Recent Professional experience
            </tspan>
          </text>
        </g>
        <g id="page-1-cv-group">
          <g id="cv-1">
            <g id="cv_2">
              <g id="Group 13">
                <path
                  id="Vector 19"
                  d="M656.5 1.5H440V125.5C596.4 149.9 649.5 53 656.5 1.5Z"
                  fill="#EFEADC"
                  stroke="#EFEADC"
                  stroke-width="2" />
                <path
                  id="Vector 22"
                  d="M767 674H912V590.604C807.252 574.193 771.688 639.364 767 674Z"
                  fill="#EFEADC"
                  stroke="#EFEADC"
                  stroke-width="2" />
              </g>
              <g id="devoteam-logo_2">
                <g id="Group_2">
                  <path
                    id="Vector_11"
                    d="M550.244 45.5847C548.756 45.5847 547.25 46.0007 547.25 49.1377C547.25 50.3683 547.424 51.1482 547.821 51.6508C548.254 52.2228 548.981 52.4827 550.088 52.4827C550.746 52.4827 551.853 52.4134 552.493 52.3094L552.597 52.2921V51.3042H550.399C549.102 51.3042 548.669 50.9576 548.669 49.935V49.571H552.13C552.666 49.571 552.995 49.207 552.995 48.6698V48.2711C553.012 46.4686 552.078 45.5847 550.244 45.5847ZM551.628 48.4618H548.669V48.2018C548.669 47.5605 548.894 46.7806 549.967 46.7806H550.503C551.455 46.7806 551.645 47.3526 551.645 48.2191V48.4618H551.628Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_12"
                    d="M538.755 45.5847H538.426C536.419 45.5847 535.537 46.6766 535.537 49.1204C535.537 51.1482 536.021 52.4827 538.426 52.4827H538.755C540.71 52.4827 541.627 51.3908 541.627 49.0337C541.627 46.6073 540.762 45.5847 538.755 45.5847ZM540.157 49.5883C540.157 50.8189 539.759 51.3215 538.755 51.3215H538.392C537.198 51.3215 536.99 50.7842 536.99 49.2764V48.8257C536.99 47.2139 537.302 46.7806 538.461 46.7806H538.669C539.759 46.7806 540.139 47.0926 540.139 48.8257V49.5883H540.157Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_13"
                    d="M525.778 45.5847C524.29 45.5847 522.785 46.0007 522.785 49.1377C522.785 50.3683 522.958 51.1482 523.356 51.6508C523.789 52.2228 524.515 52.4827 525.623 52.4827C526.28 52.4827 527.388 52.4134 528.028 52.3094L528.132 52.2921V51.3042H525.934C524.636 51.3042 524.204 50.9576 524.204 49.935V49.571H527.664C528.201 49.571 528.529 49.207 528.529 48.6698V48.2711C528.547 46.4686 527.612 45.5847 525.778 45.5847ZM527.163 48.4618H524.204V48.2018C524.204 47.5605 524.429 46.7806 525.502 46.7806H526.038C526.99 46.7806 527.18 47.3526 527.18 48.2191V48.4618H527.163Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_14"
                    d="M520.346 45.7407H518.442C517.145 45.758 515.882 46.1047 515.882 49.155C515.882 52.2401 517.075 52.4827 518.754 52.4827C519.446 52.4827 520.415 52.4654 521.609 52.2921L521.73 52.2747V43.5742H520.346V45.7407ZM520.346 51.2695H518.806C517.646 51.2695 517.335 50.8882 517.335 49.4843V48.7044C517.335 47.4219 517.75 46.9712 518.909 46.9712H520.346V51.2695Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_15"
                    d="M556.888 45.5847C556.178 45.5847 555.331 45.6367 554.708 45.7407L554.604 45.758V46.7979H556.992C557.874 46.7979 558.185 47.0926 558.185 47.9418V48.2711C557.459 48.1671 556.749 48.1325 556.126 48.1325C555.054 48.1325 553.618 48.3751 553.618 50.4376C553.618 52.3614 554.794 52.5174 556.334 52.5174C557.026 52.5174 558.393 52.4481 559.414 52.2748L559.518 52.2574L559.535 47.9418C559.518 45.862 558.272 45.5847 556.888 45.5847ZM558.168 51.2695H556.005C555.313 51.2695 555.054 51.0442 555.054 50.4549V50.2123C555.054 49.6057 555.365 49.3284 556.075 49.3284H558.168V51.2695Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_16"
                    d="M532.007 50.6456L529.966 45.7407H528.53L531.281 52.3441H532.647L535.329 45.7407H533.928L532.007 50.6456Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_17"
                    d="M544.447 50.3683V46.8326H546.454V45.7407H544.949H544.465V43.9209V43.6782H543.167V43.9209V45.7407H542.094V45.758V45.8967V46.2953V46.4166V46.8326H543.08V50.3683C543.08 51.4255 543.323 52.5001 545.088 52.5001C545.607 52.5001 546.143 52.4307 546.714 52.2921L546.818 52.2748V51.2868H545.434C544.672 51.2868 544.447 51.0789 544.447 50.3683Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_18"
                    d="M567.529 45.5674C566.871 45.5674 566.041 45.6714 565.279 45.8447C564.812 45.602 564.241 45.5847 563.705 45.5847C562.442 45.5847 560.937 45.8447 560.937 45.8447V52.3441H562.338V46.8153H563.636C564.587 46.8153 564.864 47.1272 564.864 48.1845V52.3441H566.266V47.8205C566.266 47.4219 566.214 47.1099 566.11 46.8499H567.321C568.394 46.8499 568.723 47.1792 568.723 48.2191V52.3441H570.107V47.8552C570.107 45.5674 568.359 45.5674 567.529 45.5674Z"
                    fill="#3C3C3A" />
                  <path
                    id="Vector_19"
                    d="M497.455 34.9084C490.516 34.9084 484.876 40.5413 484.876 47.5086C484.876 54.4586 490.499 60.1088 497.455 60.1088C504.393 60.1088 510.033 54.476 510.033 47.5086C510.033 40.5413 504.41 34.9084 497.455 34.9084Z"
                    fill="#F8485E" />
                </g>
                <path
                  id="Vector_20"
                  d="M505.846 46.5554H501.036V55.3599H495.327C492.23 55.3599 489.444 53.4881 489.444 49.4672C489.444 46.4861 491.001 43.5744 495.327 43.5744H496.659C496.659 43.5744 497.195 45.0302 497.386 46.5901H495.396C493.614 46.5901 492.593 47.5607 492.593 49.4672C492.593 51.2696 493.544 52.3442 495.396 52.3442H497.939V39.1028H501.019V43.5744H505.829V46.5554H505.846Z"
                  fill="white" />
              </g>
            </g>
            <text
              id="John Doe"
              fill="#F8485E"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="16"
              font-weight="bold"
              letter-spacing="0em">
              <tspan
                x="488.867"
                y="206.236">
                John&#10;
              </tspan>
              <tspan
                x="492.602"
                y="226.236">
                Doe
              </tspan>
            </text>
            <text
              id="degrees-title"
              fill="#F8485E"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="12"
              font-weight="bold"
              letter-spacing="0em">
              <tspan
                x="486"
                y="416.802">
                Degrees
              </tspan>
            </text>
            <text
              id="languages-title"
              fill="#F8485E"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="12"
              font-weight="bold"
              letter-spacing="0em">
              <tspan
                x="486"
                y="570.802">
                Languages
              </tspan>
            </text>
            <text
              id="certifications-title"
              fill="#F8485E"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="12"
              font-weight="bold"
              letter-spacing="0em">
              <tspan
                x="785.23"
                y="222.302">
                Certifications
              </tspan>
            </text>
            <text
              id="technical-skills-title"
              fill="#F8485E"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="12"
              font-weight="bold"
              letter-spacing="0em">
              <tspan
                x="774"
                y="417.802">
                Technical skills
              </tspan>
            </text>
            <text
              id="functional-skills-title"
              fill="#F8485E"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="12"
              font-weight="bold"
              letter-spacing="0em">
              <tspan
                x="767"
                y="532.802">
                Functional skills
              </tspan>
            </text>
            <text
              id="summary-title"
              fill="black"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="11"
              letter-spacing="0em">
              <tspan
                x="659.979"
                y="98.4435">
                There are many variations of passages
              </tspan>
              <tspan
                x="684.621"
                y="111.444">
                of Lorem Ipsum available, but the
              </tspan>
              <tspan
                x="677.499"
                y="124.444">
                majority have suffered alteration in
              </tspan>
              <tspan
                x="678.53"
                y="137.444">
                some form, by injected humour, or
              </tspan>
              <tspan
                x="672.289"
                y="150.444">
                randomised words which don&#39;t look
              </tspan>
              <tspan
                x="739.02"
                y="163.443">
                even slightly believable.
              </tspan>
            </text>
            <text
              id="personal-details-title"
              fill="black"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Montserrat"
              font-size="11"
              letter-spacing="0em">
              <tspan
                x="489.707"
                y="95.4435">
                Consultant
              </tspan>
            </text>
          </g>
          <circle
            id="Ellipse 1"
            cx="532.5"
            cy="318.5"
            r="47.5"
            fill="#EFEEEE" />
        </g>
      </g>
      <g
        id="description"
        class="d-none d-lg-inline-block">
        <g id="description-page-2-cv">
          <g id="details">
            <g id="technical-environment">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="31.168"
                  y="1347.74">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </tspan>
                <tspan
                  x="95.7031"
                  y="1362.74">
                  sed do eiusmod tempor incididunt ut labore &#10;
                </tspan>
                <tspan
                  x="30.3359"
                  y="1377.74">
                  et dolore magna aliqua. Ut enim ad minim veniam, quis
                </tspan>
                <tspan
                  x="122.516"
                  y="1392.74">
                  nostrud exercitation ullamco laboris nisi &#10;
                </tspan>
                <tspan
                  x="138.652"
                  y="1407.74">
                  ut aliquip ex ea commodo consequat.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="175.484"
                  y="1332.74">
                  Technical environment&#10;
                </tspan>
              </text>
            </g>
            <g id="achievements">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="1223.74">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                </tspan>
                <tspan
                  x="984"
                  y="1238.74">
                  elit, sed do eiusmod tempor incididunt ut labore &#10;
                </tspan>
                <tspan
                  x="984"
                  y="1253.74">
                  et dolore magna aliqua. Ut enim ad minim veniam,
                </tspan>
                <tspan
                  x="984"
                  y="1268.74">
                  quis nostrud exercitation ullamco laboris nisi &#10;
                </tspan>
                <tspan
                  x="984"
                  y="1283.74">
                  ut aliquip ex ea commodo consequat.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="1208.74">
                  Achievements&#10;
                </tspan>
              </text>
            </g>
            <g id="responsibilities">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="31.168"
                  y="1111.74">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </tspan>
                <tspan
                  x="95.7031"
                  y="1126.74">
                  sed do eiusmod tempor incididunt ut labore &#10;
                </tspan>
                <tspan
                  x="30.3359"
                  y="1141.74">
                  et dolore magna aliqua. Ut enim ad minim veniam, quis
                </tspan>
                <tspan
                  x="122.516"
                  y="1156.74">
                  nostrud exercitation ullamco laboris nisi &#10;
                </tspan>
                <tspan
                  x="138.652"
                  y="1171.74">
                  ut aliquip ex ea commodo consequat.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="235.578"
                  y="1096.74">
                  Responsibilities&#10;
                </tspan>
              </text>
            </g>
            <g id="context">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="992.736">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                </tspan>
                <tspan
                  x="984"
                  y="1007.74">
                  elit, sed do eiusmod tempor incididunt ut labore &#10;
                </tspan>
                <tspan
                  x="984"
                  y="1022.74">
                  et dolore magna aliqua. Ut enim ad minim veniam,
                </tspan>
                <tspan
                  x="984"
                  y="1037.74">
                  quis nostrud exercitation ullamco laboris nisi &#10;
                </tspan>
                <tspan
                  x="984"
                  y="1052.74">
                  ut aliquip ex ea commodo consequat.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="977.736">
                  Context&#10;
                </tspan>
              </text>
            </g>
            <g id="recent-professional-experience">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="38.2695"
                  y="927.736">
                  If you are adding your current mission let the end date
                </tspan>
                <tspan
                  x="320.363"
                  y="942.736">
                  empty.&#13;&#10;
                </tspan>
                <tspan
                  x="43.5547"
                  y="957.736">
                  If it is not a mission just type &#34;None&#34; in &#34;Project name&#34;
                </tspan>
                <tspan
                  x="210.84"
                  y="972.736">
                  and &#34;Project description&#34;.&#13;&#10;
                </tspan>
                <tspan
                  x="37.625"
                  y="987.736">
                  For the technical environemment, always start with an
                </tspan>
                <tspan
                  x="59.2227"
                  y="1002.74">
                  upper case and check the name of the technology &#10;
                </tspan>
                <tspan
                  x="26.5977"
                  y="1017.74">
                  in the official Web Site (Example: Openshift, OPENSHIFT,
                </tspan>
                <tspan
                  x="297.301"
                  y="1032.74">
                  OpenShift).
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="264.781"
                  y="912.736">
                  Experiences&#10;
                </tspan>
              </text>
            </g>
          </g>
          <g id="lines">
            <line
              id="recent-professional-experience-line"
              x1="382"
              y1="912.5"
              x2="466"
              y2="912.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="responsibilities-line"
              x1="382"
              y1="1096.5"
              x2="466"
              y2="1096.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="technical-environment-line"
              x1="382"
              y1="1332.5"
              x2="466"
              y2="1332.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="context-line"
              x1="561"
              y1="977.5"
              x2="971"
              y2="977.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="achievements-line"
              x1="601"
              y1="1208.5"
              x2="971"
              y2="1208.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
          </g>
        </g>
        <g id="Description-page-1-cv">
          <g id="lines_2">
            <line
              id="personal-details-line"
              x1="380"
              y1="99.5"
              x2="466"
              y2="99.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="degrees-line"
              x1="380"
              y1="419.5"
              x2="466"
              y2="419.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="languages-line"
              x1="380"
              y1="573.5"
              x2="466"
              y2="573.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="summary-line"
              x1="893"
              y1="97.5"
              x2="971"
              y2="97.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="certifications-line"
              x1="893"
              y1="217.5"
              x2="971"
              y2="217.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="technical-skills-line"
              x1="893"
              y1="420.5"
              x2="971"
              y2="420.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
            <line
              id="functional-skills-line"
              x1="893"
              y1="533.5"
              x2="971"
              y2="533.5"
              stroke="#3C3C3A"
              stroke-dasharray="2 2" />
          </g>
          <g id="details_2">
            <g id="degrees">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="95.3398"
                  y="434.736">
                  Add your education: academic qualifications,
                </tspan>
                <tspan
                  x="191.551"
                  y="449.736">
                  extracurricular activities, etc.&#13;&#10;
                </tspan>
                <tspan
                  x="40.8945"
                  y="464.736">
                  Verify the title of your degree or training on the official
                </tspan>
                <tspan
                  x="312.594"
                  y="479.736">
                  website.&#13;&#10;
                </tspan>
                <tspan
                  x="75.957"
                  y="494.736">
                  Last added will be on top of the list, so add most
                </tspan>
                <tspan
                  x="115.355"
                  y="509.736">
                  important degree / diploma has last item.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="296.016"
                  y="419.736">
                  Degrees&#10;
                </tspan>
              </text>
            </g>
            <g id="functional-skills">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="986"
                  y="548.736">
                  Add at least one functional skill and select the 5 soft
                </tspan>
                <tspan
                  x="986"
                  y="563.736">
                  skills which describe you the best.&#13;&#10;
                </tspan>
                <tspan
                  x="986"
                  y="578.736">
                  In both cases start to add the skill that you want to
                </tspan>
                <tspan
                  x="986"
                  y="593.736">
                  appear on top of the list first!
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="986"
                  y="533.736">
                  Functional skills&#10;
                </tspan>
              </text>
            </g>
            <g id="languages">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="32.6055"
                  y="588.736">
                  Select your language and add your level from Beginner
                </tspan>
                <tspan
                  x="73.8906"
                  y="603.736">
                  A1/A2 to Intermediate B1/B2 then Advance C1/C2 &#10;
                </tspan>
                <tspan
                  x="252.027"
                  y="618.736">
                  to Mother tongue.&#13;&#10;
                </tspan>
                <tspan
                  x="34.4453"
                  y="633.736">
                  Please use the Self Assessment Grid for Language level
                </tspan>
                <tspan
                  x="94.7148"
                  y="648.736">
                  guide as reference: self_assessment_grid.pdf.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="272.688"
                  y="573.736">
                  Languages&#10;
                </tspan>
              </text>
            </g>
            <g id="summary">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="112.736">
                  This is the place where the magic happens, &#10;
                </tspan>
                <tspan
                  x="984"
                  y="127.736">
                  make sure to be clear and attractive.&#13;&#10;
                </tspan>
                <tspan
                  x="984"
                  y="142.736">
                  Describe briefly: who you are, what your competences &#10;
                </tspan>
                <tspan
                  x="984"
                  y="157.736">
                  (technical and soft skills) and professional goals are.&#13;&#10;
                </tspan>
                <tspan
                  x="984"
                  y="172.736">
                  Important: use &#x2018;he/she&#x2019; and not &#x2018;I&#x2019;.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="97.736">
                  Summary&#10;
                </tspan>
              </text>
            </g>
            <g id="certifications">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="232.736">
                  When typing the Certification name, start with an
                </tspan>
                <tspan
                  x="984"
                  y="247.736">
                  upper case.&#13;&#10;
                </tspan>
                <tspan
                  x="984"
                  y="262.736">
                  Verify the exact denomination on the official web site
                </tspan>
                <tspan
                  x="984"
                  y="277.736">
                  (Example: Red Hat side).&#13;&#10;
                </tspan>
                <tspan
                  x="984"
                  y="292.736">
                  The order in which you add the certifications doesn&#39;t
                </tspan>
                <tspan
                  x="984"
                  y="307.736">
                  matter, &#10;
                </tspan>
                <tspan
                  x="984"
                  y="322.736">
                  they will be listed in anti-chronological order based on
                </tspan>
                <tspan
                  x="984"
                  y="337.736">
                  the date.&#13;&#10;
                </tspan>
                <tspan
                  x="984"
                  y="352.736">
                  If you don&#39;t have any certification yet you can skip this
                </tspan>
                <tspan
                  x="984"
                  y="367.736">
                  section.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="217.736">
                  Certifications&#10;
                </tspan>
              </text>
            </g>
            <g id="technical-skills">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="435.736">
                  Add at least one technical skill and select the 5 soft
                </tspan>
                <tspan
                  x="984"
                  y="450.736">
                  skills which describe you the best.&#13;&#10;
                </tspan>
                <tspan
                  x="984"
                  y="465.736">
                  In both cases start to add the skill that you want to
                </tspan>
                <tspan
                  x="984"
                  y="480.736">
                  appear on top of the list first!
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="984"
                  y="420.736">
                  Technical skills&#10;
                </tspan>
              </text>
            </g>
            <g id="personal-details">
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12"
                letter-spacing="0em">
                <tspan
                  x="73.0977"
                  y="114.736">
                  Select your Profile and Experience from the lists.&#13;&#10;
                </tspan>
                <tspan
                  x="17.5977"
                  y="129.736">
                  Your profile is missing? You are not sure which experience
                </tspan>
                <tspan
                  x="78.3828"
                  y="144.736">
                  level to choose? Contact your Practice Manager.
                </tspan>
              </text>
              <text
                fill="#F8485E"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16"
                font-weight="600"
                letter-spacing="0em">
                <tspan
                  x="233.719"
                  y="99.736">
                  Personal details&#10;
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</figure>

<!-- SMALL SCREEN d-none d-lg-inline-block -->

<div class="container">
  <div class="row">
    <div
      class="description-container"
      *ngFor="let id of elmsIds">
      <div
        class="col-12"
        id="description-{{ id }}">
        <h3 class="title">{{ 'guidelines.' + id + '.description-title' | translate }}</h3>
        <p>{{ 'guidelines.' + id + '.description' | translate }}</p>
      </div>
    </div>
  </div>
</div>
