<div class="card">
  <span
    class="certificate-icon fa fa-certificate"
    [ngClass]="validCertification ? 'valid' : 'expired'"></span>
  <p class="date">{{ certification.startDate }}</p>
  <h3 class="title">{{ certification.name }}</h3>
  <p
    class="date"
    [ngClass]="validCertification ? 'valid' : 'expired'">
    Expiry Date: {{ certification.expiryDate ? certification.expiryDate : 'None' }}
  </p>
</div>
