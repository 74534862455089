import { Component, Input, OnInit } from '@angular/core';
import { Colleague } from '../../../models/colleague';
import { ComponentStatus } from '../../../models/component-status';
@Component({
  selector: 'app-colleague-cards',
  templateUrl: './colleague-cards.component.html',
  styleUrls: ['./colleague-cards.component.scss']
})
export class ColleagueCardsComponent implements OnInit {
  @Input() public colleaguesData: Colleague[] = [];
  @Input() public status: ComponentStatus;
  @Input() public activeTabName: string;
  @Input() public showUnit: boolean;
  @Input() public showPractice: boolean;
  @Input() public showClient: boolean;
  public ComponentStatus: typeof ComponentStatus = ComponentStatus;

  public constructor() {}

  public get colleagues(): Colleague[] {
    return this.colleaguesData;
  }

  public ngOnInit(): void {}
}
