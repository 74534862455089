import { Component, Input, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../../utils/base-value-accessor';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerComponent,
      multi: true
    }
  ]
})
export class DatepickerComponent extends BaseValueAccessor {
  private static instanceCount: number = 0;

  @Input() label: string;
  @Input() public placeholder?: string = '';

  public datePickerForm: FormGroup;
  public focused: boolean = false;
  public id: string;

  private readonly instanceId: number;
  private readonly valueChangesSubscription: Subscription | undefined = undefined;

  public constructor(private readonly fb: FormBuilder, private readonly sanitizer: DomSanitizer) {
    super();
    this.instanceId = DatepickerComponent.instanceCount++;
    this.datePickerForm = this.fb.group({
      inputValue: ['']
    });
    this.valueChangesSubscription = this.datePickerForm
      .get('inputValue')
      ?.valueChanges.subscribe((value: string | Date): void => {
        const asString =
          value instanceof Date ? this.dateToString(value) : (value || '').split('/').reverse()
.join('-');
        this.onChange(asString);
      });
  }

  private dateToString(value: Date) {
    const year = `${value.getFullYear()}`;
    const month = `${value.getMonth() + 1}`;
    const day = `${value.getDate()}`;
    return `${year.padStart(2, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  public ngOnInit(): void {
    this.id = `${this.label}-${this.instanceId}`;
  }

  override ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  public override writeValue(value: string | null): void {
    if (value !== this.datePickerForm.get('inputValue')?.value) {
      const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, value);
      const formattedValue = sanitizedValue?.split('-').reverse()
.join('/');
      this.datePickerForm.get('inputValue')?.setValue(formattedValue);
    }
  }

  public checkValue(): boolean {
    return this.datePickerForm.get('inputValue')?.value === 'Present';
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
  }
}
