import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-generator',
  templateUrl: './cv-generator.component.html',
  styleUrls: ['./cv-generator.component.scss']
})
export class CvGeneratorComponent implements OnInit {
  ngOnInit(): void {}
}
