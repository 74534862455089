<li
  id="menu-item-{{ id }}"
  class="menu-item menu-item-{{ id }} nav-item">
  <a
    [routerLink]="link"
    class="menu-item menu-item-{{ id }} dropdown-item"
    role="button">
    {{ title | translate }}
  </a>
</li>
