import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-create-resume-card',
  templateUrl: './create-resume-card.component.html',
  styleUrls: ['./create-resume-card.component.scss']
})
export class CreateResumeCardComponent {
  public cvGeneratorUrl = environment.cvGeneratorUrl;
  public wordTemplateUrl = environment.wordTemplateUrl;
  public onePageCvUrl = environment.onePageCvUrl;
  public showGuidelines = environment.showGuidelines;

  constructor() {}
}
