<div class="App">
  <h1>Quiz - {{ currentCertification?.name }}</h1>

  <div class="layout">
    <div class="sidebar">
      <div class="quiz-progress-card">
        <h3 class="quiz-title"><span class="icon">📊</span> Quiz Progress</h3>

        <label for="categorySelect">Filter by category</label>
        <select
          id="categorySelect"
          (change)="handleCategoryChange($event)">
          <option value="">All categories</option>
          <option
            *ngFor="let category of categories"
            [value]="category">
            {{ category }}
          </option>
        </select>
        <div class="padding-top-10">
          <img
            src="assets/quiz/pass-test.png"
            class="passing-score"
            width="21px" />
          <span class="exam-info-label">
            {{ currentCertification?.totalQuestions }}Q, {{ currentCertification?.examTimeLimit }}min,
            {{ currentCertification?.passingScore }}%
          </span>
        </div>
        <div>
          <app-timer [timeLimit]="currentCertification?.examTimeLimit"></app-timer>
        </div>
        <div class="quiz-stats">
          <div class="stat-box">
            <h2>{{ totalQuizQuestions }}</h2>
            <p>Total questions</p>
          </div>
          <div class="stat-box">
            <h2 class="answered">{{ totalQuestionsAnswered }}</h2>
            <p>Questions answered</p>
          </div>
        </div>

        <div class="quiz-details">
          <p>
            <img
              src="assets/quiz/check-mark.png"
              class="icon icon-correct"
              alt=""
              aria-hidden="true" />
            Correct <span>{{ correctAnswersCount }}</span>
          </p>
          <p>
            <img
              src="assets/quiz/cross-mark.png"
              class="icon icon-correct"
              alt=""
              aria-hidden="true" />
            Incorrect <span>{{ totalQuestionsAnswered - correctAnswersCount }}</span>
          </p>
          <p>
            <img
              src="assets/quiz/trophy-2.png"
              class="icon icon-correct"
              alt=""
              aria-hidden="true" />
            Success rate <span>{{ successPercentage }}%</span>
          </p>
        </div>
      </div>
      <app-button
        [label]="'Reset quiz stats'"
        (click)="openResetModal()">
      </app-button>
      <div
        class="modal-backdrop"
        *ngIf="showResetModal">
        <div class="modal">
          <p>Are you sure you want to reset your quiz stats?</p>
          <div class="buttons-container">
            <button
              class="confirm-btn"
              (click)="confirmReset()">
              Yes, Reset
            </button>
            <button
              class="cancel-btn"
              (click)="cancelReset()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form">
      <h2 [innerHTML]="formatCodeText(currentQuestion?.question_text || '')"></h2>

      <div
        *ngIf="currentQuestion"
        class="options">
        <div
          *ngFor="let option of currentQuestion.options | keyvalue"
          class="option-row"
          [ngClass]="{ 'disabled-option': isSubmitted }"
          (click)="handleRowClick(option.key)">
          <div
            class="option"
            [ngClass]="{
              'correct-answer': isSubmitted && currentQuestion.correct_answers.includes(option.key),
              'incorrect-answer':
                isSubmitted &&
                !currentQuestion.correct_answers.includes(option.key) &&
                (isMultipleChoice ? selectedCheckboxes.includes(option.key) : selectedAnswer === option.key)
            }">
            <label
              class="question-label"
              (click)="$event.stopPropagation()">
              <input
                *ngIf="isMultipleChoice"
                type="checkbox"
                [value]="option.key"
                (change)="handleCheckboxChange(option.key)"
                [disabled]="isSubmitted"
                [checked]="selectedCheckboxes.includes(option.key)" />

              <input
                *ngIf="!isMultipleChoice"
                type="radio"
                name="answer"
                [value]="option.key"
                [(ngModel)]="selectedAnswer"
                [disabled]="isSubmitted"
                [checked]="selectedAnswer === option.key" />
              <span [innerHTML]="formatCodeText(option.value.option_text || '')"></span>
            </label>

            <p *ngIf="showExplanation && isSubmitted">{{ option.value.explanation }}</p>
          </div>
        </div>
      </div>

      <div class="navigation-buttons">
        <button
          class="btn back-btn"
          *ngIf="currentQuestionIndex > 0"
          (click)="previousQuestion()">
          Back
        </button>
        <button
          class="btn next-btn"
          (click)="nextQuestion()">
          {{
            !isSubmitted && (selectedAnswer || selectedCheckboxes.length > 0)
              ? 'Submit'
              : isSubmitted
              ? 'Next question'
              : 'Skip question'
          }}<span class="arrow">›</span>
        </button>
      </div>
    </div>
  </div>
</div>
