import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { OAuthEvent } from 'angular-oauth2-oidc/events';
import { TokenResponse } from 'angular-oauth2-oidc/types';
import { filter, take } from 'rxjs';

import { HttpStatusCode } from '@angular/common/http';
import { googleAuthConfig } from '../config/auth.google.config';
import { TokenRequest } from '../models/token-request';
import { User } from '../models/user';
import { ABOUT_PAGE_URL, LANDING_PAGE_URL } from '../shared/utils/constants';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly CLAIM_KEY: string = 'oauth_user_claim';

  public constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router,
    private readonly loginService: LoginService
  ) {
    this.configureSingleSignOn();
  }

  public get googleToken(): string | null {
    return this.oauthService.getAccessToken();
  }

  public get user(): User | null {
    const claim: any = this.oauthService.getIdentityClaims();
    const cached: any = this.getCachedUserClaim();

    if (claim) {
      if (cached && claim.email === cached.email) {
        return { ...cached, ...claim } as User;
      }
      // Else emails are not the same or the cache is empty and refresh the cache
      this.cacheUserClaim();
      return claim as User;
    } else if (!claim && cached) {
      return cached as User;
    }

    return null;
  }

  public get isAuthorized(): boolean {
    return !!this.token;
  }

  public get token(): string | null {
    return this.loginService.token();
  }

  public login(redirectTo?: string): void {
    this.oauthService.initImplicitFlow(redirectTo);
  }

  public checkUserVisitedAbout(): void {
    if (localStorage.getItem('userVisited') === 'false' || localStorage.getItem('userVisited') === null) {
      this.login(ABOUT_PAGE_URL);
    } else {
      this.login(LANDING_PAGE_URL);
    }
  }

  public async logoutAndRedirect(redirectTo: string = '/'): Promise<void> {
    await this.logout();
    await this.router.navigateByUrl(redirectTo);
  }

  public async refresh(): Promise<TokenResponse> {
    return this.oauthService.refreshToken();
  }

  public cacheUserClaim(): void {
    const claim: any = this.oauthService.getIdentityClaims();
    localStorage.setItem(
      this.CLAIM_KEY,
      JSON.stringify({
        email: claim.email,
        name: claim.name,
        given_name: claim.given_name,
        family_name: claim.family_name,
        picture: claim.picture
      })
    );
  }

  public getCachedUserClaim(): any {
    const claimStringified: string | null = localStorage.getItem(this.CLAIM_KEY);
    return claimStringified ? JSON.parse(claimStringified) : null;
  }

  public clearCachedUserClaim(): void {
    localStorage.removeItem(this.CLAIM_KEY);
  }

  configureSingleSignOn(): void {
    this.oauthService.configure(googleAuthConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    this.oauthService.events.pipe(filter((e: OAuthEvent) => e.type === 'session_terminated')).subscribe((e) => {
      this.logout().then(async () => this.router.navigateByUrl('/'));
    });

    this.oauthService.events
      .pipe(filter((e: OAuthEvent) => e.type === 'token_received' || e.type === 'token_refreshed'))
      .subscribe((e) => {
        if (this.googleToken) {
          this.loginService
            .getTokenAfterLogin(this.googleToken)
            .pipe(take(1))
            .subscribe({
              next: (tokenRequest: TokenRequest) => {
                if (tokenRequest.access_token) {
                  const state = this.oauthService.state;
                  if (e.type === 'token_received' && state) {
                    this.router.navigateByUrl(state);
                  }
                  this.cacheUserClaim();
                } else if (tokenRequest.statusCode === HttpStatusCode.Forbidden) {
                  this.logoutAndRedirect('/email-not-supported');
                } else {
                  this.logoutAndRedirect();
                }
              },
              error: () => {
                this.logoutAndRedirect();
              }
            });
        }
      });
  }

  private async logout(): Promise<void> {
    await this.oauthService.revokeTokenAndLogout(
      {
        client_id: this.oauthService.clientId,
        returnTo: this.oauthService.redirectUri
      },
      true
    );

    this.clearCachedUserClaim();
    this.loginService.cleanStorageAfterLogOut();
  }
}
