import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private readonly apiUrl: string = environment.apiUrl;

  public constructor(private readonly httpClient: HttpClient) {}

  public chat(q: string): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'charset': 'utf-8'
    });
    return this.httpClient.post<string>(`${this.apiUrl}/chat`, { q }, { headers, responseType: 'text' as 'json' });
  }
}
