import { Component, OnInit } from '@angular/core';
import { LANDING_PAGE_URL } from '../../utils/constants';
@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit {
  LANDING_PAGE_URL = LANDING_PAGE_URL;
  constructor() {}

  ngOnInit(): void {}
}
