<!-- certifications.component.html -->
<div class="certifications-container">
  <h1>Certifications</h1>
  <div class="experimental-message">
    This feature is experimental and is part of a proof-of-concept initiative launched by the A-Cloud pillar. Currently,
    it includes only AWS-focused certification quiz prep. If you'd like to add quiz questions related to the
    technologies in your pillar, please reach out to us.
  </div>
  <div class="certification-grid">
    <div
      *ngFor="let certification of certifications"
      class="certification-card"
      (click)="navigateToCertification(certification.id)">
      <img
        [src]="certification.logo"
        [alt]="certification.name + ' Logo'" />
      <p>{{ certification.name }}</p>
    </div>
  </div>
</div>
