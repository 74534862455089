import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createCustomElement, NgElementConstructor } from '@angular/elements';
import { POPUP_TAGNAME } from '../../../../services/popup.service';
import { PopupComponent } from './popup.component';

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class PopupModule {
  public constructor(private readonly injector: Injector) {
    if (!customElements.get(POPUP_TAGNAME)) {
      const element: NgElementConstructor<unknown> = createCustomElement(PopupComponent, { injector: this.injector });
      customElements.define(POPUP_TAGNAME, element);
    }
  }
}
