import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseValueAccessor } from '../../utils/base-value-accessor';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectComponent,
      multi: true
    }
  ]
})
export class MultiSelectComponent extends BaseValueAccessor implements OnInit {
  private static instanceCount: number = 0;

  @Input()
  public label: string;
  @Input()
  public suggestions: string[] = [];
  @Input() public placeholder?: string = '';
  @ViewChild('dropdownPanel')
  private readonly dropdownPanel: ElementRef<HTMLDivElement>;
  @ViewChild('searchInput')
  private readonly input: ElementRef<HTMLInputElement>;

  public isFocused: boolean = false;
  public filteredSuggestions: string[] = [];
  public readonly multiSelectForm: FormGroup;
  public tags: string[] = [];
  public search: string = '';
  public previewDropIndex: number = -1;
  public draggingIndex: number = -1;
  public draggingTag: string | null = null;

  private readonly SEPARATOR: string = ', ';
  private readonly id: number;
  private readonly valueChangesSubscription: Subscription | undefined = undefined;

  public constructor(private readonly fb: FormBuilder) {
    super();
    this.id = MultiSelectComponent.instanceCount++;
    this.multiSelectForm = this.fb.group({
      inputValue: ['']
    });
    this.valueChangesSubscription = this.multiSelectForm
      .get('inputValue')
      ?.valueChanges.subscribe((value: string): void => {
        this.tags = value ? value.split(this.SEPARATOR) : [];
        this.search = '';
        this.filteredSuggestions = this.filterSuggestions('');
        this.onChange(value);
      });
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent): void {
    if (event.target && event.target instanceof HTMLElement && event.target.parentElement) {
      if (
        !this.dropdownPanel.nativeElement.contains(event.target) &&
        !this.input.nativeElement.contains(event.target)
      ) {
        const inputValue = this.input.nativeElement.value;
        if (inputValue.trim()) {
          this.onSuggestion(inputValue);
          this.input.nativeElement.value = '';
        }
        this.isFocused = false;
      }
    }
  }

  public ngOnInit(): void {}

  public override ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  public onDelete(index: number): void {
    if (index < 0 || index >= this.tags.length) {
      return;
    }

    const newTags = [...this.tags];
    newTags.splice(index, 1);
    this.setTags(newTags.length ? newTags : []);
  }

  public override writeValue(value: string | null): void {
    if (value !== this.multiSelectForm.get('inputValue')?.value) {
      this.multiSelectForm.get('inputValue')?.setValue(value?.trim());
    }
  }

  public onSearchChange(value: string): void {
    this.filteredSuggestions = this.filterSuggestions(value);
    this.search = value;
  }

  public onKeydown(): void {
    if (this.search.trim()) {
      this.setTags([...this.tags, this.search]);
      this.search = '';
    }
  }

  public onFocus(): void {
    this.isFocused = true;
    this.filteredSuggestions = this.filterSuggestions(this.search);
  }

  public onSuggestion(value: string) {
    const tags = this.multiSelectForm.get('inputValue')?.value.toLowerCase().split(this.SEPARATOR);

    if (tags?.includes(value.toLowerCase()) || !value.trim()) {
      return;
    }
    if (value.trim()) this.multiSelectForm.get('inputValue')?.setValue([...this.tags, value].join(this.SEPARATOR));
  }

  public onDragStart(event: DragEvent, previousIndex: number): void {
    event.dataTransfer?.setDragImage(document.createElement('div'), 0, 0);
    event.dataTransfer?.setData('text/html', `${this.id}`);
    // Updating these causes a re-render and chrome has issues with changing the dom in the onDragStart method
    // https://stackoverflow.com/questions/14203734/dragend-dragenter-and-dragleave-firing-off-immediately-when-i-drag
    // https://groups.google.com/a/chromium.org/g/chromium-bugs/c/YHs3orFC8Dc/m/ryT25b7J-NwJ
    setTimeout(() => {
      this.draggingIndex = previousIndex;
      this.draggingTag = this.tags[previousIndex];
      this.previewDropIndex = previousIndex;
    }, 0);
  }

  public onDragEnter(event: DragEvent | null, index: number): void {
    if (event && this.draggingIndex >= 0) {
      event.preventDefault();
      this.previewDropIndex = index;
    }
  }

  public onDragOver(event: DragEvent): void {
    this.draggingIndex >= 0 ? event.preventDefault() : null;
  }

  public onDragEnd(): void {
    const { draggingIndex, previewDropIndex } = this;

    if (draggingIndex >= 0) {
      if (previewDropIndex === -1) {
        this.previewDropIndex = draggingIndex;
      }

      this.moveTag(draggingIndex, previewDropIndex);

      this.previewDropIndex = -1;
      this.draggingIndex = -1;
      this.draggingTag = null;
    }
  }

  private moveTag(previousIndex: number, currentIndex: number): void {
    if (
      previousIndex === currentIndex ||
      previousIndex < 0 ||
      previousIndex >= this.tags.length ||
      currentIndex < 0 ||
      currentIndex >= this.tags.length
    ) {
      return;
    }
    const newTags = [...this.tags];
    moveItemInArray(newTags, previousIndex, currentIndex);
    this.setTags(newTags);
  }

  private setTags(newTags: string[]): void {
    const control: AbstractControl<any, any> | null = this.multiSelectForm.get('inputValue');
    if (control) {
      control.setValue(newTags.join(this.SEPARATOR));
    }
  }

  private filterSuggestions(value: string): string[] {
    if (value === '') {
      return this.suggestions.filter((suggestion: string): boolean => !this.tags.includes(suggestion));
    }
    const trimmedValue: string = value.trim().toLowerCase();
    return this.suggestions.filter(
      (suggestion: string): boolean =>
        !this.tags.includes(suggestion) && suggestion.trim().toLowerCase()
.includes(trimmedValue)
    );
  }
}
