<div>
  <div class="card">
    <p class="content">
      <app-text-view text="{{ text }}"></app-text-view>
    </p>
    <div class="content">
      <app-button
        label="{{ buttonText }}"
        (clicked)="emitClick($event)"></app-button>
    </div>
  </div>
</div>
