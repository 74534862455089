<div class="page">
  <h1>{{ 'onboardingChecklist.title' | translate }}</h1>
  <h2 [innerHtml]="'onboardingChecklist.description' | translate"></h2>

  <div class="content">
    <form [formGroup]="form">
      <div
        *ngFor="let key of checklistKeys; let i = index"
        class="choice-container">
        <div class="label-container">
          <label>{{ key | translate }}</label>
        </div>
        <div class="checkbox-container">
          <input
            [formControlName]="key"
            type="checkbox"
            (change)="updateChecklist(key)" />
        </div>
      </div>
    </form>
  </div>
</div>
