import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Resume } from 'src/app/models/resume';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../shared/components/toast/toast.service';
import { getFileExtension } from '../../shared/utils/get-file-extension';
import { ResumesService } from './../../services/resumes.service';

@Component({
  selector: 'app-resume-card',
  templateUrl: './resume-card.component.html',
  styleUrls: ['./resume-card.component.scss']
})
export class ResumeCardComponent {
  @Input()
  public resume!: Resume;

  @Output()
  public removeResume: EventEmitter<any> = new EventEmitter();

  @Output()
  public unsetResumes: EventEmitter<any> = new EventEmitter();

  @ViewChild('toggleMenuBtn') public toggleButton: ElementRef;
  @ViewChild('menu') public menu: ElementRef;

  public loading: boolean = false;
  public resumeNameMaxLength: number = 65;
  public iconUrl: string = './assets/img/resumes/file-icon.png';
  public fileType: string | undefined;
  public menuVisible: boolean = false;
  public masterCVEnabled: boolean = environment.showMasterCV;

  public constructor(
    private readonly resumeService: ResumesService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly ref: ElementRef
  ) {}

  @HostListener('document:click', ['$event'])
  public clickOutside(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  public ngOnInit(): void {
    this.setFileIcon();
  }

  public closeMenu(): void {
    if (this.menuVisible) this.menuVisible = false;
  }

  public deleteResume(): void {
    this.closeMenu();
    this.loading = true;
    this.resumeService.deleteResume(this.resume.id).subscribe({
      next: (): void => {
        this.toastService.showSuccess(this.translateService.instant('resume.delete.success'));
        this.loading = false;
        this.removeResume.emit();
      },
      error: (): void => {
        this.loading = false;
        this.toastService.showError(this.translateService.instant('resume.delete.error'));
      }
    });
  }

  public downloadResume(): void {
    this.closeMenu();
    this.loading = true;
    this.resumeService.downloadFile(this.resume.id).subscribe({
      next: (response: Blob): void => {
        response.text().then((text) => {
          this.downloadFile(text);
        });
        this.toastService.showSuccess(this.translateService.instant('resume.download.success'));
        this.loading = false;
      },
      error: (): void => {
        this.toastService.showError(this.translateService.instant('resume.download.error'));
        this.loading = false;
      }
    });
  }

  public toggleMenu(): void {
    this.menuVisible = !this.menuVisible;
  }

  public downloadFile(base64String: string): void {
    const extension: string | undefined = this.resume.name.split('.').pop()
?.toLowerCase();
    const mimeType: string =
      extension === 'pdf' ? 'pdf' : 'vnd.openxmlformats-officedocument.wordprocessingml.document';
    const source: string = `data:application/${mimeType};base64,${base64String}`;
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = source;
    link.download = this.resume.name;
    link.click();
  }

  public setAsMaster(): void {
    this.unsetResumes.emit(this.resume);
    this.updateResume({ name: this.resume.name, master: true });
  }

  public unsetAsMaster(): void {
    this.updateResume({ name: this.resume.name, master: false });
  }

  private updateResume(properties: Partial<Resume>): void {
    this.closeMenu();
    this.resume = { ...this.resume, master: Boolean(properties.master) };
    this.resumeService.updateResume(this.resume.id, properties).subscribe({
      next: (): void => {
        this.toastService.showSuccess(this.translateService.instant('resume.update.success'));
      },
      error: (): void => {
        this.toastService.showError(this.translateService.instant('resume.update.error'));
        this.resume = { ...this.resume, master: !properties.master };
      }
    });
  }

  private setFileIcon(): void {
    const extension: string | undefined = getFileExtension(this.resume.name);
    if (!extension) return;
    switch (extension.toLowerCase()) {
      case '.doc':
      case '.docx':
        this.iconUrl = './assets/img/upload/word-icon.png';
        break;
      case '.pdf':
        this.iconUrl = './assets/img/upload/pdf-icon.png';
        break;
      case '.pptx':
        this.iconUrl = './assets/img/upload/pptx-icon.png';
        break;
      default:
    }
  }
}
