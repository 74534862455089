import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() label: string | undefined;
  @Input() disabled?: boolean = false;
  @Input() id: string;
  @Input() primaryColor?: string;
  @Input() secondaryColor?: string;

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  public onClick(event: Event): void {
    this.clicked.emit(event);
  }
}
