import { Component, EventEmitter, Input, Output, HostListener, ElementRef } from '@angular/core';

/**
 * The language selection menu on the navigationbar.
 * It offers the functionality to change the language of the application.
 * If functions as a toggleable dropdown menu.
 */
@Component({
  selector: 'app-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent {
  @Input()
  public currentLanguage!: any;

  @Input()
  public supportedLanguages!: Object;

  @Output()
    changeLanguage: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  protected isOpen = false;

  constructor(private readonly eRef: ElementRef) {}

  /**
   * Toggles the visibility of the language selector dropdown
   */
  public toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  /**
   * Closes the dropdown menu if it is opened.
   */
  public closeMenu(): void {
    if (this.isOpen) this.isOpen = false;
  }

  public switchLanguage(language: any): void {
    this.changeLanguage.emit(language);
  }
}
