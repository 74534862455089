import { Component, ElementRef, Input, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../../utils/base-value-accessor';

@Component({
  selector: 'app-year-datepicker',
  templateUrl: './year-datepicker.component.html',
  styleUrls: ['./year-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: YearDatepickerComponent,
      multi: true
    }
  ]
})
export class YearDatepickerComponent extends BaseValueAccessor {
  private static instanceCount: number = 0;

  @Input() public label: string;
  @Input() public placeholder?: string = '';
  @ViewChild('parentElement') public parentElementRef!: ElementRef;
  @ViewChild(BsDatepickerDirective) public datepicker!: BsDatepickerDirective;

  public yearPickerForm: FormGroup;
  public focused: boolean = false;
  public id: string;
  public datepickerConfig: Partial<BsDatepickerConfig>;

  private readonly instanceId: number;
  private readonly valueChangesSubscription: Subscription | undefined = undefined;

  public constructor(
    private readonly fb: FormBuilder,
    private readonly sanitizer: DomSanitizer,
    private readonly elementRef: ElementRef
  ) {
    super();
    this.instanceId = YearDatepickerComponent.instanceCount++;
    this.yearPickerForm = this.fb.group({
      inputValue: ['']
    });

    this.valueChangesSubscription = this.yearPickerForm
      .get('inputValue')
      ?.valueChanges.subscribe((value: number): void => {
        value = new Date(value).getFullYear();
        this.onChange(value);
      });
  }

  public ngOnInit(): void {
    this.id = `${this.label}-${this.instanceId}`;
    window.addEventListener('scroll', this.onScroll.bind(this), true);
  }

  public override ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
    window.removeEventListener('scroll', this.onScroll.bind(this), true);
  }

  public onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    const parentElement = this.parentElementRef.nativeElement;
    const isOutside = !parentElement.contains(target) && !this.elementRef.nativeElement.contains(target);

    if (isOutside) {
      this.datepicker.hide();
    }
  }

  public override writeValue(value: string | null): void {
    if (value !== this.yearPickerForm.get('inputValue')?.value) {
      const sanitizedValue: string | null = this.sanitizer.sanitize(SecurityContext.HTML, value);

      const year: number | null = sanitizedValue ? new Date(sanitizedValue).getFullYear() : null;

      this.yearPickerForm.get('inputValue')?.setValue(year);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
  }
}
