export const skillSuggestionList: string[] = [
  '.NET',
  '3rd Party Acceptance Testing',
  'ABAC',
  'Acceptance',
  'ACL',
  'ACLs',
  'Active Directory Federation Services',
  'Active Directory',
  'ADAL',
  'After Effects',
  'Agile Kanban',
  'Agile Risk Manager',
  'Agile Scrum',
  'Agile',
  'AI',
  'AIX',
  'Amazon Web Services (AWS)',
  'Ambari',
  'Android',
  'Angular',
  'Anomaly Detection',
  'Ansible',
  'Anti-virus',
  'ANTS',
  'Apache Hadoop',
  'Apache Spark',
  'Apache',
  'APIGEE',
  'Appian',
  'Appium',
  'Application Development Cycle',
  'Application Registrations',
  'APS',
  'Architecture',
  'ARIS',
  'ARM Templates',
  'Artifactory',
  'AS/400 (iSeries)',
  'Asible',
  'ASP.NET MVC',
  'ASP.NET',
  'Atlassian Stack',
  'Audit Management',
  'Audition',
  'Automation Anywhere',
  'AvePoint DocAve 6',
  'AvePoint DocAve',
  'Avepoint Migrator',
  'AVI',
  'Awk',
  'AWS',
  'AWX',
  'Azure (MVP)',
  'Azure Active Directory',
  'Azure Data Components',
  'Azure Data Factory',
  'Azure Databricks',
  'Azure DevOps',
  'Azure Functions',
  'Azure Key Vault',
  'Azure Kubernetes Service (AKS)',
  'Azure ML',
  'Azure Pipelines',
  'Azure SQL Database',
  'Azure Storage',
  'Azure',
  'BABOK',
  'Backend',
  'Backup/Restore',
  'Bamboo',
  'Bash',
  'Batch',
  'BGP',
  'Big Data',
  'Bind DNS',
  'Bind',
  'BitBucket',
  'Bitcoin',
  'Bizagi',
  'Blockchain',
  'BMPN',
  'Bootstrap',
  'Boto',
  'Bouncy Castle',
  'Box',
  'BPMN UML',
  'BPMN',
  'Business Analysis',
  'Business Continuity & Disaster Recovery Management',
  'C',
  'C#',
  'C++',
  'Cable Networks',
  'Camunda',
  'CASB Concept',
  'Case360',
  'Cassandra',
  'CCNA',
  'CDP',
  'CentOS',
  'Certificates',
  'Change Management',
  'Checkpoint Firewall',
  'CheckPoint FW-1',
  'CI',
  'CIS-Controls',
  'Cisco CCNA 1-4',
  'Cisco Devices Configuration',
  'Cisco Jabber / WebEx (Power User)',
  'Cisco Routers',
  'Cisco',
  'Citrix XenServer 5',
  'Citrix',
  'Clear Replies',
  'Clients and Servers',
  'Cloud and Datacenter Infrastructure & Operations',
  'Cloud Migration',
  'Cloud Security',
  'Cloud',
  'Cloudera',
  'CloudFormation',
  'CMMI',
  'COBIT',
  'COBOL',
  'Compliance',
  'Compression and Archiving Products',
  'Computer Vision',
  'Conceptual Study',
  'Configuration Management',
  'Confluence',
  'Consul',
  'Containerization',
  'Containers',
  'CosmosDB',
  'CPE',
  'CSS',
  'SASS',
  'CSS3',
  'SCSS',
  'Cucumber',
  'Cuda',
  'Customer Oriented Communication',
  'CVS',
  'Cypress',
  'Dash',
  'Dash/Plotly',
  'Data Analysis',
  'Data Mining',
  'Data Modeling',
  'Data Visualization Machine Learning',
  'Data Visualization',
  'Database',
  'Databases',
  'DataBricks',
  'Datacenter',
  'DataModeling',
  'DB2',
  'Debian and RHEL',
  'Debian',
  'Defect Management',
  'DEIS',
  'Delivery',
  'Delve',
  'Deployment',
  'Design and Architecture of Digital Electronic Systems',
  'Design of Embedded and Real-Time Systems',
  'Design',
  'DHCP',
  'Diameter',
  'Directory Servers',
  'DirSync / AADSync / ADConnect',
  'Disaster Recovery',
  'DLP concept',
  'DMARC',
  'DMN',
  'DNS',
  'Docker Swarm',
  'Docker',
  'DropBox',
  'DRP',
  'DynamoDB',
  'EasyMock',
  'EBIOS Risk Manager',
  'EC2',
  'EID',
  'EIGRP',
  'EKS',
  'ElasticSearch',
  'ELK-stack',
  'Enterprise Architecture',
  'ER',
  'ESSO',
  'ETCD',
  'Ethereum',
  'Ethernet',
  'EventHub',
  'Excel',
  'Explainable AI',
  'Fabric',
  'Feasibility study',
  'FEEL-Scala',
  'Figma',
  'File Shares',
  'Finance',
  'Firebase',
  'Firewall and security',
  'Firewall',
  'Flask',
  'Flink',
  'Flutter',
  'FluxCD',
  'Forgerock AM',
  'ForgeRock DS',
  'ForgeRock IDM',
  'FortiGate',
  'FOTA (Firmware Over The Air)',
  'Fraud Detection',
  'FreeBSD',
  'FTP/SFTP',
  'Functional Analysis',
  'G Suite',
  'GCP (Google Cloud Platform)',
  'GCP Big Data',
  'GCP Cloud Functions',
  'GCP Compute',
  'GCP Storage',
  'GCP VM Migration',
  'GCP VMWare',
  'GCP VPC',
  'GCP',
  'GDPR',
  'GIMP',
  'Git',
  'GitHub Actions',
  'GitLab CI',
  'GitLab',
  'GitOps',
  'Go',
  'Google Analytics',
  'Google App Engine',
  'Google Cloud Functions',
  'Google Cloud Platform (GCP)',
  'Google Cloud Pub/Sub',
  'Google Cloud Run',
  'Google Cloud SQL',
  'Google Cloud Storage',
  'Google Cloud Vision API',
  'Google Kubernetes Engine (GKE)',
  'Google Tag Manager',
  'Governance',
  'Gradle',
  'Grafana',
  'Graph Analysis',
  'Graph API',
  'Graphite',
  'GraphQL',
  'GRC',
  'Grep',
  'Groovy',
  'Hadoop',
  'Haproxy',
  'HashiCorp Consul',
  'HashiCorp Packer',
  'HashiCorp Terraform',
  'Hashicorp Vault',
  'Haskell',
  'HBase',
  'HDFS',
  'Helm',
  'HG',
  'Hibernate',
  'Hibernation',
  'Hive',
  'HP QC',
  'HP QTP',
  'HP Quality Center',
  'HTML',
  'HTML5',
  'HTTP/HTTPS',
  'Hyper-V',
  'Hyperledger Fabric',
  'IaaS',
  'IAM',
  'IBM Cloud',
  'IBM DataPower',
  'IBM Mainframe',
  'IBM MQ',
  'IBM Sterling B2B Integrator',
  'IBM WebSphere Transformation Extender',
  'IBM WebSphere',
  'Icinga',
  'ICloud',
  'ICSF',
  'ICT',
  'Idea Management',
  'Identity & Access Management',
  'Identity Access Management',
  'Identity Governance',
  'Identity Management',
  'IIS',
  'Illustrator',
  'Immutable Infrastructure',
  'Impala',
  'IMS',
  'InDesign',
  'Informatica',
  'Infrastructure as code',
  'Initiative Management',
  'IntelliJ IDEA',
  'IntelliJ',
  'Internet Information Services',
  'Internet Infrastructure ',
  'Ionic',
  'Ionic',
  'iOS',
  'IP Routing',
  'IP Telephony',
  'IP/TCP',
  'IPS(L)',
  'IPsec',
  'IPSL',
  'Iptables',
  'IPv4/IPv6',
  'ISF',
  'ISO 9001',
  'ISO 27001',
  'ISO 27002',
  'ISO 27005',
  'ISO 2700x',
  'ISO 27k',
  'ISO 9001:2018',
  'ISPF',
  'Istio',
  'ISTQB',
  'IT Risk Management',
  'IT Service Excellence',
  'IT Transformation (Change Management)',
  'ITIL Service management V2-V3-V2011-V4',
  'ITIL',
  'J2EE',
  'J2SE',
  'Jackson',
  'Jasmine',
  'Java',
  'JavaScript',
  'JCL',
  'Jenkins',
  'Jest',
  'Jira',
  'JIRA',
  'JMeter',
  'JMS',
  'JMX',
  'JPA',
  'JQuery',
  'JS',
  'JSON',
  'JSP',
  'JUEL (Camunda)',
  'JUnit',
  'Jupyter Notebook',
  'Jupyter Notebooks',
  'Jupyter',
  'K2 Blackpearl (pre-sales track)',
  'K8s',
  'Kafka',
  'Kali Linux Pentesting',
  'Kanban',
  'Karma',
  'Kendo UI',
  'Keras',
  'Kerberos',
  'Keycloak',
  'Kibana',
  'Kinesis',
  'Kotlin',
  'Kubernetes',
  'Kudu',
  'KVM',
  'LAMP',
  'LAN/WAN',
  'Laravel',
  'LaTeX',
  'LDAP',
  'Lean Methodology',
  'Lean-Six-Sigma',
  'Lean',
  'LESS',
  'LibreOffice',
  'Linux (Debian/Centos)',
  'Linux Containers',
  'Linux Scripting',
  'Linux Server',
  'Linux',
  'Load Balancers',
  'Load Balancing',
  'Logstash',
  'Lucene',
  'LVM',
  'M365',
  'Mac OS',
  'Machine Learning',
  'MacOS',
  'Magento',
  'Magicdraw',
  'Management Software',
  'MariaDB',
  'Market Analysis',
  'MATLAB',
  'Matplotlib',
  'Maturity assessments',
  'Maven',
  'Mcollective',
  'MDX',
  'Meraki',
  'Mercurial',
  'Mesos',
  'Messaging',
  'Metalogix Content Matrix',
  'Metasploit',
  'Microservice Architectures',
  'Microservices',
  'Microsoft .NET',
  'Microsoft Access',
  'Microsoft Active Directory',
  'Microsoft Azure Active Directory',
  'Microsoft Azure Cognitive Services',
  'Microsoft Azure DevOps',
  'Microsoft Azure Functions',
  'Microsoft Azure IaaS',
  'Microsoft Azure Logic Apps',
  'Microsoft Azure Service Bus',
  'Microsoft Azure',
  'Microsoft BI Suite',
  'Microsoft Dynamics 365',
  'Microsoft Dynamics AX',
  'Microsoft Dynamics CRM',
  'Microsoft Dynamics GP',
  'Microsoft Dynamics NAV',
  'Microsoft Exchange Server',
  'Microsoft FindTime and MeetSweet',
  'Microsoft Flow',
  'Microsoft Graph API',
  'Microsoft Hyper-V',
  'Microsoft Intune',
  'Microsoft Office 365',
  'Microsoft Office',
  'Microsoft OneDrive',
  'Microsoft OneNote',
  'Microsoft Outlook',
  'Microsoft Planner',
  'Microsoft Power Automate',
  'Microsoft Power BI',
  'Microsoft PowerBI Paginated Report',
  'Microsoft PowerBI',
  'Microsoft Project',
  'Microsoft SharePoint',
  'Microsoft SQL Server',
  'Microsoft SQL Server',
  'Microsoft SSRS',
  'Microsoft Stream',
  'Microsoft Teams',
  'Microsoft Visual Studio',
  'Microsoft Windows',
  'Microsoft Word',
  'Microsoft Yammer',
  'MicroStrategy',
  'Middleware',
  'MindJet MindManager',
  'MiniTab',
  'MLflow',
  'Mobile app development',
  'Mobile device management (MDM)',
  'Mobile Networks',
  'Mocha',
  'Mockito',
  'Model-driven development',
  'ModSecurity',
  'MongoDB',
  'Monitoring',
  'MPLS',
  'MS Azure fundamentals',
  'MS Hyper-V ',
  'MS Office',
  'MS Project',
  'MS SQL Server 2005-2008-2012-2014',
  'MS Teams',
  'MS Visio',
  'MS-Office',
  'MSSQL',
  'Multi-Factor Authentication',
  'MVS',
  'MySQL',
  'Nagios',
  'NAT gateway',
  'NAT/PAT',
  'Natural Language Processing (NLP)',
  'Ncclient',
  'Neo4j',
  'NestJS',
  'Netscreen Firewall',
  'Network Security Basics',
  'Network',
  'Networking',
  'Nexus',
  'NFS',
  'NGINX',
  'NiFi',
  'Nintex Workflow and Forms',
  'Nintex Workflow',
  'NIST',
  'NLP',
  'Node.js',
  'Nokia Nuage',
  'NoSQL',
  'Novell',
  'NPM',
  'NTP',
  'NumPy',
  'OAuth2',
  'Office 365',
  'OIDC',
  'Ometa Business Connector',
  'Oozie',
  'OpenAM',
  'OpenBSD',
  'OpenCV',
  'OpenDJ',
  'OpenDKIM',
  'OpenIDM',
  'OpenIG',
  'OpenShift 3.11',
  'OpenShift 4.X',
  'OpenShift',
  'OpenSSO',
  'OpenStack',
  'OpenText case360',
  'OpenText Livelink 9.x',
  'Operating Systems',
  'OPNsense',
  'Oracle DB',
  'OSPF',
  'OSS/BSS systems',
  'Outlook',
  'OWASP',
  'PaaS',
  'PAM (global overview)',
  'Pandas',
  'Parad',
  'Penetration Testing',
  'People Management',
  'Perl',
  'PfSense',
  'Photoshop',
  'PHP',
  'PKI',
  'PL/SQL',
  'Plotly',
  'PMBOK & Prince II (Certified)',
  'Postfix',
  'Postgres',
  'PostgreSQL',
  'Postman',
  'Power Automate',
  'Power BI',
  'PowerPoint',
  'PowerShell',
  'Pre-Sales',
  'Première Pro',
  'PRINCE2',
  'Privileged Access Management (PAM)',
  'Privileged Identity Management',
  'Process Management',
  'Product Modelling',
  'Project Management',
  'Project Web App',
  'Prolog',
  'Puppet',
  'PyCharm',
  'PySpark',
  'Python',
  'Pytorch',
  'QiTASC',
  'Qlik Sense',
  'QoS',
  'Quarkus',
  'Quasar Framework',
  'R',
  'RabbitMQ',
  'RBAC',
  'React Native',
  'React Redux',
  'React Testing Library (RTL)',
  'React',
  'ReBAC',
  'Red Hat',
  'Redis for Azure',
  'Redshift',
  'Regular Expressions',
  'Release Automation',
  'Requirements Management',
  'REST Services',
  'REST',
  'Reverse Proxies',
  'Reverse Proxy',
  'RHCOS',
  'RHEL',
  'RHEL/CentOS',
  'Risk Management',
  'Routing and Switching',
  'RPA',
  'RSA SecurID',
  'Rundeck',
  'Rust',
  'S3',
  'SaaS',
  'SAFe',
  'Sales of network solutions',
  'SaltStack',
  'SAML2',
  'SAP',
  'SAS VA',
  'SAS',
  'SASS',
  'Scala',
  'Scikit-Learn',
  'Scikit',
  'Scripting',
  'Scrum',
  'SCSS',
  'SDWAN',
  'Secure Deployment',
  'Security Assessment',
  'Security Audit',
  'Security Awareness',
  'Security',
  'Selenium',
  'Self-sovereign Identity',
  'SELinux',
  'Server 2003/2008',
  'Server 2010',
  'ServiceNOW GRC',
  'ServiceNOW VRM',
  'SFML',
  'ShareGate',
  'SharePoint 2013',
  'SharePoint 2016',
  'Sharepoint 2019',
  'SharePoint Designer 2010 – 2013',
  'SharePoint Foundation',
  'SharePoint MOSS 2007',
  'SharePoint Online',
  'SharePoint',
  'Shell Scripting',
  'Shell',
  'Singleton',
  'SIP',
  'Skype',
  'SOAP UI',
  'SOAP',
  'SOAPUI',
  'Software Development',
  'Solaris',
  'Solidity',
  'SonarQube',
  'SpamAssassin',
  'Spark',
  'Sparx Enterprise Architect',
  'SPF',
  'SPFx',
  'Splunk',
  'SPML',
  'Spring Batch',
  'Spring Boot',
  'Spring Cloud',
  'Spring Data',
  'Spring Framework',
  'Spring MVC',
  'Spring',
  'SPSS',
  'SQL Management Studio',
  'SQL',
  'SQLite',
  'SQLServer',
  'Sqoop',
  'SS7',
  'SSAS',
  'SSO',
  'Static and dynamic routing',
  'Storage',
  'STP',
  'STP(802.1d)',
  'Sun Calendar Server',
  'Sun Directory Server',
  'Sun Identity Synchronization for Windows',
  'Sun JCE',
  'Sun Messaging Server (SIMS)',
  'Sun PKC11',
  'Sun Portal Server',
  'Sun Solaris',
  'Sun Web Server',
  'SVN',
  'Swift',
  'System integration',
  'Tableau',
  'TCP/IP',
  'Teams',
  'Technical Analysis',
  'Technical Architecture',
  'Telecom Network',
  'tEMC Documentum (Fundamentals)',
  'Tensorflow',
  'Terraform',
  'Test Desgin',
  'Test Driven Development',
  'Test Execution',
  'TestCafe',
  'Testing',
  'Thymeleaf',
  'TMap',
  'tOffice Communication Server - Lync 2010 - Lync 2013 Administration',
  'Trac',
  'Traefik reverse proxy',
  'Transitioning from Shadow IT',
  'Trello',
  'Troubleshooting',
  'tSkype for Business (Power User)',
  'TSO',
  'TypeScript',
  'Ubuntu',
  'UC4',
  'UiPath (RPA)',
  'UiPath',
  'UMA',
  'UML',
  'Unified Communications',
  'UNIX Services',
  'Unix',
  'Unix/Linux',
  'V-model',
  'Vagrant',
  'VB',
  'VBA',
  'VBS',
  'VHDL',
  'Virtual Networks',
  'Virtualization',
  'Visio',
  'Visual Studio Code',
  'VLAN',
  'VMare ESX',
  'VMWare ESXi',
  'VMware Workstation',
  'VMWare',
  'VNS',
  'VSP',
  'VSphere',
  'VTP',
  'Vue.js',
  'Vue',
  'Vulnerability management',
  'VyOS',
  'Waterfall',
  'Web and application servers',
  'WebSockets',
  'WebSSO',
  'Weka',
  'WikiMedia',
  'Wikiprojects',
  'Windows 7',
  'Windows Server 2012 and higher',
  'Windows Server',
  'Windows Vista',
  'Windows Workstation',
  'Windows XP',
  'Windows',
  'Wireless (802.11)',
  'Wireshark',
  'Word',
  'Workspace One/Airwatch',
  'Workstation',
  'WPF',
  'WSS 3.0',
  'Xamarin.Forms',
  'XML-DSig',
  'XML',
  'YAML',
  'Yammer',
  'Yarn',
  'Z/OS',
  'Zeppelin'
];
