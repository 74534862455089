<div class="container">
  <app-icon
    [icon]="'angle-left'"
    class="left arrow"
    (click)="previousPage()"></app-icon>
  <div>{{ currentPage }}</div>
  <div class="space">/</div>
  <div>{{ totalPages }}</div>
  <app-icon
    [icon]="'angle-right'"
    class="right arrow"
    (click)="nextPage()"></app-icon>
</div>
