import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Checklist } from '../models/checklist';

@Injectable({
  providedIn: 'root'
})
export class OnboardingChecklistService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly httpClient: HttpClient) {}

  find() {
    return this.httpClient.get(`${this.apiUrl}/onboarding-checklist`);
  }

  save(checklist: Checklist) {
    return this.httpClient.post(`${this.apiUrl}/onboarding-checklist`, checklist);
  }
}
