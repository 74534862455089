import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly apiUrl = environment.apiUrl;

  public constructor(private readonly httpClient: HttpClient) {}

  // Method to get the role of a user by email
  public getUserRole(email: string): Observable<{ role: string }> {
    return this.httpClient.get<{ role: string }>(`${this.apiUrl}/users/role/${email}`);
  }
}
