import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { delay, retry } from 'rxjs';
import { ComponentStatus } from 'src/app/models/component-status';
import { ProfileService } from 'src/app/services/profile.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { environment } from '../../../environments/environment';
import { Profile } from './../../models/profile';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public profile!: Profile;
  public name: string | undefined;

  public status!: ComponentStatus;
  public ComponentStatus: typeof ComponentStatus = ComponentStatus;
  public statusCode: HttpStatusCode | null;
  public showWhoIsWorkingAtMyClient: boolean = environment.showWhoIsWorkingAtMyClient;
  public showColleaguesInUnitAndPractice: boolean = environment.showColleaguesInUnitAndPractice;

  public referralUrl = environment.referralUrl;
  public intranetBeUrl = environment.intranetBeUrl;
  public intranetEmeaUrl = environment.intranetEmeaUrl;
  public portalUrl = environment.portalUrl;
  public appsPortalUrl = environment.appsPortalUrl;
  public showCertificates = environment.showCertificates;

  public constructor(private readonly profileService: ProfileService, private readonly toastService: ToastService) {}

  public ngOnInit(): void {
    this.loadProfile();
  }

  public goToResumePage(): void {
    location.href = 'resumes';
  }

  public goToCertificatePage(): void {
    location.href = 'certificates';
  }

  private loadProfile(): void {
    this.status = ComponentStatus.LOADING;

    this.profileService
      .getProfile()
      .pipe(retry(3), delay(500))
      .subscribe({
        next: (data: Profile): void => {
          this.profile = data;
          this.statusCode = HttpStatusCode.Ok;
          this.status = ComponentStatus.OK;
          this.name = this.profile.name.split(/\s+/).shift()!;
        },
        error: (err: HttpErrorResponse): void => {
          this.status = ComponentStatus.ERROR;
          this.statusCode = err.status;
          this.toastService.showError(this.toastService.setTranslation(`dashboard.errors.${err.status}`));
        }
      });
  }
}
