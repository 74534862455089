import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  public tooltip: string = '';
  public left: number = 0;
  public top: number = 0;
  public leftCus: string = '';
  public topCus: string = '';
  public widthCus: string = '';

  constructor() {}

  ngOnInit(): void {}
}
