import { Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/components/toast/toast.service';
import { Colleague } from '../../../../models/colleague';
import { copyToClipboard } from '../../../../shared/utils/copy-content';

@Component({
  selector: 'app-colleague-card',
  templateUrl: './colleague-card.component.html',
  styleUrls: ['./colleague-card.component.scss']
})
export class ColleagueCardComponent implements OnInit {
  @Input() public colleague: Colleague;
  @Input() public activeTabName: string;
  @Input() public showUnit: boolean;
  @Input() public showPractice: boolean;
  @Input() public showClient: boolean;

  public constructor(
    private readonly toastService: ToastService,
    private readonly translate: TranslateService,
    private readonly clipboardService: ClipboardService
  ) {}

  public ngOnInit(): void {}

  public copyEmail(email: string): void {
    copyToClipboard(email, this.clipboardService);
    this.toastService.showSuccess(this.translate.instant('dashboard.copy-email-success'));
  }
}
