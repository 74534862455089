import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import cvGuidelines from '../../../assets/mock/cv-guidelines';
import { CvGuidelines } from '../../models/cv-guidelines.interface';

@Component({
  selector: 'app-new-guidelines',
  templateUrl: './new-guidelines.component.html',
  styleUrls: ['./new-guidelines.component.scss']
})
export class NewGuidelinesComponent implements OnInit {
  public sections: CvGuidelines[];
  public title: string | null;
  public selectedItem: CvGuidelines;
  public selectedIndex: number = 0;
  public exampleTemplateImages: {};

  public constructor(private readonly route: ActivatedRoute, private readonly router: Router) {
    this.route.params.subscribe((): void => {
      this.changeDescription();
    });
  }

  public ngOnInit(): void {
    this.changeDescription();
    const currentIndex: number = this.sections.findIndex((index) => index.title === this.selectedItem.title);
    this.selectedIndex = currentIndex;
  }

  public changeDescription(): void {
    this.sections = cvGuidelines;
    this.title = this.route.snapshot.paramMap.get('title') || 'personal-details';
    this.exampleTemplateImages = this.sections[13].img;
    this.sections.map((section) => {
      if (section.title === this.title) {
        this.selectedItem = section;
      }
    });

    if (!this.selectedItem) {
      this.router.navigate(['/404']);
    }
  }

  public changeColor(i: number): void {
    this.selectedIndex = i;
  }
}
