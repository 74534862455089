import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { delay, retry } from 'rxjs';
import { ComponentStatus } from './../../models/component-status';
import { ToastService } from './../../shared/components/toast/toast.service';
import { Certificate } from './../../models/certificate';
import { CertificatesService } from './../../services/certificates.service';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
  public certificates!: Certificate[];

  public status!: ComponentStatus;
  public ComponentStatus = ComponentStatus;

  constructor(
    private readonly certificateService: CertificatesService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadResumes();
  }

  private loadResumes(): void {
    this.status = ComponentStatus.LOADING;

    this.certificateService
      .getAllCertificates()
      .pipe(retry(3), delay(500))
      .subscribe({
        next: (data) => {
          this.certificates = data;
          this.status = ComponentStatus.OK;
          if (this.certificates?.length) {
            this.toastService.showSuccess(this.translateService.instant('certificate.toast.success'));
          }
        },
        error: () => {
          this.status = ComponentStatus.ERROR;
          this.toastService.showError(this.translateService.instant('certificate.toast.error'));
        }
      });
  }
}
