import { AfterViewInit, Component, HostBinding, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-guidelines',
  templateUrl: 'guidelines.component.html',
  styleUrls: ['./guidelines.component.scss']
})
export class GuidelinesComponent implements OnInit, AfterViewInit {
  @HostBinding('class') public class: string = 'page';

  public elmsIds: string[] = [
    'personal-details',
    'summary',
    'certifications',
    'degrees',
    'technical-skills',
    'languages',
    'functional-skills',

    'recent-professional-experience',
    'context',
    'responsibilities',
    'achievements',
    'technical-environment'
  ];
  public viewBox: string = '0 0 1346 1477';
  public width: string = '100%';

  @HostListener('window:resize')
  public onResize(): void {
    this.detectScreenSize();
  }

  public ngOnInit(): void {
    setTimeout((): void => {
      this.showHideElements(true);
    }, 3000);
    this.scrollOnClick();
  }

  public ngAfterViewInit(): void {
    this.showHideElements();
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    if (window.innerWidth < 992) {
      this.viewBox = '400 0 1346 1477';
      this.width = '200%';
    } else {
      this.viewBox = '0 0 1346 1477';
      this.width = '100%';
    }
  }

  private showHideElements(show?: boolean): void {
    const opacity: string = show ? '1' : '0';

    for (const id of this.elmsIds) {
      this.setElementEffects(id, opacity);
      this.setElementEffects(`${id  }-line`, opacity);
    }
  }

  private setElementEffects(id: string, opacity: string): void {
    const elem: HTMLElement = document.getElementById(id) as HTMLElement;
    elem.style.opacity = opacity;
    elem.classList.toggle('fade-in');
  }

  private scrollOnClick(): void {
    for (const id of this.elmsIds) {
      const description: HTMLElement = document.getElementById(id) as HTMLElement;
      this.scroll(description, id);
      const title: HTMLElement = document.getElementById(`${id  }-title`) as HTMLElement;
      this.scroll(title, id);
    }
  }

  private scroll(description: Element, id: string): void {
    (description as HTMLElement).style.cursor = 'pointer';
    description.addEventListener('click', (): void => {
      document.getElementById(`description-${  id}`)?.scrollIntoView({ behavior: 'smooth' });
    });
  }
}
