import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { TrainingPath } from '../models/certification-path/training-path';

@Injectable({
  providedIn: 'root'
})
export class CertificationPathService {
  private readonly jsonUrl = 'assets/quiz/certification-paths.json';

  constructor(private readonly http: HttpClient) {}

  getTrainingPaths(): Observable<TrainingPath[]> {
    return this.http.get<any>(this.jsonUrl).pipe(
      map((data: any) => {
        if (Array.isArray(data)) {
          return data.map((item: any) => item.trainingPath as TrainingPath);
        } else if (data && data.trainingPath) {
          return [data.trainingPath as TrainingPath];
        }
        return [];
      })
    );
  }
}
