<h3>
  <b>{{ 'resume.stepper.stepsToFollow' | translate }}</b>
</h3>
<ul>
  <li>
    <app-stepper-header
      [stepNumber]="'1'"
      [stepTitle]="'resume.stepper.createTitle' | translate"></app-stepper-header>
    <div class="stepper-item">
      <app-stepper-item [innerHTML]="'resume.stepper.createText' | translate"></app-stepper-item>
    </div>
  </li>

  <li>
    <app-stepper-header
      [stepNumber]="'2'"
      [stepTitle]="'resume.stepper.uploadTitle' | translate"></app-stepper-header>
    <div class="stepper-item">
      <app-stepper-item [innerHTML]="'resume.stepper.uploadText' | translate"></app-stepper-item>
    </div>
  </li>

  <li>
    <app-stepper-header
      [stepNumber]="'3'"
      [stepTitle]="'resume.stepper.reviewTitle' | translate"></app-stepper-header>
    <div class="stepper-item">
      <app-stepper-item [innerHTML]="'resume.stepper.reviewText' | translate"></app-stepper-item>
    </div>
  </li>

  <li>
    <app-stepper-header
      [stepNumber]="'4'"
      [stepTitle]="'resume.stepper.updateTitle' | translate"></app-stepper-header>
    <div class="stepper-item">
      <app-stepper-item [innerHTML]="'resume.stepper.updateText' | translate"></app-stepper-item>
    </div>
  </li>
</ul>
