import { Injectable, NgZone } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { PopupComponent } from '../shared/components/custom-element/popup/popup.component';
import { Colleague } from '../models/colleague';

export const POPUP_TAGNAME: string = 'marker-pop-up';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  public constructor(private readonly zone: NgZone) {}

  /**
   * Create a popup which will be used on the map. This popup is created using
   * @angular/elements.
   * @param colleagues: array of colleagues to pass to the popup.
   */
  public createPopup(colleagues: Colleague[]): any {
    let popup!: NgElement & WithProperties<PopupComponent>;
    this.zone.run(() => {
      popup = document.createElement(POPUP_TAGNAME) as any;
      popup.addEventListener('close', () => document.body.removeChild(popup));
      popup.colleagues = colleagues;
      document.body.appendChild(popup);
    });
    return popup;
  }
}
