<li
  id="menu-item-{{ id }}"
  class="menu-item menu-item-{{ id }} nav-item"
  [ngClass]="{ 'dropdown menu-item-has-children': isDropdownMenu() }"
  [routerLinkActive]="['active']">
  <a
    [routerLink]="link"
    class="nav-link"
    [routerLinkActive]="['active']"
    data-toggle="dropdown"
    role="button"
    aria-expanded="false">
    <i class="the-arrow fal fa-long-arrow-left"></i>
    {{ title | translate }}
  </a>
  <div
    *ngIf="isDropdownMenu()"
    class="dropdown-menu">
    <ul>
      <app-list-item-child
        *ngFor="let child of children; index as i"
        [id]="i"
        [title]="child.title"
        [link]="child.link"></app-list-item-child>
      <li class="menu-item view-all nav-item">
        <a
          [routerLink]="link"
          class="menu-item view-all dropdown-item"
          role="button">
          {{ 'header.mainMenu.viewAll' | translate }}
        </a>
      </li>
    </ul>
  </div>
</li>
