<div class="resumes-page">
  <div class="page">
    <h1>{{ 'resume.title' | translate }}</h1>
    <h2 [innerHtml]="'resume.description' | translate"></h2>
    <div class="resume-container">
      <div class="stepper">
        <app-stepper></app-stepper>
      </div>
      <div class="upload-container">
        <app-upload
          [isResumeListLoading]="status === ComponentStatus.LOADING"
          class="uploader"
          (uploadComplete)="refreshResumes()"></app-upload>
        <div [ngSwitch]="status">
          <div
            class="loading"
            *ngSwitchCase="ComponentStatus.LOADING">
            <app-loading></app-loading>
          </div>

          <div *ngSwitchCase="ComponentStatus.ERROR">
            <p class="text-danger">{{ 'resume.errorMessage' | translate }}</p>
          </div>

          <div *ngSwitchDefault>
            <ng-container *ngIf="resumes?.length; else noResumes">
              <div class="resumes-list">
                <h4 class="resume-title">{{ 'resume.subTitle' | translate }}</h4>
                <p>{{ 'resume.mastercv.subtitle' | translate }}</p>
                <div
                  class="resume-card-container"
                  *ngFor="let resume of resumes; let i = index"
                  [ngStyle]="{ 'margin-bottom': resumes.length ? '3rem' : '0' }">
                  <app-resume-card
                    (removeResume)="removeResume(i)"
                    (unsetResumes)="unsetResumes($event)"
                    [resume]="resume"></app-resume-card>
                </div>
              </div>
            </ng-container>
            <ng-template #noResumes>
              <div
                class="no-resumes"
                [innerHtml]="'resume.noResume' | translate"></div>
            </ng-template>
          </div>
        </div>

        <app-create-resume-card></app-create-resume-card>
      </div>
    </div>
  </div>
</div>
