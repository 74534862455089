import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  environment: environment.name,
  dsn: environment.dsn,
  integrations: [
    // Registers and configures the Tracing integration,
    // Which automatically instruments your application to monitor its
    // Performance, including custom Angular routing instrumentation
    new BrowserTracing({
      tracingOrigins: [environment.apiUrl, /^\//],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // Of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
