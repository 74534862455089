<!-- Toolbar -->
<nav
  ngxHideOnScroll
  [classNameWhenHidden]="'nav--hidden'">
  <app-navigation-bar
    [listItems]="listItems"
    [isAuth]="isAuthorized"
    (login)="login()"
    (logout)="logout()"
    [supportedLanguages]="supportedLanguages"
    [currentLanguage]="currentLanguage"
    (changeLanguage)="changeLanguage($event)"></app-navigation-bar>
</nav>
<div
  id="content"
  class="site-content">
  <!-- Content -->
  <div
    id="primary"
    class="content-area">
    <app-toast></app-toast>
    <main
      id="main"
      class="site-main">
      <router-outlet></router-outlet>
    </main>
  </div>
  <!-- Footer -->
  <app-footer></app-footer>
  <app-support-button *ngIf="showChatbot && isAuthorized"></app-support-button>
</div>
