import { Injectable } from '@angular/core';
import introJs from 'intro.js';

@Injectable({
  providedIn: 'root'
})
export class TourService {
  public launchCVEditorTour(): void {
    introJs()
      .setOptions({
        dontShowAgain: true,
        disableInteraction: true,
        steps: [
          {
            title: 'Welcome on the CV Generator',
            intro:
              'The CV Generator tool has been designed to help you easily create, update, preview, download, and publish your Devoteam resume.'
          },
          {
            element: document.querySelector('.data-cv-preview') as HTMLElement | undefined,
            title: 'CV Preview',
            intro: 'As you change the values in the forms, you can see the preview updating in real-time.'
          },
          {
            element: document.querySelector('.data-progress') as HTMLElement | undefined,
            title: 'Progress Bar',
            intro: `The progress bar on the CV builder indicates the completion status of your CV. It displays a percentage on the right side of the bar. To generate your CV, you must achieve 100% completion, meaning that all sections, except for Certifications (if you don't have any), must be completed.`
          },
          {
            element: document.querySelector('.data-progress-icon') as HTMLElement | undefined,
            title: 'Icons',
            intro: `To access each section, simply click on the corresponding icon located under the progress bar. An icon that is filled indicates that the section is completed, while an outlined icon indicates that the section has not yet been filled in.`
          },
          {
            element: document.querySelector('.data-cv-download-button') as HTMLElement | undefined,
            title: 'Download CV',
            intro: `To download your CV, simply click on the button and choose the format you prefer (PDF or DOCX). The file will be saved on your local machine for future use.`
          },
          {
            element: document.querySelector('.data-cv-publish-button') as HTMLElement | undefined,
            title: 'Publish CV',
            intro: `By clicking on this button, you can directly upload your CV into the system without the need to download it onto your local machine first. This saves you time and effort, and allows for a seamless experience.`
          }
        ]
      })
      .start();
  }
}
