import { Component, OnInit } from '@angular/core';

import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { OfflineService } from './pages/app-offline/offline.service';
import { AuthService } from './services/auth.service';
import { LANDING_PAGE_URL } from './shared/utils/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public previousUrl: string = '';
  public currentUrl: string = '';

  constructor(
    private readonly offlineService: OfflineService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    if (this.offlineService.isAppOffline()) {
      this.router.navigateByUrl('offline');
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (this.authService.isAuthorized && e.url === '/') {
          this.router.navigateByUrl(LANDING_PAGE_URL);
        }
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      if (this.currentUrl === '/server-error' && this.previousUrl === '') {
        this.router.navigateByUrl(LANDING_PAGE_URL);
      }
    });
  }
}
