<div
  class="popup-map-card"
  *ngIf="colleagues && colleagues.length > 0">
  <div class="popup-map-card-header">
    <div class="popup-map-card-title">
      <span>{{ colleagues[0].location?.city ? colleagues[0].location?.city : colleagues[0].zip }}</span>
      <span> - </span>
      <span>{{ colleagues[0].location?.zip }}</span>
    </div>
    <app-button label="{{ colleagues.length }}"></app-button>
  </div>
  <div
    *ngFor="let colleague of colleagues"
    class="popup-map-card-content">
    <div class="colleague-data">
      <div class="image-container">
        <img
          src="{{ colleague.picture || '../../../../../assets/img/image-3.png' }}"
          alt=""
          class="colleague-photo" />
      </div>
      <div class="colleague-data-details">
        <div>{{ colleague.name }}</div>
        <div>({{ colleague.practice }})</div>
      </div>
      <span
        class="copy-icon"
        (click)="copyEmail(colleague.email)"
        [tooltip]="'dashboard.copy-email' | translate">
        <app-icon
          [type]="'regular'"
          [icon]="'clone'"
          [size]="'14'"></app-icon>
      </span>
    </div>
    <div class="hrl"></div>
  </div>
</div>
