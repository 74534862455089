import { Component, ElementRef, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent {
  public referralUrl: string = environment.referralUrl;
  public intranetBeUrl: string = environment.intranetBeUrl;
  public intranetEmeaUrl: string = environment.intranetEmeaUrl;
  public portalUrl: string = environment.portalUrl;
  public appsPortalUrl: string = environment.appsPortalUrl;
  public isOpen: boolean = false;

  public constructor(private readonly eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public clickOutside(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  public toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  public closeMenu(): void {
    if (this.isOpen) this.isOpen = false;
  }
}
