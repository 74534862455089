import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from './menu';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {
  @Input()
  public listItems!: ListItem[];

  @Input()
  public isAuth!: boolean;

  @Output()
    login: EventEmitter<any> = new EventEmitter();

  @Output()
    logout: EventEmitter<any> = new EventEmitter();

  @Input()
  public supportedLanguages!: Object;

  @Input()
  public currentLanguage!: string;

  @Output()
    changeLanguage: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
