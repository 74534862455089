import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, Subscription, timer } from 'rxjs';
import { OfflineService } from './offline.service';

@Component({
  selector: 'app-app-offline',
  templateUrl: './app-offline.component.html',
  styleUrls: ['./app-offline.component.scss']
})
export class AppOfflineComponent implements OnInit, OnDestroy {
  constructor(private readonly offlineService: OfflineService, private readonly router: Router) {}

  timerSub: Subscription = new Subscription();
  ngOnInit(): void {
    if (!this.offlineService.isAppOffline()) {
      this.router.navigateByUrl('dashboard');
    } else {
      const delay = this.offlineService.getNextOnlineTime().getTime() - new Date().getTime();
      this.timerSub = timer(delay)
        .pipe(
          map(() => {
            this.router.navigateByUrl('dashboard'); // Load data contains the http request
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    console.log('Unsubscribed');
    this.timerSub.unsubscribe();
  }
}
