import { Component } from '@angular/core';

@Component({
  selector: 'app-component-logo-main',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 449.1 132.5"
      class="logo-devoteam"
      role="img"
      aria-label="Devoteam">
      <title>Devoteam</title>
      <path
        class="text"
        d="M344.4,56.2c-7.8,0-15.8,2.2-15.8,18.7,0,6.5.9,10.5,3.1,13.2,2.3,3,6.1,4.4,11.9,4.4a106.92,106.92,0,0,0,12.7-.9l.6-.1V86.3H345.3c-6.8,0-9.1-1.8-9.1-7.2v-2h18.3c2.8,0,4.6-1.9,4.6-4.8V70.2c-.1-9.3-5.1-14-14.7-14m7.3,15.2H336.1V70c0-3.4,1.2-7.5,6.8-7.5h2.8c5,0,6,3,6,7.5v1.4ZM283.8,56.2h-1.7c-10.5,0-15.2,5.7-15.2,18.6,0,10.6,2.6,17.7,15.2,17.7h1.7c10.3,0,15.1-5.8,15.1-18.1,0-12.8-4.5-18.2-15.1-18.2m7.5,21c0,6.5-2.1,9.2-7.4,9.2H282c-6.3,0-7.4-2.8-7.4-10.8V73.2c0-8.5,1.7-10.8,7.8-10.8h1.1c5.7,0,7.8,1.6,7.8,10.8Zm-75.9-21c-7.8,0-15.8,2.2-15.8,18.7,0,6.5.9,10.5,3,13.2,2.3,3,6.1,4.4,11.9,4.4a106.92,106.92,0,0,0,12.7-.9l.6-.1V86.3H216.2c-6.8,0-9.1-1.8-9.1-7.2v-2h18.3c2.8,0,4.6-1.9,4.6-4.8V70.2c.1-9.3-4.9-14-14.6-14m7.4,15.2H207.2V70c0-3.4,1.2-7.5,6.8-7.5h2.8c5,0,6,3,6,7.5ZM186.9,45.6V57h-10c-6.8.1-13.5,1.9-13.5,17.9,0,16.3,6.3,17.5,15.2,17.5a104.24,104.24,0,0,0,15.1-1l.6-.1V45.6Zm0,40.5h-8.1c-6.1,0-7.7-2-7.7-9.4V72.6c0-6.8,2.2-9.1,8.3-9.1h7.5ZM379.4,56.2a83.06,83.06,0,0,0-11.5.8l-.6.1v5.5h12.6c4.6,0,6.3,1.6,6.3,6v1.7a84.64,84.64,0,0,0-10.8-.8c-5.7,0-13.3,1.3-13.3,12.1,0,10.1,6.2,10.9,14.3,10.9a113.94,113.94,0,0,0,16.2-1.3l.6-.1.1-22.7c0-10.7-6.6-12.2-13.9-12.2m6.8,29.9H374.8c-3.6,0-5-1.2-5-4.3V80.5c0-3.2,1.7-4.6,5.4-4.6h11ZM248.3,82.8,237.6,57.1H230l14.4,34.7h7.3l14.1-34.7h-7.4Zm65.6-1.5V62.8h10.6V57H314V46.1h-7V57h-5.6v5.9h5.2V81.5c0,5.5,1.3,11.2,10.6,11.2a38.26,38.26,0,0,0,8.6-1.1l.5-.1V86.3H319c-4-.1-5.1-1.2-5.1-5M435.4,56.1a58.14,58.14,0,0,0-11.9,1.4c-2.5-1.3-5.4-1.4-8.3-1.4a101.12,101.12,0,0,0-14.6,1.4V91.7H408v-29h6.8c5,0,6.5,1.6,6.5,7.2V91.8h7.4V67.9a14.34,14.34,0,0,0-.8-5.1h6.3c5.6,0,7.4,1.7,7.4,7.2V91.8h7.3V68.2c.2-12.1-9-12.1-13.5-12.1" />
      <path
        class="circle"
        d="M66.3,0a66.3,66.3,0,1,0,66.2,66.3A66.32,66.32,0,0,0,66.3,0m44.1,61.3H85v46.3H54.9c-16.3,0-31-9.9-31-31,0-15.7,8.2-31,31-31h7a86.83,86.83,0,0,1,3.9,15.9H55.3c-9.4,0-14.7,5.1-14.7,15.2,0,9.5,5,15.2,14.7,15.2H68.7V22.1H85V45.7h25.4V61.3Z" />
    </svg>
  `,
  styles: []
})
export class MainLogoComponent {}
