import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../../utils/base-value-accessor';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AutocompleteInputComponent,
      multi: true
    }
  ]
})
export class AutocompleteInputComponent extends BaseValueAccessor implements OnInit, OnDestroy {
  private static instanceCount: number = 0;

  @Input() label: string;
  @Input() list: string[] = [];
  @Input() public placeholder?: string = '';
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Output() isFocused: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('dropdownPanel')
  private readonly dropdownPanel: ElementRef<HTMLDivElement>;
  @ViewChild('searchInput')
  private readonly input: ElementRef<HTMLInputElement>;

  public excludeList: string[] = [];
  public focused: boolean = false;
  public id: string;
  public textInputForm: FormGroup;

  private readonly instanceId: number;
  private readonly valueChangesSubscription: Subscription | undefined = undefined;

  constructor(private readonly fb: FormBuilder, private readonly elementRef: ElementRef) {
    super();
    this.instanceId = AutocompleteInputComponent.instanceCount++;
    this.textInputForm = this.fb.group({
      inputValue: ['']
    });
    this.valueChangesSubscription = this.textInputForm
      .get('inputValue')
      ?.valueChanges.subscribe((value: string): void => {
        if (this.input) {
          const isInputFocused = this.input.nativeElement === document.activeElement;
          this.focused = !isInputFocused ? false : true;
        }
        this.onChange(value);
        this.excludeList = this.list.filter((item) => !item.toLowerCase().includes(value.toLowerCase()));
        if (this.valueChanged) this.valueChanged.emit(value);
      });
  }

  ngOnInit(): void {
    this.id = `${this.label}-${this.instanceId}`;
  }

  override ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  public onFocus() {
    this.focused = true;
    const value = this.textInputForm.get('inputValue')?.value;
    if (value) this.excludeList = this.list.filter((item) => !item.toLowerCase().includes(value.toLowerCase()));
    this.isFocused.emit(this.focused);
  }

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      const textareaElement = this.elementRef.nativeElement.querySelector('.input input');
      if (textareaElement) {
        textareaElement.blur();
      }
    }
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent): void {
    if (event.target instanceof HTMLElement && event.target.parentElement) {
      if (
        !this.dropdownPanel.nativeElement.contains(event.target) &&
        !this.input.nativeElement.contains(event.target)
      ) {
        this.focused = false;
        this.isFocused.emit(this.focused);
      }
    }
  }

  public override writeValue(value: string | null): void {
    this.textInputForm.get('inputValue')?.setValue(value);
  }

  public selectItem(item: string) {
    this.focused = false;
    this.isFocused.emit(this.focused);
    this.textInputForm.get('inputValue')?.setValue(item);
  }
}
