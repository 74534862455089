import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../../utils/base-value-accessor';
import { TEXT_AREA_ROWS } from '../../../utils/constants';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextAreaComponent,
      multi: true
    }
  ]
})
export class TextAreaComponent extends BaseValueAccessor {
  private static instanceCount: number = 0;

  @Input() public label?: string;
  @Input() public placeholder?: string = '';
  @Input() public rows: number = TEXT_AREA_ROWS;

  public textareaForm: FormGroup;
  public focused: boolean = false;
  public id: string;

  private readonly instanceId: number;
  private readonly valueChangesSubscription: Subscription | undefined = undefined;

  public constructor(private readonly fb: FormBuilder, private readonly elementRef: ElementRef) {
    super();
    this.instanceId = TextAreaComponent.instanceCount++;
    this.textareaForm = this.fb.group({
      inputValue: ['']
    });
    this.valueChangesSubscription = this.textareaForm
      .get('inputValue')
      ?.valueChanges.subscribe((value: string): void => {
        this.onChange(value);
      });
  }

  public ngOnInit(): void {
    this.id = `field${this.label}-${this.instanceId}`;
  }

  override ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  @HostListener('document:keyup.escape', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      const textareaElement = this.elementRef.nativeElement.querySelector('.input textarea');
      if (textareaElement) {
        textareaElement.blur();
      }
    }
  }

  public override writeValue(value: string | null): void {
    if (value !== this.textareaForm.get('inputValue')?.value) {
      this.textareaForm.get('inputValue')?.setValue(value);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
  }
}
