import { Component, Input } from '@angular/core';
import { ListItemChild } from '../../../menu';

/**
 * The dropdown child of a navigation option on the navigation bar.
 */
@Component({
  selector: 'app-list-item-child',
  templateUrl: './list-item-child.component.html',
  styleUrls: ['./list-item-child.component.scss']
})
export class ListItemChildComponent implements ListItemChild {
  @Input() id!: number;
  @Input() title!: string;
  @Input() link!: string;
}
