<form
  [formGroup]="datePickerForm"
  class="input-wrapper">
  <label
    [for]="label"
    [ngClass]="focused ? 'active' : ''"
    >{{ label }}</label
  >
  <input
    *ngIf="checkValue()"
    formControlName="inputValue"
    type="text"
    [attr.disabled]="true"
    [placeholder]="placeholder"
    id="{{ id }}"
    name="{{ label }}"
    autocomplete="off"
    (focus)="onFocus()"
    (blur)="onBlur()" />
  <input
    *ngIf="!checkValue()"
    formControlName="inputValue"
    type="text"
    [placeholder]="placeholder"
    bsDatepicker
    id="{{ id }}"
    name="{{ label }}"
    autocomplete="off"
    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', useUtc: false, initCurrentTime: true }"
    (focus)="onFocus()"
    (blur)="onBlur()" />
</form>
