import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CvPageIndexService } from '../../../../services/cv-page-index.service';
import { SubscriptionBaseDirective } from '../../../../shared/directives/subscription-base.directive';

@Component({
  selector: 'app-cv-page-navigator',
  templateUrl: './cv-page-navigator.component.html',
  styleUrls: ['./cv-page-navigator.component.scss']
})
export class CvPageNavigatorComponent extends SubscriptionBaseDirective implements OnInit {
  public currentPage: number = 1;
  public totalPages: number;

  public constructor(private readonly cvPageIndexService: CvPageIndexService, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this.cvPageIndexService.totalPagesObs.subscribe((index) => {
        this.totalPages = index;
        this.cdr.detectChanges();
      })
    );
    this.addSubscription(
      this.cvPageIndexService.currentPageIndexObs.subscribe((index) => {
        this.currentPage = index;
        this.cdr.detectChanges();
      })
    );
  }

  public previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.cvPageIndexService.updateCurrentPage(this.currentPage);
    }
  }
  public nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.cvPageIndexService.updateCurrentPage(this.currentPage);
    }
  }
}
