import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertType } from './alert-type';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Output() remove = new EventEmitter<number>();
  @Input() title!: string;
  @Input() type: AlertType = AlertType.secondary;
  @Input() id?: number;

  constructor() {}

  ngOnInit(): void {}

  dismiss(alert: HTMLDivElement) {
    alert.remove();
    this.remove.emit(this.id);
  }
}
