<div class="wrapper">
  <p class="name word-wrap">{{ certificate.name }}</p>
  <p class="issuer">{{ certificate.issuer }}</p>
  <p
    class="word-wrap"
    *ngIf="certificate.id">
    ID: {{ certificate.id }}
  </p>
  <p *ngIf="certificate.date">Date: {{ certificate.date | formatDateString }}</p>
</div>
