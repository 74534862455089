import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '../../directives/directives.module';
import { ModalComponent } from './modal.component';
import { ModalBodyComponent } from './modal-body/modal-body.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';

@NgModule({
  declarations: [ModalComponent, ModalBodyComponent, ModalFooterComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [ModalComponent, ModalBodyComponent, ModalFooterComponent]
})
export class ModalModule {}
