import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-body',
  template: '<ng-content></ng-content>'
})
export class ModalBodyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
