import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      // eslint-disable-next-line @typescript-eslint/dot-notation, dot-notation
      return params.interpolateParams['Default'] || params.key;
    }
    return params.key;
  }
}
