<div class="button-wrapper">
  <button
    type="button"
    class="button-element"
    [disabled]="disabled"
    (click)="toggleDropdown()">
    <span class="label">{{ label }}</span>
    <app-icon
      [type]="'solid'"
      [icon]="showDropdown ? 'chevron-down' : 'chevron-up'"
      [size]="'14'"></app-icon>
  </button>
  <ul
    *ngIf="showDropdown"
    class="dropdown-wrapper">
    <li
      class="option"
      *ngFor="let option of downloadOptions"
      (click)="download(option)">
      {{ option }}
    </li>
  </ul>
</div>
