import { OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export class SubscriptionBaseDirective implements OnDestroy {
  private readonly subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public addSubscription(newSub: Subscription): void {
    this.subscription.add(newSub);
  }
}
