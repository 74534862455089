import { Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../../utils/base-value-accessor';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true
    }
  ]
})
export class DropdownComponent extends BaseValueAccessor implements OnInit, OnDestroy {
  private static instanceCount: number = 0;

  @Input() label: string;
  @Input() list: string[];
  @Input() public placeholder?: string = '';

  public filteredList: string[];
  public focused: boolean = false;
  public id: string;
  public dorpDownForm: FormGroup;

  private readonly instanceId: number;
  private readonly valueChangesSubscription: Subscription | undefined = undefined;

  constructor(private readonly fb: FormBuilder, private readonly sanitizer: DomSanitizer) {
    super();
    this.instanceId = DropdownComponent.instanceCount++;
    this.dorpDownForm = this.fb.group({
      inputValue: ['']
    });
    this.valueChangesSubscription = this.dorpDownForm
      .get('inputValue')
      ?.valueChanges.subscribe((value: string): void => {
        this.onChange(value);
      });
  }

  ngOnInit(): void {
    this.id = `${this.label}-${this.instanceId}`;
  }

  override ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  public override writeValue(value: string | null): void {
    if (value !== this.dorpDownForm.get('inputValue')?.value) {
      const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, value);
      this.dorpDownForm.get('inputValue')?.setValue(sanitizedValue);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
  }
}
