<div class="general-info-card">
  <div class="title-wrapper">
    <h2 class="title">{{ 'dashboard.generalInfo.title' | translate }}</h2>
  </div>
  <div class="body">
    <div
      class="general-info"
      [ngSwitch]="status">
      <div
        class="loading"
        *ngSwitchCase="ComponentStatus.LOADING">
        <app-loading></app-loading>
      </div>

      <div *ngSwitchCase="ComponentStatus.ERROR">
        <p class="text-danger">
          {{ errorMessage | translate : { Default: 'app.errors.default' } | translate }}
        </p>
      </div>

      <div *ngSwitchDefault>
        <ul class="list">
          <li>
            <span class="label">{{ 'dashboard.generalInfo.body.rank' | translate }}:</span> {{ profile.rank }}
          </li>
          <li>
            <span class="label">{{ 'dashboard.generalInfo.body.peopleManager' | translate }}:</span>
            {{ profile.peopleManager }}
          </li>
          <li>
            <span class="label">{{ 'dashboard.generalInfo.body.unit' | translate }}:</span> {{ profile.unit }}
          </li>
          <li>
            <span class="label">{{ 'dashboard.generalInfo.body.unitManager' | translate }}:</span>
            {{ profile.unitManager }}
          </li>
          <li>
            <span class="label">{{ 'dashboard.generalInfo.body.practice' | translate }}:</span> {{ profile.practice }}
          </li>
          <li>
            <span class="label">{{ 'dashboard.generalInfo.body.practiceManager' | translate }}:</span>
            {{ profile.practiceManager }}
          </li>
          <li>
            <span class="label">{{ 'dashboard.generalInfo.body.email' | translate }}:</span> {{ profile.emailAddress }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
