import { Component, Input, OnInit } from '@angular/core';
import { Certification } from '../../../../models/cv';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  @Input() public certificate: Certification;

  constructor() {}

  ngOnInit(): void {}
}
