// Certifications.component.ts
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Certification, certifications } from './certifications';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent {
  certifications: Certification[] = certifications;

  constructor(private readonly router: Router) {}

  navigateToCertification(id: string): void {
    this.router.navigate(['/quiz', id]);
  }
}
