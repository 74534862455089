<div class="wpml-ls-legacy-dropdown-click">
  <ul>
    <li class="wpml-ls-item wpml-ls-current-language">
      <a
        class="wpml-ls-item-toggle cursor-pointer"
        (click)="toggleMenu()">
        <ng-container>{{ 'nav.quick-links.title' | translate }}</ng-container>
      </a>

      <ul
        class="wpml-ls-sub-menu"
        [ngStyle]="{ visibility: isOpen ? 'visible' : 'hidden' }">
        <ng-container>
          <li>
            <a
              target="_blank"
              href="{{ referralUrl }}"
              >{{ 'nav.quick-links.referral' | translate }}</a
            >
          </li>

          <li>
            <a
              target="_blank"
              href="{{ intranetBeUrl }}"
              >{{ 'nav.quick-links.intranetBe' | translate }}</a
            >
          </li>

          <li>
            <a
              target="_blank"
              href="{{ intranetEmeaUrl }}"
              >{{ 'nav.quick-links.intranetEmea' | translate }}</a
            >
          </li>

          <li>
            <a
              target="_blank"
              href="{{ portalUrl }}"
              >{{ 'nav.quick-links.portal' | translate }}</a
            >
          </li>

          <li>
            <a
              target="_blank"
              href="{{ appsPortalUrl }}"
              >{{ 'nav.quick-links.appsPortal' | translate }}</a
            >
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</div>
