import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { Colleague } from '../../../../models/colleague';
import { copyToClipboard } from '../../../utils/copy-content';
import { ToastService } from '../../toast/toast.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  @Input() colleagues: Colleague[];

  public constructor(
    private readonly toastService: ToastService,
    private readonly translate: TranslateService,
    private readonly clipboardService: ClipboardService
  ) {}

  public copyEmail(email: string): void {
    copyToClipboard(email, this.clipboardService);
    this.toastService.showSuccess(this.translate.instant('dashboard.copy-email-success'));
  }
}
