<div class="support-button-container">
  <button
    class="support-button"
    [tooltip]="'Need help? Use the chatbot'"
    (click)="openChat()">
    <i
      class="fa fa-question"
      aria-hidden="true">
    </i>
  </button>
  <app-support-email-button></app-support-email-button>
</div>

<app-chatbot
  [show]="showChat"
  (closeChat)="close($event)">
</app-chatbot>
