import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * TextView components displays the text with Devoteam branding (font, size, color, etc).
 * By Default,  TextView will do a Translate look up.
 * Custom style can also  be added.
 *
 * Examples:
 * NORMAL TEXT
      <app-text-view text="normal.text"></app-text-view>
 * NORMAL TEXT WITH NO TRANSLATION
      <app-text-view text="Normal Text with not translatation" [noTranslate]=true></app-text-view>
 * Page Title
      <app-text-view text="page.title" textType="pageTitle"></app-text-view>
 * Page Titel with color green
      <app-text-view text="page.title.green" textType="pageTitle" style="color:green"></app-text-view>
  * error text
      <app-text-view text="error.message" textType="error"></app-text-view>
 */
@Component({
  selector: 'app-text-view',
  templateUrl: './text-view.component.html',
  styleUrls: ['./text-view.component.scss']
})
export class TextViewComponent implements OnInit {
  constructor(private readonly translate: TranslateService) {}

  /**
   *  PASS ONE OF THE FOLLOWING AS TYPE:
   *    pageTitle: Title for a page
   *    pageSubTitle: Subtitle for a page
   *    cardTitle: Title for a card  or component
   *    message: Message for the users
   *    error:  Error for the users
   *    boldText: Bold text
   *    generalText: General Text or leave it empty
   */
  @Input() type!: string;

  /**
   *  Translate id of the text that needs to be displayed to users.
   */
  @Input() text!: string;

  /**
   *  Any additional  styling (Ex: 'color: <some-color>;')
   */
  @Input() style!: string;

  /**
   *  False by default.
   *  To just display the text that is passed, make  this TRUE.True if Translate should not be used, set this to true
   */
  @Input() noTranslate: boolean = false;

  /**
   *  True by default.
   *  If Linebreak is needed before the text, set this to false.
   */
  @Input() topLineBreak: boolean = true;

  /**
   *  True by default.
   *  If Linebreak is needed after the text, set this to false.
   */
  @Input() bottomLineBreak: boolean = true;

  ngOnInit(): void {}

  getStyleClass(): string {
    return this.type?.length ? this.type : 'normalText';
  }

  getStyle(): string {
    return this.style;
  }

  getText(): string {
    return this.noTranslate ? this.text : this.translate.instant(this.text);
  }
}
