<div
  class="position"
  [ngStyle]="{ display: show ? 'block' : 'none' }">
  <div class="chat">
    <header class="chat-header">
      <div class="name">My Devoteam Chatbot</div>
      <button
        class="close-button"
        (click)="close()">
        &times;
      </button>
    </header>
    <main
      class="messages"
      #scrollBottom>
      <ng-container *ngFor="let message of messages">
        <div
          class="message {{ message.from }}"
          [innerHTML]="sanitizeMessage(message.text)"></div>
      </ng-container>
    </main>
    <footer class="input">
      <input
        placeholder="Ask your question here!"
        type="text"
        [(ngModel)]="text"
        (keyup.enter)="sendMessage()" />
      <span
        [tooltip]="'Send message'"
        (click)="sendMessage()">
        <i
          class="fa fa-paper-plane"
          aria-hidden="true">
        </i>
      </span>
      <span [tooltip]="'Send email'">
        <app-icon
          (click)="sendEmail()"
          [type]="'solid'"
          [icon]="'envelope'"
          [size]="'18'"></app-icon>
      </span>
    </footer>
  </div>
</div>
