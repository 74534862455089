import { Component, OnInit } from '@angular/core';
import { QuizService, QuizStatsInfo } from 'src/app/services/quiz.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public quizStats: QuizStatsInfo[] = [];

  public constructor(private readonly service: QuizService) {}

  public ngOnInit(): void {
    this.loadQuizStats();
  }

  private loadQuizStats(): void {
    this.service.getAllQuizStats().subscribe(
      (stats) => {
        this.quizStats = stats; // Store the fetched stats into the component's property
      },
      (error) => {
        console.error('Error fetching quiz stats:', error);
      }
    );
  }
}
