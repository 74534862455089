import { Injectable } from '@angular/core';
import { BOD, EOD, UTC_CET } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  constructor() {}

  public isAppOffline(): boolean {
    const cetHour = new Date().getUTCHours() + UTC_CET;
    return cetHour >= EOD || cetHour < BOD ? true : false;
  }

  public getNextOnlineTime(): Date {
    const date: Date = new Date();
    date.setUTCDate(this.getNextOnlineDay(date));
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    return date;
  }

  private getNextOnlineDay(date: Date): number {
    if (date.getUTCHours() >= EOD && date.getUTCHours() <= 23) {
      return date.getUTCDate() + 1;
    }
    return date.getUTCDate();
  }
}
