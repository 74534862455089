<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="style">
  <div
    class="modal-dialog"
    role="document">
    <div
      class="modal-content"
      appClickOutside
      (clickOutside)="clickOutSide($event)">
      <div class="modal-header">
        <h5
          class="modal-title"
          *ngIf="title">
          {{ title }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content select="app-modal-body"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="app-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>

<!--

<app-modal [title]="'hello'" #modalComponentRef>
  <app-modal-body>
    body
  </app-modal-body>
  <app-modal-footer>
    <button type="button" class="btn btn-primary" (click)="modalComponentRef.close()">open</button>
  </app-modal-footer>
</app-modal>


<button type="button" class="btn btn-primary" (click)="modalComponentRef.open()">open</button>

-->
