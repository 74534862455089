import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss']
})
export class ActionCardComponent implements OnInit {
  @Input() text!: string;
  @Input() buttonText!: string;
  @Output() clicked = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  emitClick(event: Event) {
    this.clicked.emit(event);
  }
}
