<div class="card">
  <div [ngClass]="masterCVEnabled ? 'menu-wrapper-master' : 'menu-wrapper'">
    <span
      *ngIf="resume.master"
      class="master-cv"
      >Master CV</span
    >
    <div [ngClass]="resume.master ? 'menu' : 'menu-right'">
      <span
        #toggleMenuBtn
        (click)="toggleMenu()"
        [ngClass]="menuVisible ? 'active-menu' : ''">
        ...
      </span>
      <ul
        #menu
        *ngIf="menuVisible">
        <li *ngIf="masterCVEnabled && !resume.master">
          <a (click)="setAsMaster()">{{ 'resume.mastercv.set' | translate }}</a>
        </li>
        <li *ngIf="masterCVEnabled && resume.master">
          <a (click)="unsetAsMaster()">{{ 'resume.mastercv.unset' | translate }}</a>
        </li>
        <li>
          <a (click)="downloadResume()">{{ 'resume.download.tooltip' | translate }}</a>
        </li>
        <li>
          <a (click)="confirmDeleteResumeRef.open()">{{ 'resume.deleteModal.title' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
  <p class="date">{{ resume.lastEditedOn | date : 'MMMM dd, yyyy HH:mm' }}</p>
  <div class="title-wrapper">
    <span>
      <img
        class="icon"
        [src]="iconUrl" />
    </span>
    <h3
      class="title"
      *ngIf="resume.name.length <= resumeNameMaxLength; else resumeNameTooLong">
      {{ resume.name }}
    </h3>
    <ng-template #resumeNameTooLong>
      <h3 class="title">{{ resume.name | slice : 0 : resumeNameMaxLength }}...</h3>
    </ng-template>
  </div>
  <div
    [ngClass]="masterCVEnabled ? 'icon-wrapper' : 'icons-wrapper'"
    *ngIf="!loading"></div>
  <div
    class="loading"
    *ngIf="loading">
    <app-loading [active]="loading"></app-loading>
  </div>
</div>
<app-confirmation
  [title]="'resume.deleteModal.title' | translate"
  #confirmDeleteResumeRef
  [description]="'resume.deleteModal.description' | translate"
  (closed)="(null)"
  (confirmed)="deleteResume()">
</app-confirmation>
