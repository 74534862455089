<form
  [formGroup]="textareaForm"
  class="text-area-wrapper">
  <label
    for="{{ id }}"
    class="label"
    [ngClass]="focused ? 'active' : ''"
    >{{ label }}</label
  >
  <textarea
    formControlName="inputValue"
    id="{{ id }}"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"
    class="text-area"
    [rows]="rows"></textarea>
</form>
