import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss']
})
export class StepperHeaderComponent implements OnInit {
  @Input()
  public stepNumber!: string;

  @Input()
  public stepTitle!: string;

  constructor() {}

  ngOnInit(): void {}
}
