import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService, MissingTranslationHandler } from '@ngx-translate/core';
import { version } from '../../../version';
import { LOCAL_STORAGE_LANGUAGE_KEY, SUPPORTED_LANGUAGES } from './translation.service';
import { MyMissingTranslationHandler } from './my-missing-translation-handler.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', `.json?v=${version}`);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: SUPPORTED_LANGUAGES.en,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler }
    })
  ],
  providers: [MyMissingTranslationHandler],
  exports: [TranslateModule]
})
export class TranslationModule {
  constructor(translate: TranslateService) {
    translate.addLangs(Object.values(SUPPORTED_LANGUAGES));

    const preferredLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);

    if (!Object.values(SUPPORTED_LANGUAGES).some((supportedLang) => supportedLang === preferredLanguage)) {
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, SUPPORTED_LANGUAGES.en);

      translate.setDefaultLang(SUPPORTED_LANGUAGES.en);
      translate.use(SUPPORTED_LANGUAGES.en);
    } else {
      translate.setDefaultLang(preferredLanguage ? preferredLanguage : SUPPORTED_LANGUAGES.en);
      translate.use(preferredLanguage ? preferredLanguage : SUPPORTED_LANGUAGES.en);
    }
  }
}
