import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from '../modal/modal.module';
import { TranslationModule } from '../../translations/translation.module';
import { ButtonModule } from '../button/button.module';
import { ConfirmationComponent } from './confirmation.component';

@NgModule({
  declarations: [ConfirmationComponent],
  exports: [ConfirmationComponent],
  imports: [CommonModule, ModalModule, TranslationModule, ButtonModule]
})
export class ConfirmationModule {}
