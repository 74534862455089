<div class="wpml-ls-legacy-dropdown-click">
  <ul>
    <li class="wpml-ls-item wpml-ls-current-language">
      <a
        class="wpml-ls-item-toggle cursor-pointer"
        (click)="toggleMenu()">
        <ng-container *ngFor="let language of supportedLanguages | keyvalue">
          <ng-container *ngIf="currentLanguage === language.value">{{ language.key | uppercase }}</ng-container>
        </ng-container>
      </a>

      <ul
        class="wpml-ls-sub-menu"
        [ngStyle]="{ visibility: isOpen ? 'visible' : 'hidden' }">
        <ng-container *ngFor="let language of supportedLanguages | keyvalue">
          <li
            class="wpml-ls-item"
            *ngIf="currentLanguage !== language.value">
            <a
              class="cursor-pointer"
              (click)="switchLanguage(language.value)"
              >{{ language.key | uppercase }}</a
            >
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</div>
