<div class="button-wrapper">
  <button
    [ngStyle]="{ 'background-color': primaryColor, 'color': secondaryColor }"
    type="button"
    class="button-element"
    id="{{ id }}"
    [disabled]="disabled"
    (click)="onClick($event)">
    {{ label }}
  </button>
</div>
