<form
  [formGroup]="multiSelectForm"
  class="input-wrapper">
  <label [for]="label">{{ label }}</label>
  <div
    class="tag-holder"
    [ngClass]="{ active: isFocused }">
    <ng-container *ngFor="let tag of tags; let i = index">
      <span
        class="drop-slot"
        (dragenter)="onDragEnter($event, i)"
        (dragover)="onDragOver($event)">
        <span
          *ngIf="previewDropIndex === i && draggingIndex >= i"
          class="drop-preview tag">
          {{ draggingTag }}
        </span>
        <span
          draggable="true"
          (dragstart)="onDragStart($event, i)"
          (dragend)="onDragEnd()"
          [ngClass]="{ tag: true, hidden: i === draggingIndex }">
          {{ tag }}
          <span class="close-icon">
            <app-icon
              (click)="onDelete(i)"
              classes="close-icon"
              color="white"
              icon="close"></app-icon>
          </span>
        </span>
        <span
          *ngIf="previewDropIndex === i && draggingIndex < i"
          class="drop-preview tag">
          {{ draggingTag }}
        </span>
      </span>
      <span
        *ngIf="i === tags.length - 1"
        (dragenter)="onDragEnter($event, i + 1)"
        (dragover)="onDragOver($event)"></span>
      <span
        *ngIf="previewDropIndex === tags.length"
        class="drop-preview tag">
        {{ draggingTag }}
      </span>
    </ng-container>
    <input
      #searchInput
      class="suggest"
      [value]="search"
      (keydown.enter)="onKeydown()"
      (keyup)="onSearchChange(searchInput.value)"
      (focus)="onFocus()" />
    <div
      #dropdownPanel
      class="dropdown-panel"
      [ngClass]="{ open: isFocused }">
      <span
        *ngIf="search.trim()"
        (click)="onSuggestion(search)"
        class="dropdown-entry">
        <span class="add-entry"> {{ 'shared.add' | translate }}{{ ': ' }} </span>
        {{ search }}
      </span>
      <span
        (click)="onSuggestion(suggestion)"
        class="dropdown-entry"
        *ngFor="let suggestion of filteredSuggestions">
        {{ suggestion }}
      </span>
      <span
        class="dropdown-empty"
        *ngIf="filteredSuggestions.length <= 0 && !search">
        {{ 'shared.empty' | translate }}
      </span>
    </div>
  </div>
  <input
    formControlName="inputValue"
    class="multi-select-wrapper-input"
    type="hidden" />
</form>
