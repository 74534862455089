<section id="login-page">
  <div class="container">
    <div class="row">
      <div class="col-md-5 col-xl-5 col-xxl-4"></div>
      <div class="col-md-7 col-xl-7 col-xxl-8 fill-window">
        <div
          id="login-circle"
          class="justify-content-center align-items-center">
          <div
            class="inner"
            style="opacity: 1; transform: translate(0px)">
            <h2 class="block-title">
              <span class="subtitle">{{ 'login.subtitle' | translate }}</span>
              <strong>{{ 'login.title' | translate }}</strong>
            </h2>
            <app-button
              *ngIf="!isAuthorized"
              (clicked)="login()"
              label="{{ 'login.loginButton' | translate }}"></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
