import {
  ApplicationRef,
  ViewContainerRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Input
} from '@angular/core';
import { TooltipComponent } from '../components/tooltip/tooltip/tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @Input() public tooltip = '';
  @Input() public top: string;
  @Input() public left: string;
  @Input() public width: string;
  private componentRef: ComponentRef<any> | null = null;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly appRef: ApplicationRef,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (this.componentRef === null) {
      this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      this.setTooltipComponentProperties();
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.destroy();
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  public destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  private setTooltipComponentProperties(): void {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      const { left, right, bottom, width } = this.elementRef.nativeElement.getBoundingClientRect();
      this.componentRef.instance.left = (right - left) / 2 + left;
      this.componentRef.instance.top = bottom;
      this.componentRef.instance.width = width;
      if (this.top) {
        this.componentRef.instance.topCus = this.top;
      }
      if (this.left) {
        this.componentRef.instance.leftCus = this.left;
      }
      if (this.width) {
        this.componentRef.instance.widthCus = this.width;
      }
    }
  }
}
