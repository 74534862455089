import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseValueAccessor } from '../../../utils/base-value-accessor';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextInputComponent,
      multi: true
    }
  ]
})
export class TextInputComponent extends BaseValueAccessor {
  private static instanceCount: number = 0;

  @Input() label: string;
  @Input() public placeholder?: string = '';

  public textInputForm: FormGroup;
  public focused: boolean = false;
  public id: string;

  private readonly instanceId: number;
  private readonly valueChangesSubscription: Subscription | undefined = undefined;

  public constructor(private readonly fb: FormBuilder, private readonly elementRef: ElementRef) {
    super();
    this.instanceId = TextInputComponent.instanceCount++;
    this.textInputForm = this.fb.group({
      inputValue: ['']
    });
    this.valueChangesSubscription = this.textInputForm
      .get('inputValue')
      ?.valueChanges.subscribe((value: string): void => {
        this.onChange(value);
      });
  }

  public ngOnInit(): void {
    this.id = `${this.label}-${this.instanceId}`;
  }

  override ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  @HostListener('document:keyup.escape', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      const textareaElement = this.elementRef.nativeElement.querySelector('.input input');
      if (textareaElement) {
        textareaElement.blur();
      }
    }
  }

  public override writeValue(value: string | null): void {
    if (value !== this.textInputForm.get('inputValue')?.value) {
      this.textInputForm.get('inputValue')?.setValue(value);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
  }
}
