import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Colleague } from '../models/colleague';
import { ColleagueDto } from '../models/coalleague-dto';

@Injectable({
  providedIn: 'root'
})
export class ColleaguesService {
  private readonly apiUrl: string = environment.apiUrl;

  public constructor(private readonly httpClient: HttpClient) {}

  public getAllColleagues(): Observable<Colleague[]> {
    return this.httpClient.get<Colleague[]>(`${this.apiUrl}/colleagues`);
  }

  public getAllCoachees(): Observable<ColleagueDto[]> {
    return this.httpClient.get<ColleagueDto[]>(`${this.apiUrl}/colleagues/coachee`);
  }

  public getAllColleaguesInSameUnitOrPractice(): Observable<Colleague[]> {
    return this.httpClient.get<Colleague[]>(`${this.apiUrl}/colleagues/punit`);
  }

  public getAllColleaguesWithSameClient(): Observable<Colleague[]> {
    return this.httpClient.get<Colleague[]>(`${this.apiUrl}/colleagues/client`);
  }
}
