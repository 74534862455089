import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

import { ResumesService } from '../../services/resumes.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { MAX_FILE_SIZE } from '../../shared/utils/constants';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  @Output() uploadComplete = new EventEmitter();
  @Input() public isResumeListLoading: boolean = false;

  public files: NgxFileDropEntry[] = [];
  public loading: boolean = false;

  private readonly hasHoverEffectSub = new BehaviorSubject<boolean>(false);
  public hasHoverEffect$ = this.hasHoverEffectSub.asObservable().pipe(debounceTime(10), distinctUntilChanged());

  private readonly hasDragEffectSub = new BehaviorSubject<boolean>(false);
  private isDragInBody = false;

  public hasDragEffect$ = this.hasDragEffectSub.asObservable().pipe(debounceTime(10), distinctUntilChanged());

  public constructor(
    private readonly resumeService: ResumesService,
    private readonly translate: TranslateService,
    private readonly toastService: ToastService
  ) {}

  // Dragover listener
  @HostListener('window:dragenter')
  public onDragEnter(): void {
    if (!this.isDragInBody) {
      this.hasDragEffectSub.next(true);
      this.isDragInBody = true;
    }
  }

  public deleteHoverEffect(): void {
    this.hasDragEffectSub.next(false);
    this.hasHoverEffectSub.next(false);
  }

  public addHoverEffect(): void {
    this.hasDragEffectSub.next(true);
    this.hasHoverEffectSub.next(true);
  }

  public dropped(fileDropEntries: NgxFileDropEntry[]): void {
    if (!this.isNumberOfFilesAllowed(fileDropEntries)) {
      return this.toastService.showError(this.translate.instant('resume.errors.numberOfFiles'));
    }

    const fileDropEntry: NgxFileDropEntry = fileDropEntries[0];
    const fileEntry: FileSystemFileEntry = fileDropEntry.fileEntry as FileSystemFileEntry;

    if (!fileEntry.isFile) {
      return this.toastService.showError(this.translate.instant('resume.errors.notAFile'));
    }

    if (!this.resumeService.isFileNameAllowed(fileEntry.name)) {
      return this.toastService.showError(this.translate.instant('resume.errors.fileName'));
    }

    if (!this.resumeService.isFileTypeAllowed(fileEntry.name)) {
      return this.toastService.showError(this.translate.instant('resume.errors.fileType'));
    }

    fileEntry.file((file: File): void => {
      if (!this.resumeService.isFileSizeAllowed(file.size)) {
        return this.toastService.showError(
          this.translate.instant('resume.errors.fileSize', {
            // Size in KB
            maxSize: Math.round(MAX_FILE_SIZE * 125)
          })
        );
      }
      this.files.push(fileDropEntry);
      this.pushFileToServer(file);
    });
  }

  private isNumberOfFilesAllowed(files: NgxFileDropEntry[]): boolean {
    if (files.length > 1) return false;
    return true;
  }

  private pushFileToServer(file: File) {
    this.loading = true;
    this.resumeService.uploadResume(file).subscribe({
      next: (data: HttpEvent<any>) => {
        // Below code is not used currently. But can be used in future.
        // If (data.type === HttpEventType.UploadProgress) {}
        if (data.type === HttpEventType.Response) {
          this.toastService.showSuccess(this.translate.instant('resume.upload.success'));
          this.loading = false;
          this.uploadComplete.emit();
        }
      },
      error: (e: any) => {
        this.loading = false;
        this.toastService.showError(this.translate.instant('resume.upload.fail'));
      }
    });
  }
}
