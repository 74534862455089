<form
  [formGroup]="yearPickerForm"
  class="input-wrapper"
  #parentElement>
  <label
    [for]="label"
    [ngClass]="focused ? 'active' : ''"
    >{{ label }}</label
  >

  <input
    bsDatepicker
    #dp="bsDatepicker"
    [bsConfig]="{ dateInputFormat: 'YYYY', minMode: 'year' }"
    formControlName="inputValue"
    [placeholder]="placeholder"
    id="{{ id }}"
    type="year-date"
    name="{{ label }}"
    autocomplete="off"
    (focus)="onFocus()"
    (blur)="onBlur()" />
</form>
