import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { TokenRequest } from '../models/token-request';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly TOKEN_KEY = 'access_token';

  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly httpClient: HttpClient) {}

  public getTokenAfterLogin(googleToken: string): Observable<TokenRequest> {
    const body: TokenRequest = { access_token: googleToken };
    return this.httpClient.post<TokenRequest>(`${this.apiUrl}/auth/login`, body).pipe(
      map((tokenRequest) => {
        const token = tokenRequest.access_token;
        if (token) {
          this.setToken(token);
        }
        return tokenRequest;
      })
    );
  }

  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  token(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  cleanStorageAfterLogOut(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }
}
