import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface QuizStatsInfo {
  id: number;
  userEmail: string;
  quizId: string;
  totalQuestionsAnswered: number;
  correctAnswersCount: number;
  successPercentage: number;
}

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  private readonly apiUrl: string = environment.apiUrl;

  public constructor(private readonly httpClient: HttpClient) {}

  public updateQuizStats(payload: any): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/quiz-stats/update`, payload);
  }

  // Method to fetch all quiz stats for admin
  public getAllQuizStats(): Observable<QuizStatsInfo[]> {
    return this.httpClient.get<QuizStatsInfo[]>(`${this.apiUrl}/quiz-stats/all`);
  }
}
