<div class="path-container">
  <div
    class="filter-section"
    [@fadeInOut]>
    <select
      class="domain-filter"
      [(ngModel)]="selectedDomain"
      (ngModelChange)="filterPaths()">
      <option
        *ngFor="let domain of domains"
        [value]="domain">
        {{ domain }}
      </option>
    </select>
  </div>

  <div
    *ngIf="loading"
    class="loading-state">
    Loading certification paths...
  </div>

  <div
    *ngIf="error"
    class="error-state">
    {{ error }}
  </div>

  <div
    *ngFor="let filteredPath of filteredPaths"
    class="domain-section"
    [@fadeInOut]>
    <div class="domain-header">
      <h2>{{ filteredPath.domain }}</h2>
    </div>

    <div
      *ngFor="let speciality of filteredPath.specialities"
      class="role-path">
      <div class="role-header">
        <h3>{{ speciality.name }}</h3>
      </div>

      <div class="certification-path">
        <ng-container *ngFor="let cert of speciality.certifications; let i = index">
          <div class="certification-item">
            <a
              [href]="cert.classroomLink"
              target="_blank">
              <div class="card-content">
                <div
                  class="cert-badge"
                  [class.active]="cert.flag">
                  <img
                    [src]="cert.logo"
                    [alt]="cert.name"
                    class="cert-logo" />
                  <div class="cert-info">
                    <h4>{{ cert.name }}</h4>
                  </div>
                </div>
              </div>
            </a>
            <div class="card-buttons">
              <button
                class="classroom-button"
                (click)="goToClassroom($event, cert)">
                Go to classroom
              </button>
              <button
                class="quiz-button"
                (click)="goToQuiz($event, cert)">
                Go to quiz
              </button>
            </div>
          </div>
          <div
            class="connector"
            *ngIf="i !== speciality.certifications.length - 1"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
