import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OfflineService } from '../pages/app-offline/offline.service';

@Injectable({
  providedIn: 'root'
})
export class TimeCheckGuard implements CanMatch {
  constructor(private readonly router: Router, private readonly offlineService: OfflineService) {}

  canMatch(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.offlineService.isAppOffline() ? this.router.navigateByUrl('offline') : true;
  }
}
