<div class="container">
  <div>
    <img
      class="degree-icon"
      src="/assets/img/cv-generator/diploma.png"
      alt="diploma" />
  </div>
  <div class="education">
    <p class="name">{{ education.degree || '' }}</p>
    <p *ngIf="education.school || education.end">{{ education.school || '' }} &#8211; {{ education.end || '' }}</p>
  </div>
</div>
