import { Component } from '@angular/core';

@Component({
  selector: 'app-support-email-button',
  templateUrl: './support-email.component.html',
  styleUrls: ['./support-email.component.scss']
})
export class SupportEmailComponent {
  // Predefined email details
  recipient: string = 'be.my@devoteam.com';

  sendEmail(): void {
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${  encodeURIComponent(this.recipient)}`;
    window.open(mailtoLink, '_blank');
  }
}
