export function scrollIntoViewWithOffset(
  selector: string,
  overflowingParent: HTMLElement | null | undefined,
  offset: number
) {
  const element = document.querySelector(selector) as HTMLElement;
  if (element) {
    if (overflowingParent && overflowingParent.parentElement) {
      overflowingParent.parentElement.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - overflowingParent.offsetTop - offset
      });
    }
  }
}
