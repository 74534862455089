import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() public timeLimit: number | undefined;
  public time: number = 0;
  private timerInterval: any;
  public isTimeLimitReached: boolean = false;
  public isPaused: boolean = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.startTimer();
  }

  ngOnDestroy() {
    this.clearInterval();
  }

  private clearInterval() {
    if (this.timerInterval) clearInterval(this.timerInterval);
  }

  startTimer() {
    if(this.timeLimit) {
      this.timeLimit = this.timeLimit * 60;
    }
    this.clearInterval();
    this.isPaused = false;
    this.timerInterval = setInterval(() => {
      if (!this.isPaused) {
        this.time++;
        if (this.timeLimit && this.time >= this.timeLimit) {
          this.isTimeLimitReached = true;
        }
        this.cdr.detectChanges(); // Ensure UI updates immediately
      }
    }, 1000);
  }

  pauseTimer() {
    this.isPaused = !this.isPaused;
  }

  resetTimer() {
    this.clearInterval();
    this.time = 0;
    this.isTimeLimitReached = false;
    this.startTimer();
  }

  // Format time as hh:mm:ss
  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;

    return `${this.padTime(hours)}:${this.padTime(minutes)}:${this.padTime(sec)}`;
  }

  // Helper function to pad the time with zeroes
  padTime(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }
}
