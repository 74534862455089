import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  template: `<span
    class="fa-{{ type }} fa-{{ icon }} font-size-{{ size }} text-color-{{ color }} {{ classes }}"
    style="color:{{ color }};"></span>`,
  styleUrls: ['./icons.component.scss']
})
export class IconComponent {
  @Input()
  public type: 'solid' | 'regular' = 'solid';

  @Input()
  public color!: string;

  @Input()
  public size!: string;

  @Input()
  public icon!: string;

  @Input()
  public classes!: string;
}
