export const jobSuggestions = [
  'IT Operating Model Consultant',
  'Functional Solution Consultant',
  'Organization & Process Consultant',
  'Most valuable Project Management & Change ITSE',
  'Project Management & Change ITSE',
  'Artificial Intelligence Engineer',
  'Automation Engineer',
  'Engineer Technical Solution / Hot technologies',
  'Engineer Technical Solution / Standard technologies',
  'Most valuable Artificial Intelligence Engineer',
  'Most valuable Solution & CMDB Architect / Hot technologies',
  'Most valuable Technical Solution / Hot technologies Engineer',
  'Portal Solution Architect',
  'Solution & CMDB Architect / Hot technologies',
  'Solution & CMDB Architect / Standard technologies',
  'Cloud & Devops Architect',
  'Infra Architect',
  'Most Valuable Cloud & Devops Architect',
  'Cloud/Devops Automation Engineering',
  'Engineering & Implementation Cloud',
  'Engineering & Implementation DevOps Platform',
  'Infrastructure Engineer',
  'Most valuable Engineering & Implementation Cloud & Devops',
  'Infra Project Manager',
  'Most valuable Project Management, Change and Business Analysis',
  'Project Management, Change and Business Analysis',
  'Developer',
  'Functional Analysis and Technical Design',
  'Most Valuable Developer',
  'System & Network Administrator',
  'Change & Release',
  'General Business Analyst',
  'Most Valuable Specialized Business Analyst',
  'PMO / PM',
  'Program Director',
  'Business Automation (AI & RPA)',
  'Functional Tester',
  'Network Architect',
  'Network engineer L2',
  'Network engineer L3',
  'Network Project Manager',
  'NGN/WIFI engineer',
  'SDN/SD WAN Engineer',
  'Digital Marketing Consultant',
  'UX Lead',
  'UX/UI Analyst / Researcher',
  'UX/UI Designer',
  'Consulting Director',
  'Creative Director',
  'Lead Product Owner',
  'Product Manager',
  'Product Owner',
  'Business & Resilience Consultant',
  'Most Valuable Risk & Compliance Manager',
  'Risk & Compliance Analyst',
  'Offensive Cybersecurity Specialist',
  'Security Solutions Architect',
  'Cyber security Project manager',
  'Most Valuable Secure DevOps & Cloud Manager',
  'Security Engineering & Solutions',
  'Security Operational Analyst',
  'Data Scientist',
  'Most valuable Data Scientist',
  'Big data / Platform Architect',
  'Big data / Platform Engineer',
  'Big data Administrator',
  'Big data Project Manager',
  'Most valuable Solutions Architect',
  'Data governance Architect',
  'Data governance consultant',
  'Most valuable data governance Architect',
  'Data Analyst',
  'Modern BI Architect',
  'Most valuable modern BI Architect',
  'DWP & Cloud Architect',
  'Most Valuable Architect DWP & Cloud',
  'DWP L2 Technician',
  'Modern Desktop Administrator',
  'Collaboratives Solutions Project Manager',
  'Most Valuable DWP Project Manager',
  'Project Management Infra / Modern Desktop / Mobility',
  'Collaborative Solutions Consultant',
  'Infra Consultant DWP',
  'Infra/Modern Desktop / Mobility Consultant',
  'Most Valuable Collaborative Solutions Consultant',
  'Most Valuable Infra / DWP Consultant',
  'Enterprise Digital Culture & Change Manager',
  'Most Valuable Enterprise Digital Culture & Change Manager',
  'Digital Transformation Project Manager',
  'Enterprise Project Manager',
  'Most Valuable Digital Transformation Project Manager',
  'BI Developer',
  'BI administrator',
  'SharePoint Developer',
  'SharePoint Infrastructure Engineer',
  'SharePoint Administrator',
  'Business Consultant Information Management',
  '.NET Software Developer',
  'Java/J2EE Software Developer',
  'Software Architect',
  'Middleware/SOA Engineer',
  'Mobile App Developer',
  'Web Developer',
  'Web Designer',
  'Media Engineer',
  'Media Integration Engineer',
  'Solution Integration Engineer',
  'Solution Architect',
  'Functional Analyst',
  'Business Analyst',
  'Information System Organisation & Management',
  'System Administrator',
  'System Engineer Microsoft Infrastructure',
  'System Engineer Unix Infrastructure',
  'Network Engineer',
  'Software Engineer',
  'Enterprise Architect IT Infrastructure',
  'Enterprise Architect Business Applications',
  'ITSM Consultant',
  'IT Governance Consultant',
  'Security Governance Consultant',
  'Test Engineer',
  'Test Manager',
  'Test Analyst',
  'Telecom Engineer',
  'Service Delivery Manager',
  'Technical Coordinator',
  'Project Coordinator',
  'Project Manager',
  'Program Manager',
  'Practice Manager'
];
