import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { CertificationPathService } from '../../../services/certification-path.service';
import { TrainingPath } from '../../../models/certification-path/training-path';
@Component({
  selector: 'app-path-view',
  templateUrl: './path-view.component.html',
  styleUrls: ['./path-view.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class PathViewComponent implements OnInit {
  trainingPaths: TrainingPath[] = [];
  filteredPaths: TrainingPath[] = [];
  domains: string[] = [];
  selectedDomain: string = 'All';
  specialties: string[] = [];
  selectedSpecialty: string = 'All';
  loading = true;
  error: string | null = null;

  constructor(private readonly certificationPathService: CertificationPathService, private readonly router: Router) {}

  ngOnInit(): void {
    this.loadTrainingPaths();
  }

  goToClassroom(event: Event, cert: any) {
    event.stopPropagation();
    window.open(cert.classroomLink, '_blank');
  }

  goToQuiz(event: Event, cert: any) {
    event.stopPropagation(); // Prevent bubbling
    this.router.navigate([cert.quizLink]);
  }

  private loadTrainingPaths(): void {
    this.certificationPathService.getTrainingPaths().subscribe({
      next: (data) => {
        console.log('Received training paths:', data);
        this.trainingPaths = data;
        const shortestPath = this.findShortestPath(this.trainingPaths);
        if (shortestPath) {
          this.filteredPaths = [shortestPath];
          this.selectedDomain = shortestPath.domain;
        }
        this.loading = false;
        this.extractDomainsAndSpecialties();
      },
      error: (err) => {
        this.error = 'Failed to load certification paths';
        this.loading = false;
        console.error('Error loading training paths:', err);
      }
    });
  }

  private findShortestPath(paths: TrainingPath[]): TrainingPath | undefined {
    return paths.reduce((shortest, current) => {
      if (!shortest || current.specialities.length < shortest.specialities.length) {
        return current;
      }
      return shortest;
    }, undefined as TrainingPath | undefined);
  }

  private extractDomainsAndSpecialties() {
    this.domains = ['All', ...new Set(this.trainingPaths.map((path) => path.domain))];
    this.specialties = [
      'All',
      ...new Set(this.trainingPaths.flatMap((path) => path.specialities.map((speciality) => speciality.name)))
    ];
  }

  filterPaths() {
    this.filteredPaths = this.trainingPaths
      .filter((path) => {
        const domainMatch = this.selectedDomain === 'All' || path.domain === this.selectedDomain;
        return domainMatch;
      })
      .map((path) => ({
        ...path,
        specialities: path.specialities.filter(
          (speciality) => this.selectedSpecialty === 'All' || speciality.name === this.selectedSpecialty
        )
      }));
  }
}
