<div class="card">
  <p
    class="title"
    [innerHtml]="'resume.create.title' | translate"></p>
  <div class="description">
    <p [innerHtml]="'resume.create.description' | translate"></p>
  </div>

  <div class="links">
    <div class="link">
      <img
        src="../../../assets/img/resumes/CV_template.png"
        alt="" />
      <a
        target="_blank"
        href="{{ cvGeneratorUrl }}"
        class="item"
        >{{ 'resume.create.cvGenerator' | translate }}</a
      >
    </div>
    <div class="link">
      <img
        src="../../../assets/img/resumes/one_page_CV.png"
        alt="" />
      <a
        target="_blank"
        href="{{ onePageCvUrl }}"
        class="item"
        >{{ 'resume.create.onePageTemplate' | translate }}</a
      >
    </div>
    <div class="link">
      <img
        src="../../../assets/img/resumes/CV_template.png"
        alt="" />
      <a
        target="_blank"
        href="{{ wordTemplateUrl }}"
        class="item"
        >{{ 'resume.create.wordTemplateDocs' | translate }}</a
      >
    </div>
  </div>
</div>
