import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AlertType } from '../alert/alert-type';
import { Toast } from './toast';

@Injectable()
export class ToastService {
  private readonly toastSub = new Subject<Toast>();
  toast$ = this.toastSub.asObservable();
  private readonly DELAY = 10000;

  constructor(private readonly translateService: TranslateService) {}

  show(title: string, type: AlertType = AlertType.secondary, delay: number = this.DELAY): void {
    this.toastSub.next({
      title,
      delay,
      type,
      id: Date.now() + Math.random()
    });
  }

  showError(title: string, delay: number = this.DELAY) {
    this.show(title, AlertType.danger, delay);
  }

  showSuccess(title: string, delay: number = this.DELAY) {
    this.show(title, AlertType.success, delay);
  }

  // This is used to set a default fallback in case there is no translation found for the key
  setTranslation(translationKey: string): string {
    const translated = this.translateService.instant(translationKey);
    if (translated !== translationKey) return translated;
    return this.translateService.instant('app.errors.default');
  }
}
