<div class="certifications-container">
  <h1 class="big-title">Plan your certification journey</h1>
  <h2 class="small-title">Which certification should I start with?</h2>
  <div class="recommendation-sections">
    <div class="recommendation-column">
      <h2>Non-IT Background / Cloud Career Switch</h2>
      <p>
        Start with:
        <strong
          >A foundational cloud certification (e.g., AWS Certified Cloud Practitioner, Microsoft Certified: Azure
          Fundamentals, Google Cloud Digital Leader)</strong
        >
        to build core cloud knowledge, then earn
        <strong
          >AI-related certification (e.g., AWS Certified AI Practitioner, Microsoft Certified: Azure AI Fundamentals,
          Google Cloud Machine Learning Engineer)</strong
        >
        to showcase AI expertise. Consider a Salesforce certification (e.g., Salesforce Administrator) to gain CRM and
        cloud-based business solutions knowledge.
      </p>
      <p>
        <span>Outcome:</span> Enhances your competitive edge, positioning you for in-demand cloud, AI, and CRM roles.
        Sets the stage for career growth and additional advanced certifications.
      </p>
    </div>

    <div class="recommendation-column">
      <h2>Sales/Marketing & Business Roles</h2>
      <p>
        Start with: <strong> A foundational cloud certification</strong> to validate your cloud knowledge (e.g., AWS
        Certified Cloud Practitioner, Azure Fundamentals, Google Cloud Digital Leader), then earn
        <strong
          >pursue an AI or CRM certification (e.g., Salesforce AI Associate, AWS Certified AI Practitioner, Microsoft
          AI-900)</strong
        >
        to enhance your expertise in AI-powered business solutions.
      </p>
      <p>
        <span>Outcome:</span> Strengthens your marketability with in-demand cloud and AI knowledge, opening doors for
        career advancement and higher pay.
      </p>
    </div>

    <div class="recommendation-column">
      <h2>IT Professionals</h2>
      <p>
        Start with: An
        <strong
          >associate or role-specific cloud certification (e.g., AWS Certified Solutions Architect – Associate, Azure
          Administrator Associate, Google Cloud Associate Engineer)
        </strong>
        that aligns with your role, to deepen your technical skills. Then obtain an
        <strong>AWS Certified AI Practitioner</strong> is recommended to validate conceptual AI knowledge.
      </p>
      <p>
        <span>Outcome:</span> Boosts your confidence and credibility in AI, cloud, and machine learning projects.
        Positions you for career advancement, higher pay, and further specialization in cloud technologies.
      </p>
    </div>
  </div>
  <h1 class="big-title mt-10">Prepare for your certification journey</h1>
  <h2 class="small-title">Which certification quiz should I start with?</h2>
  <div class="certification-grid">
    <div
      *ngFor="let certification of certifications"
      class="certification-card"
      (click)="navigateToCertification(certification.id)">
      <img
        [src]="certification.logo"
        [alt]="certification.name + ' Logo'" />
      <p>{{ certification.name }}</p>
    </div>
  </div>
  <h1 class="big-title mt-10">Choose your certification journey</h1>
  <h2 class="small-title">
    Which certification path should I follow? Choose one of these classroom to start your learning journey
  </h2>
  <div class="training-paths-section">
    <app-path-view></app-path-view>
  </div>
</div>
