import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Cv } from '../models/cv';
import { environment } from '../../environments/environment';
import { ComponentStatus } from '../models/component-status';
import { ToastService } from '../shared/components/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class CvGeneratorService implements OnDestroy {
  private readonly apiUrl = environment.apiUrl;
  private subscription: Subscription;

  private readonly cvDataSubject: BehaviorSubject<Cv | null> = new BehaviorSubject(null);
  public cvData$ = this.cvDataSubject.asObservable();

  private readonly remoteCvDataSubject: BehaviorSubject<Cv | null> = new BehaviorSubject(null);
  public remoteCvData$ = this.remoteCvDataSubject.asObservable();

  public status: ComponentStatus;

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly translate: TranslateService,
    private readonly toastService: ToastService
  ) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public updateCvDataLocal(data: Cv): void {
    this.cvDataSubject.next(data);
  }

  public loadCvData(userId?: number): Observable<Cv> {
    const url: string = `${this.apiUrl}/cv-generator/draft`;
    const params = userId ? { userId } : undefined;
    return this.httpClient.get<{ draft: Cv }>(url, { params }).pipe(map((data) => data.draft));
  }

  public updateCvDataOnServer(cvData: Cv, userId?: number): void {
    const url: string = `${this.apiUrl}/cv-generator/draft`;
    const params = userId ? { userId } : undefined;
    this.httpClient.post<Cv>(url, cvData, { params }).subscribe();
  }

  public getGooglePhoto(userId?: number): Observable<any> {
    const url: string = `${this.apiUrl}/cv-generator/google-photo`;
    const params = userId ? { userId } : undefined;
    return this.httpClient.get(url, { params });
  }

  public async downloadCv(userId?: number): Promise<void> {
    const url: string = `${this.apiUrl}/cv-generator/download-cv`;
    const params = userId ? { userId } : undefined;
    return new Promise((resolve, reject) => {
      this.subscription = this.httpClient
        .get(url, { responseType: 'arraybuffer', observe: 'response', params })
        .subscribe({
          next: (res) => {
            this.openFileInBrowser(res, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            this.toastService.showSuccess(this.translate.instant('resume.download.success'));
            resolve();
          },
          error: (err) => {
            this.toastService.showError(this.translate.instant('resume.download.error'));
            reject(err);
          }
        });
    });
  }

  public async downloadCvAsPdf(userId?: number): Promise<void> {
    const url: string = `${this.apiUrl}/cv-generator/download-cv-pdf`;
    const params = userId ? { userId } : undefined;
    return new Promise((resolve, reject) => {
      this.subscription = this.httpClient
        .get(url, { responseType: 'arraybuffer', observe: 'response', params })
        .subscribe({
          next: (res) => {
            this.openFileInBrowser(res, 'application/pdf');
            this.toastService.showSuccess(this.translate.instant('resume.download.success'));
            resolve();
          },
          error: (err) => {
            this.toastService.showError(this.translate.instant('resume.download.error'));
            reject(err);
          }
        });
    });
  }

  public async publishCv(userId?: number): Promise<void> {
    const url: string = `${this.apiUrl}/cv-generator/publish-cv`;
    const params = userId ? { userId } : undefined;
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, { params }).subscribe({
        next: () => {
          this.toastService.showSuccess(this.translate.instant('resume.publish.success'));
          resolve();
        },
        error: (err) => {
          this.toastService.showError(this.translate.instant('resume.publish.fail'));
          reject(err);
        }
      });
    });
  }

  private openFileInBrowser(res: HttpResponse<ArrayBuffer>, type: string): void {
    if (res.body) {
      const contentDispositionHeader: string | null = res.headers.get('Content-Disposition');
      const filename: string = contentDispositionHeader?.split('attachment; ')[1].split('=')[1].replace(/"/g, '') || '';
      const blob: Blob = new Blob([res.body], {
        type
      });
      const url: string = window.URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
