import {
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { CvPageIndexService } from '../../../../services/cv-page-index.service';
import { CvGeneratorService } from '../../../../services/cv-generator.service';
import { Cv } from '../../../../models/cv';
import { SubscriptionBaseDirective } from './../../../../shared/directives/subscription-base.directive';
import { MainLogoComponent } from './../../../../shared/components/logos/main-logo.component.ts/main-logo.component';
@Component({
  selector: 'app-paginated-view',
  templateUrl: 'paginated-view.component.html',
  styleUrls: ['paginated-view.component.scss']
})
export class PaginatedViewComponent extends SubscriptionBaseDirective implements OnInit {
  @Input() public pageSize: string = 'A4';
  @ViewChild('paginatedView') public paginatedView: ElementRef<HTMLDivElement>;
  @ViewChildren('elements', { read: ElementRef }) public elementsData: QueryList<ElementRef<HTMLElement>>;
  public data: Cv | null = {};
  private pageCounter: number;
  private readonly PAGE_TOTAL_PADDING: number = 130;

  public constructor(
    public vrc: ViewContainerRef,
    private readonly cvPageIndexService: CvPageIndexService,
    private readonly cvService: CvGeneratorService
  ) {
    super();
  }
  public ngOnInit(): void {
    this.addSubscription(
      this.cvService.cvData$.subscribe((data) => {
        this.data = data;
        this.trimObjectFields(this.data);
        setTimeout(() => {
          this.updatePages();
          this.cvPageIndexService.currentPageIndexObs.subscribe((index) => {
            this.showPage(index);
          });
        }, 0);
      })
    );
    this.addSubscription(
      this.cvPageIndexService.currentPageIndexObs.subscribe((index: number) => {
        this.showPage(index);
      })
    );
  }

  public checkData(data: any): boolean {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        if (data[key].some((item) => this.checkData(item))) {
          return true;
        }
      } else if (typeof data[key] === 'object') {
        if (this.checkData(data[key])) {
          return true;
        }
      } else {
        if (data[key]) {
          return true;
        }
      }
    }
    return false;
  }

  public trimObjectFields(obj: any): void {
    for (const prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].trim();
      } else if (typeof obj[prop] === 'object') {
        this.trimObjectFields(obj[prop]);
      }
    }
  }

  private updatePages(): void {
    this.pageCounter = 0;
    this.paginatedView.nativeElement.innerText = '';
    this.fillContent(this.elementsData);
    this.cvPageIndexService.updateTotalPages(this.pageCounter);
  }

  // Fills in the content wrapper with html elements based on `data: QueryList<ElementRef>`
  // If the page is filled in completely, it will create a new page
  private fillContent(data: QueryList<ElementRef<HTMLElement>> | HTMLElement[]): void {
    const currentList: HTMLElement[] = [];
    if (data.length === 0) {
      return;
    }
    if (data instanceof QueryList<ElementRef<HTMLElement>>) {
      for (const elRef of this.elementsData) {
        const el: HTMLElement = elRef.nativeElement;
        currentList.push(el);
      }
    }
    if (data instanceof Array<HTMLElement>) {
      for (const el of data) {
        currentList.push(el);
      }
    }

    const page: HTMLDivElement = this.getNewPage();
    this.paginatedView.nativeElement.appendChild(page);
    const contentWrapper: HTMLDivElement = this.createDivElement('cv-content-wrapper');
    const rightContainer: HTMLDivElement = this.createDivElement('right-container');
    const leftContainer: HTMLDivElement = this.createDivElement('left-container');
    const experienceContainer: HTMLDivElement = this.createDivElement('experience-container');
    const newList: HTMLElement[] = [];

    for (const el of currentList) {
      if (el.classList.contains('left')) {
        // Adding extra space for the padding
        if (contentWrapper.offsetHeight + this.PAGE_TOTAL_PADDING <= page.clientHeight) {
          leftContainer.appendChild(el);
          if (!contentWrapper.contains(leftContainer)) {
            contentWrapper.append(leftContainer);
          }
        } else {
          newList.push(el);
        }
      } else if (el.classList.contains('right')) {
        if (contentWrapper.clientHeight <= page.clientHeight) {
          rightContainer.appendChild(el);
          if (!contentWrapper.contains(leftContainer)) {
            contentWrapper.append(leftContainer);
          }
          if (!contentWrapper.contains(rightContainer)) {
            contentWrapper.append(rightContainer);
          }
        } else {
          newList.push(el);
        }
      } else if (el.classList.contains('experience')) {
        if (contentWrapper.clientHeight === 0 && experienceContainer.offsetHeight <= page.offsetHeight) {
          page.appendChild(experienceContainer);
          experienceContainer.appendChild(el);
          this.paginatedView.nativeElement.appendChild(page);
        } else {
          newList.push(el);
        }
      }
      if (!el.classList.contains('experience')) {
        if (!page.contains(contentWrapper)) {
          page.appendChild(contentWrapper);
        }
      }
    }
    this.fillContent(newList);
  }

  private getNewPage(): HTMLDivElement {
    const page: HTMLDivElement = this.createDivElement('cv-page');
    page.classList.add(this.pageSize);

    const leftBubble: HTMLDivElement = document.createElement('div');
    const rightBubble: HTMLDivElement = document.createElement('div');
    if (this.pageCounter % 2 === 0) {
      leftBubble.classList.add('odd-leftBubble');
      rightBubble.classList.add('odd-rightBubble');
    } else {
      leftBubble.classList.add('even-leftBubble');
      rightBubble.classList.add('even-rightBubble');
    }
    const logo: HTMLElement = this.createLogoElement();
    page.appendChild(logo);
    page.appendChild(leftBubble);
    page.appendChild(rightBubble);
    this.pageCounter++;
    return page;
  }

  private createDivElement(name: string): HTMLDivElement {
    const element: HTMLDivElement = document.createElement('div');
    element.classList.add(name);
    return element;
  }

  private createLogoElement(): HTMLElement {
    const logo: ComponentRef<MainLogoComponent> = this.vrc.createComponent(MainLogoComponent);
    const logoElement: HTMLElement = logo.location.nativeElement;
    logoElement.classList.add('cv-logo');
    return logoElement;
  }

  private showPage(pageNumber: number): void {
    const pageList: HTMLCollectionOf<Element> = document.getElementsByClassName('cv-page');
    pageNumber--;
    for (let i: number = 0; i < pageList.length; i++) {
      if (pageNumber === i) {
        pageList[i].classList.add('show');
        pageList[i].classList.remove('hide');
      } else {
        pageList[i].classList.add('hide');
        pageList[i].classList.remove('show');
      }
    }
  }
}
