import { Component } from '@angular/core';

@Component({
  selector: 'app-component-logo-main-light',
  template: ' <img src="../../../../../assets/img/logo/devoteam_white_cmyk.png" class="light-logo" role="img" /> ',
  styles: [
    `
      .light-logo {
        display: block;
        width: 100%;
      }
    `
  ]
})
export class MainLogoLightComponent {}
