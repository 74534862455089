<footer
  id="colophon"
  class="site-footer">
  <div
    id="socket"
    class="pb-2 bg-white">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4 col-xl-3 order-lg-last mb-4 mb-lg-0"></div>

        <div class="col-12 col-lg-8 col-xl-9">
          <div class="d-md-flex justify-content-between align-items-center">
            <div
              id="menu-socket"
              class="menu-socket-menu-container">
              <ul
                id="socket-menu"
                class="nav align-items-center">
                <li
                  id="menu-item-9222"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9222 nav-item">
                  <a
                    href="https://belgium.devoteam.com/legal-notice/"
                    class="nav-link">
                    {{ 'footer.socket.legalNotice' | translate }}
                  </a>
                </li>
                <li
                  id="menu-item-9223"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9223 nav-item">
                  <a
                    href="https://belgium.devoteam.com/terms-and-conditions/"
                    class="nav-link">
                    {{ 'footer.socket.termsConditions' | translate }}
                  </a>
                </li>
                <li
                  id="menu-item-9224"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9224 nav-item">
                  <a
                    href="https://belgium.devoteam.com/personal-data/"
                    class="nav-link">
                    {{ 'footer.socket.personalData' | translate }}
                  </a>
                </li>
                <li class="menu-item copyright">
                  <p class="nav-link">Copyright 2022. All rights reserved.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- #colophon -->
