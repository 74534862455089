import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private readonly translate: TranslateService) {}

  public proceedInFrench(): void {
    this.proceedUsing(SUPPORTED_LANGUAGES.fr);
  }

  public proceedInDutch(): void {
    this.proceedUsing(SUPPORTED_LANGUAGES.nl);
  }

  public proceedInEnglish(): void {
    this.proceedUsing(SUPPORTED_LANGUAGES.en);
  }

  public proceedUsing(language: SUPPORTED_LANGUAGES): void {
    this.translate.setDefaultLang(language);
    this.translate.use(language);

    this.setLocalStorage(language);
  }

  private setLocalStorage(language: SUPPORTED_LANGUAGES) {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, language);
  }
}

export enum SUPPORTED_LANGUAGES {
  nl = 'nl-be',
  fr = 'fr-be',
  en = 'en-us'
}

export const LOCAL_STORAGE_LANGUAGE_KEY = 'lang';
