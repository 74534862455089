import { ControlValueAccessor } from '@angular/forms';
import { SubscriptionBaseDirective } from '../directives/subscription-base.directive';

export abstract class BaseValueAccessor extends SubscriptionBaseDirective implements ControlValueAccessor {
  value: any;
  onTouched: Function = (): void => {};
  onChange: Function = (value: any): void => {};
  disabled: boolean = false;

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
