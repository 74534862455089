<div class="App">
  <h1>Quiz - {{ quizName }}</h1>

  <div class="layout">
    <div class="sidebar">
      <label for="categorySelect">Filter by Category:</label>
      <select
        id="categorySelect"
        (change)="handleCategoryChange($event)">
        <option value="">All Categories</option>
        <option
          *ngFor="let category of categories"
          [value]="category">
          {{ category }}
        </option>
      </select>

      <h3>Total Questions: {{ totalQuestions }}</h3>
      <h3>Questions Answered: {{ totalQuestionsAnswered }}</h3>

      <p>Correct Answers: {{ correctAnswersCount }}</p>
      <p>Incorrect Answers: {{ totalQuestionsAnswered - correctAnswersCount }}</p>

      <p>
        Success Rate:
        {{ successPercentage }} %
      </p>

      <app-button
        [label]="'Reset Quiz Stats'"
        (click)="resetQuizStats()">
      </app-button>
    </div>

    <div class="form">
      <h2>{{ currentQuestion?.question_text }}</h2>

      <div
        *ngIf="currentQuestion"
        class="options">
        <div
          *ngFor="let option of currentQuestion.options | keyvalue"
          class="option-row"
          [ngClass]="{ 'disabled-option': isSubmitted }"
          (click)="handleRowClick(option.key)">
          <div
            class="option"
            [ngClass]="{
              'correct-answer': isSubmitted && currentQuestion.correct_answers.includes(option.key),
              'incorrect-answer':
                isSubmitted &&
                !currentQuestion.correct_answers.includes(option.key) &&
                (isMultipleChoice ? selectedCheckboxes.includes(option.key) : selectedAnswer === option.key)
            }">
            <label>
              <input
                *ngIf="isMultipleChoice"
                type="checkbox"
                [value]="option.key"
                (change)="handleCheckboxChange(option.key)"
                [disabled]="isSubmitted"
                [checked]="selectedCheckboxes.includes(option.key)" />
              <input
                *ngIf="!isMultipleChoice"
                type="radio"
                name="answer"
                [value]="option.key"
                [(ngModel)]="selectedAnswer"
                [disabled]="isSubmitted"
                [checked]="selectedAnswer === option.key" />
              {{ currentQuestion.options[option.key].option_text }}
            </label>
            <p *ngIf="showExplanation && isSubmitted">{{ currentQuestion.options[option.key].explanation }}</p>
          </div>
        </div>
      </div>

      <app-button
        [label]="isSubmitted ? 'Next Question' : 'Submit'"
        (click)="isSubmitted ? handleNewQuestion() : handleSubmit()">
      </app-button>
    </div>
  </div>
</div>
