<form
  [formGroup]="textInputForm"
  class="input-wrapper">
  <label
    [for]="label"
    [ngClass]="focused ? 'active' : ''"
    >{{ label }}</label
  >
  <input
    #searchInput
    formControlName="inputValue"
    id="{{ id }}"
    [placeholder]="placeholder"
    name="{{ label }}"
    (focus)="onFocus()"
    autocomplete="off" />
  <ul
    #dropdownPanel
    [ngClass]="{ open: focused && (list | filter : excludeList).length }"
    class="autocomplete-wrapper">
    <li
      *ngFor="let item of list | filter : excludeList"
      (click)="selectItem(item)">
      {{ item }}
    </li>
  </ul>
</form>
