import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { icon } from 'leaflet';

import { Colleague, Location } from 'src/app/models/colleague';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../services/popup.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { AlertType } from '../../shared/components/alert/alert-type';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input() public colleagues: Colleague[] = [];

  public markers: L.Marker[] = [];

  public markerClusterGroup!: L.MarkerClusterGroup;
  public map: L.Map;

  public constructor(
    private readonly popupService: PopupService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.map = L.map('map').setView([51.505, -0.09], 5);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    this.setCurrentLocation();
    this.setCircles();
    this.initMarkerClusterGroup();
  }

  private setCurrentLocation(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition): void => {
        const latitude: number = position.coords.latitude;
        const longitude: number = position.coords.longitude;
        this.map.setView([latitude, longitude], 12);
      });
    }
  }

  private setCircles(): void {
    const zipMap: { [key: string]: Colleague[] } = {};
    const notFoundZip: string[] = [];

    for (const colleague of this.colleagues) {
      if (colleague.zip) {
        const oldValue: Colleague[] = zipMap[colleague.zip];
        zipMap[colleague.zip] = oldValue ? [...oldValue, colleague] : [colleague];
      }
    }

    for (const zip of Object.keys(zipMap)) {
      const location: Location | undefined = zipMap[zip][0]?.location;
      if (location) {
        this.addMarker(Number(location.lat), Number(location.lng), zipMap[zip]);
      } else {
        notFoundZip.push(zip);
      }
    }

    if (notFoundZip && notFoundZip.length > 0) {
      this.toastService.show(
        `${this.translateService.instant('dashboard.location.toast.failed')}: ${notFoundZip.join(',')}`,
        AlertType.warning,
        7000
      );
    }
  }

  private addMarker(latitude: number, longitude: number, colleagues: Colleague[]): void {
    const marker: L.Marker = L.marker([latitude, longitude], {
      icon: icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'assets/img/colleagues/red-marker.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      })
    });
    marker.bindPopup((): any => this.popupService.createPopup(colleagues));
    this.markers.push(marker);
  }

  private initMarkerClusterGroup(): void {
    this.markerClusterGroup = L.markerClusterGroup({
      iconCreateFunction: (cluster: L.MarkerCluster): L.DivIcon =>
        L.divIcon({
          html: `<div class="custom-marker">${cluster.getChildCount()}</div>`,
          className: 'custom-marker-cluster',
          iconSize: L.point(35, 35)
        }),
      polygonOptions: {
        fill: false,
        stroke: false
      }
    });

    this.markerClusterGroup.addLayers(this.markers);
    this.markerClusterGroup.addTo(this.map);
  }
}
