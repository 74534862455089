<!-- <div class="banner">
  <img
    class="banner-image"
    src="../../../assets/img/intro/compressed-welcome-banner.jpg" />
  <h1 class="banner-text">{{ 'login.subtitle' | translate }}</h1>
</div> -->
<div class="intro-container">
  <div class="information">
    <div class="first-row">
      <div class="first-row-info">
        <h1 class="first-row-info-header">{{ 'introduction.what.whatTitle' | translate }}</h1>
        <div class="first-row-info-text">
          <p>
            {{ 'introduction.what.whatParagraph' | translate }}
          </p>
          <ul class="first-row-info-text-questions">
            <li>{{ 'introduction.what.whatQuestionCollegues' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionColleguesNewCustomer' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionWhoAccountManager' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionCareerPaths' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionRelevantCertificatesHR' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionOnlineTraining' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionCVVersions' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionITTools' | translate }}</li>
            <li>{{ 'introduction.what.whatQuestionOpenTickets' | translate }}</li>
          </ul>
          <p>
            {{ 'introduction.what.whatClosingParagraph' | translate }}
          </p>
        </div>
      </div>
      <video
        controls
        class="first-row-video"
        poster="../../../assets/img/intro/gina-preview.png">
        <source
          src="https://drive.google.com/uc?export=download&id=1xpAVpxhgi25LcfoBJtfuDno8fvW_5Rx2"
          type="video/mp4" />
      </video>
    </div>
    <div class="second-row">
      <video
        controls
        poster="../../../assets/img/intro/johan-preview.png"
        class="first-row-video">
        <source
          src="https://drive.google.com/uc?export=download&id=1B9jZoSw_eBttDR5FoJUK4WOKFkey6O53"
          type="video/mp4" />
      </video>
      <div class="second-row-info">
        <h1 class="second-row-info-header">{{ 'introduction.whatToDo.whatToDoTitle' | translate }}</h1>
        <div class="second-row-info-text">
          <p>
            {{ 'introduction.whatToDo.whatToDoFirstParagraph' | translate }}
          </p>
          <p>
            {{ 'introduction.whatToDo.whatToDoSecondParagraph' | translate }}
          </p>
        </div>
        <a
          routerLink="/resumes"
          rel="resumes"
          class="second-row-info-button"
          >{{ 'introduction.whatToDo.whatToDoCreateResume' | translate }}</a
        >
      </div>
    </div>
  </div>
  <div class="upcoming-features">
    <img
      class="upcoming-features-image"
      src="../../../assets/img/intro/upcoming-features.jpg"
      alt="upcoming features image" />

    <div class="upcoming-features-info">
      <h1 class="upcoming-features-info-header">
        {{ 'introduction.upcomingFeatures.upcomingFeaturesTitle' | translate }}
      </h1>
      <div class="upcoming-features-info-text">
        <p>
          {{ 'introduction.upcomingFeatures.upcomingFeaturesFirstParagraph' | translate }}
        </p>
        <ul class="upcoming-features-info-text-questions">
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesRelevantCertificates' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesOtherConsultants' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesCareerPaths' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesKindOfTraining' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesDevoteamContacts' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesWhereTools' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesTechnicalIssues' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesHomeplace' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesSimilarInterests' | translate }}</li>
          <li>{{ 'introduction.upcomingFeatures.upcomingFeaturesEvents' | translate }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
