import { Component } from '@angular/core';

@Component({
  selector: 'app-main-logo-only',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132.5 132.5"
      class="logo-devoteam"
      role="img"
      aria-label="Devoteam">
      <title>Devoteam</title>
      <path
        class="circle"
        d="M66.3,0a66.3,66.3,0,1,0,66.2,66.3A66.32,66.32,0,0,0,66.3,0m44.1,61.3H85v46.3H54.9c-16.3,0-31-9.9-31-31,0-15.7,8.2-31,31-31h7a86.83,86.83,0,0,1,3.9,15.9H55.3c-9.4,0-14.7,5.1-14.7,15.2,0,9.5,5,15.2,14.7,15.2H68.7V22.1H85V45.7h25.4V61.3Z" />
    </svg>
  `,
  styles: []
})
export class MainLogoOnlyComponent {}
