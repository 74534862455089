import { CvEditorUi } from '../../app/models/cv-editor-ui';
import { skillSuggestionList } from './skill-suggestions';
import { jobSuggestions } from './job-suggestions';
import { softSkillSuggestionList } from './soft-skill-suggestions';

export enum SectionIds {
  personalDetails = 'personalDetails',
  summary = 'summary',
  educations = 'educations',
  languages = 'languages',
  skills = 'skills',
  certifications = 'certifications',
  experiences = 'experiences'
}

export const data: CvEditorUi[] = [
  {
    section: 'Personal details',
    sectionId: SectionIds.personalDetails,
    description:
      'Select your Profile and Experience from the lists. Your profile is missing? You are not sure which experience level to choose? Contact your Practice Manager.',
    fields: [
      {
        'row-fields': [
          {
            type: 'text',
            label: 'First Name',
            value: 'firstName'
          },
          {
            type: 'text',
            label: 'Last Name',
            value: 'lastName'
          }
        ]
      },
      {
        'row-fields': [
          {
            type: 'auto-complete',
            label: 'Role',
            value: 'jobTitle',
            list: jobSuggestions
          }
        ]
      }
    ]
  },
  {
    section: 'Summary',
    sectionId: SectionIds.summary,
    description:
      'This is the place where the magic happens, make sure to be clear and attractive. Describe briefly: who you are, what your competences (technical and soft skills) and professional goals are. Important: use ‘he/she’ and not ‘I’.',
    fields: [
      {
        'row-fields': [
          {
            type: 'text-area',
            label: '',
            value: 'summary'
          }
        ]
      }
    ]
  },
  {
    section: 'Education',
    sectionId: SectionIds.educations,
    description:
      'Add your education: academic qualifications, extracurricular activities, etc. Verify the title of your degree or training on the official website. Last added will be on top of the list, so add most important degree / diploma has last item.',
    value: 'educations',
    button: 'Add an education',
    fields: [
      {
        group: [
          {
            'row-fields': [
              {
                type: 'text',
                label: 'Degree',
                value: 'degree'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'text',
                label: 'School',
                value: 'school'
              },
              {
                type: 'year-date',
                label: 'End',
                value: 'end'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    section: 'Languages',
    sectionId: SectionIds.languages,
    description:
      'Select your language and add your level from Beginner A1/A2 to Intermediate B1/B2 then Advance C1/C2 to Mother tongue. Please use the Self Assessment Grid for Language level guide as reference: self_assessment_grid.pdf.',
    button: 'Add Language',
    value: 'languages',
    fields: [
      {
        'only-deletable': true,
        'inline': true,
        'group': [
          {
            'row-fields': [
              {
                type: 'text',
                label: 'Language',
                value: 'name'
              },
              {
                type: 'drop-down',
                label: 'Level',
                value: 'level',
                list: [
                  'Mother tongue',
                  'Advanced (C2)',
                  'Advanced (C1)',
                  'Intermediate (B2)',
                  'Intermediate (B1)',
                  'Beginner (A2)',
                  'Beginner (A1)'
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    section: 'Skills',
    sectionId: SectionIds.skills,
    description:
      'Add at least one technical skill and select the 5 soft skills which describe you the best. In both cases start to add the skill that you want to appear on top of the list first!',
    fields: [
      {
        category: 'Technical skills',
        button: 'Add Skill Category',
        value: 'technicalSkills',
        group: [
          {
            'row-fields': [
              {
                type: 'text',
                label: 'Category',
                value: 'category'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'multi-select',
                label: 'Skills',
                value: 'name',
                list: skillSuggestionList
              }
            ]
          }
        ]
      },
      {
        'category': 'Soft skills',
        'value': 'softSkills',
        'only-deletable': true,
        'group': [
          {
            'row-fields': [
              {
                type: 'auto-complete',
                label: '',
                value: 'name',
                list: softSkillSuggestionList
              }
            ]
          }
        ]
      }
    ]
  },
  {
    section: 'Certifications',
    sectionId: SectionIds.certifications,
    description:
      'When typing the Certification name, start with an upper case. Verify the exact denomination on the official web site (Example: Red Hat side). The order in which you add the certifications doesn\'t matter, they will be listed in anti-chronological order based on the date. If you don\'t have any certification yet you can skip this section.',
    value: 'certifications',
    button: 'Add Certification',
    fields: [
      {
        group: [
          {
            'row-fields': [
              {
                type: 'text',
                label: 'Certification Name',
                value: 'name'
              },
              {
                type: 'date',
                label: 'Date',
                value: 'date'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'text',
                label: 'Certification ID',
                value: 'id'
              },
              {
                type: 'text',
                label: 'Issuer',
                value: 'issuer'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    section: 'Experiences',
    sectionId: SectionIds.experiences,
    description:
      'If you are adding your current mission let the end date empty. If it is not a mission just type \'None\' in \'Project name\' and \'Project description\'. For the technical environemment, always start with an upper case and check the name of the technology in the official Web Site (Example: Openshift, OPENSHIFT, OpenShift).',
    button: 'Add Experience',
    value: 'experiences',
    fields: [
      {
        group: [
          {
            'row-fields': [
              {
                type: 'text',
                label: 'Company Name',
                value: 'company'
              },
              {
                type: 'text',
                label: 'Project Name',
                value: 'projectName'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'date',
                label: 'Start Date',
                value: 'start'
              },
              {
                type: 'date',
                label: 'End Date',
                value: 'end'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'toggle',
                label: 'Current project?',
                value: 'present'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'text-area',
                label: 'Project Description',
                value: 'projectDescription'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'text',
                label: 'Position',
                value: 'role'
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'text-area',
                label: 'Responsibilities',
                value: 'projectRoleDescription'
              }
            ]
          },
          {
            category: 'Achievements',
            button: 'Add Achievement',
            value: 'tasks',
            group: [
              {
                'row-fields': [
                  {
                    type: 'text',
                    label: 'Achievement',
                    value: 'name'
                  }
                ]
              }
            ]
          },
          {
            'row-fields': [
              {
                type: 'multi-select',
                label: 'Technical Environment',
                value: 'environments',
                list: skillSuggestionList
              }
            ]
          }
        ]
      }
    ]
  }
];
