<div class="additional-menu d-flex order-lg-last">
  <app-button
    *ngIf="!isAuthorized"
    (clicked)="login.emit()"
    class="d-none d-lg-inline-flex"
    label="{{ 'login.loginButton' | translate }}"></app-button>
  <app-button
    *ngIf="isAuthorized"
    (clicked)="logout.emit()"
    class="d-none d-lg-inline-flex"
    label="{{ 'login.logoutButton' | translate }}"></app-button>

  <app-language-menu
    class="d-flex"
    [supportedLanguages]="supportedLanguages"
    [currentLanguage]="currentLanguage"
    (changeLanguage)="changeLanguage.emit($event)"></app-language-menu>

  <app-quick-links></app-quick-links>

  <div class="ms-xxl-5 ms-xl-3 px-0 btn-countries">
    <a
      target="-a"
      href="https://intranet.devoteam.be//"
      class="small d-xl-inline">
      <app-icon
        [icon]="'globe-americas'"
        [classes]="'me-2'"></app-icon>
      <span class="small d-xl-inline">
        {{ 'devoteamBelgium' | translate }}
      </span>
    </a>
  </div>

  <button
    class="navbar-toggler hamburger hamburger--spin"
    type="button"
    [ngClass]="{ 'is-active': isBurgerOpen }"
    data-bs-toggle="collapse"
    data-bs-target="#navbarMainMenu"
    aria-controls="navbarMainMenu"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="clickBurger($event)">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>

  <div
    id="navbarMainMenu"
    class="navbar-collapse me-auto width collapse"
    [ngClass]="{ show: isBurgerOpen }">
    <ul
      id="main-menu"
      class="navbar-nav me-auto mb-2 mb-lg-0 pt-2 py-lg-0 d-inline-block d-lg-none">
      <ng-container *ngIf="isAuthorized">
        <li
          *ngFor="let item of listItems"
          class="menu-item menu-item-type-custom menu-item-object-custom nav-item">
          <a
            [routerLink]="item.link"
            class="nav-link"
            >{{ item.title | translate }}</a
          >
        </li>
      </ng-container>

      <app-button
        *ngIf="!isAuthorized"
        (clicked)="login.emit()"
        label="{{ 'login.loginButton' | translate }}"></app-button>

      <app-button
        *ngIf="isAuthorized"
        (clicked)="logout.emit()"
        label="{{ 'login.logoutButton' | translate }}"></app-button>
    </ul>
  </div>
</div>
