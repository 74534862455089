import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OnboardingChecklistService } from '../../services/onboarding-checklist.service';
import { OnboardingChecklist } from '../../models/onboarding-checklist';

@Component({
  selector: 'app-onboarding-checklist',
  templateUrl: './onboarding-checklist.component.html',
  styleUrls: ['./onboarding-checklist.component.scss']
})
export class OnboardingChecklistComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  private userChecklistSub: Subscription = new Subscription();
  private formSub: Subscription = new Subscription();

  public checklistKeys: string[] = [
    'onboardingChecklistPc',
    'onboardingChecklistCv',
    'onboardingChecklistCommunication',
    'onboardingChecklistHelp',
    'onboardingChecklistIntranet',
    'onboardingChecklistOrganogram',
    'onboardingChecklistProfile',
    'onboardingChecklistInfos',
    'onboardingChecklistMeetings',
    'onboardingChecklistMyhr',
    'onboardingChecklistOnboardingTemplate',
    'onboardingChecklistCvVerification',
    'onboardingChecklistIntermissionTasks'
  ];

  public constructor(
    private readonly fb: FormBuilder,
    private readonly onboardingChecklistService: OnboardingChecklistService
  ) {}

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm(): void {
    this.form = this.initForm();
    this.userChecklistSub = this.onboardingChecklistService
      .find()
      .subscribe((onboardingChecklist: OnboardingChecklist) => this.form.patchValue(onboardingChecklist.checklist));
  }

  private initForm(): FormGroup {
    return this.fb.group(this.createFalseChecklist());
  }

  public updateChecklist(checklistKey: string): void {
    const control: AbstractControl = this.form.controls[checklistKey];
    if (control) {
      this.formSub = this.onboardingChecklistService.save(this.form.value).subscribe();
    }
  }

  public ngOnDestroy(): void {
    this.formSub.unsubscribe();
    this.userChecklistSub.unsubscribe();
  }

  private createFalseChecklist() {
    const falseChecklist: { [key: string]: boolean } = {};
    for (const key of this.checklistKeys) {
      falseChecklist[key] = false;
    }
    return falseChecklist;
  }
}
