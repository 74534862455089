import { FormArray, FormControl } from '@angular/forms';

export const DEFAULT_EDUCATION_ROW = {
  school: '',
  degree: '',
  end: ''
};

export const DEFAULT_LANGUAGE_ROW = {
  name: '',
  level: ''
};

export const DEFAULT_TECHNICAL_SKIILS_ROW = {
  category: '',
  name: ''
};

export const DEFAULT_SOFT_SKIILS_ROW = {
  name: ''
};

export const DEFAULT_CERTIFICATE_ROW = {
  name: '',
  date: '',
  id: '',
  issuer: ''
};

export const DEFAULT_EXPERIENCE_ROW = {
  company: '',
  projectName: '',
  start: '',
  end: '',
  projectDescription: '',
  role: '',
  projectRoleDescription: '',
  tasks: new FormControl(new FormArray([])),
  environments: ''
};

export const DEFAULT_TASK_ROW = {
  name: ''
};

export enum ControlKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  jobTitle = 'jobTitle',
  picture = 'picture',
  summary = 'summary',
  educations = 'educations',
  degree = 'degree',
  school = 'school',
  educationEndDate = 'end',
  technicalSkills = 'technicalSkills',
  technicalSkillsCategory = 'category',
  technicalSkillsName = 'name',
  softSkills = 'softSkills',
  softSkill = 'name',
  certifications = 'certifications',
  certificationName = 'name',
  certificationDate = 'date',
  certificationId = 'certificationId',
  certificationIssuer = 'issuer',
  languages = 'languages',
  languageName = 'name',
  languageLevel = 'level',
  experiences = 'experiences',
  companyName = 'company',
  projectName = 'projectName',
  projectStartDate = 'start',
  projectEndDate = 'end',
  projectPresent = 'present',
  projectDescription = 'projectDescription',
  role = 'role',
  projectRoleDescription = 'projectRoleDescription',
  tasks = 'tasks',
  taskName = 'name',
  environments = 'environments'
}
