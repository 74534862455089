import { Component, Input } from '@angular/core';
import { Certificate } from './../../models/certificate';

@Component({
  selector: 'app-certification-card',
  templateUrl: './certification-card.component.html',
  styleUrls: ['./certification-card.component.scss']
})
export class CertificationCardComponent {
  @Input()
  public certification!: Certificate;
  validCertification: boolean = !this.certification.expiryDate
    ? true
    : new Date(this.certification.expiryDate) > new Date();

  constructor() {}
}
