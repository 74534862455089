<ngx-file-drop
  (onFileDrop)="dropped($event)"
  accept=".docx, .pdf, .pptx"
  [multiple]="false"
  class="drop-zone-wrapper"
  [ngClass]="{
    'upload-dragenter-hover': hasHoverEffect$ | async,
    'upload-dragenter': hasDragEffect$ | async
  }"
  (onFileOver)="addHoverEffect()"
  (onFileLeave)="deleteHoverEffect()">
  <ng-template
    ngx-file-drop-content-tmp
    let-openFileSelector="openFileSelector">
    <div class="content-wrapper">
      <div
        class="loading"
        *ngIf="loading">
        <app-loading [active]="loading"></app-loading>
      </div>
      <app-text-view
        type="pageSubTitle"
        text="resume.upload.message"></app-text-view>
      <div class="icons">
        <img
          class="word-icon"
          src="./assets/img/upload/word-icon.png" />
        <img
          class="pdf-icon"
          src="./assets/img/upload/pdf-icon.png" />
        <img
          class="pptx-icon"
          src="./assets/img/upload/pptx-icon.png" />
      </div>
      <app-button
        (clicked)="openFileSelector($event)"
        [disabled]="loading || isResumeListLoading"
        label="{{ 'resume.upload.browse' | translate }}">
      </app-button>
    </div>
  </ng-template>
</ngx-file-drop>
