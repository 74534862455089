import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ListItem } from '../menu';

@Component({
  selector: 'app-additional-menu',
  templateUrl: './additional-menu.component.html',
  styleUrls: ['./additional-menu.component.scss']
})
export class AdditionalMenuComponent {
  @Input()
  public listItems!: ListItem[];

  @Input()
  public isAuth!: boolean;

  @Output()
    login: EventEmitter<any> = new EventEmitter();

  @Output()
    logout: EventEmitter<any> = new EventEmitter();

  @Input()
  public supportedLanguages!: Object;

  @Input()
  public currentLanguage!: string;

  @Output()
    changeLanguage: EventEmitter<any> = new EventEmitter();

  @HostListener('click') onClick() {
    this.isBurgerOpen = false;
  }

  protected isBurgerOpen = false;

  constructor() {}

  get isAuthorized(): boolean {
    return this.isAuth;
  }

  public clickBurger($event): void {
    $event.stopPropagation();
    this.isBurgerOpen = !this.isBurgerOpen;
  }
}
