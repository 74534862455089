import { Component, Input } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { ProgressBar } from '../../../models/progress-bar';
import { scrollIntoViewWithOffset } from '../../../shared/utils/scroll-into-view-with-offset';
import { SectionIds } from '../../../../assets/cv-editor-ui-structure/cv-editor-ui';

@Component({
  selector: 'app-progress-svg',
  template: `
    <ng-container [ngSwitch]="item.linkSection">
      <span
        (click)="scroll(sectionIds.personalDetails)"
        *ngSwitchCase="'personalDetails'">
        <app-person-svg [fillColor]="fillColor"></app-person-svg>
      </span>
      <span
        (click)="scroll(sectionIds.summary)"
        *ngSwitchCase="'summary'">
        <app-summary-svg [fillColor]="fillColor"></app-summary-svg>
      </span>
      <span
        (click)="scroll(sectionIds.educations)"
        *ngSwitchCase="'educations'">
        <app-education-svg [fillColor]="fillColor"></app-education-svg>
      </span>
      <span
        (click)="scroll(sectionIds.skills)"
        *ngSwitchCase="'skills'">
        <app-skills-svg [fillColor]="fillColor"></app-skills-svg>
      </span>
      <span
        (click)="scroll(sectionIds.certifications)"
        *ngSwitchCase="'certifications'">
        <app-certification-svg [fillColor]="fillColor"></app-certification-svg>
      </span>
      <span
        (click)="scroll(sectionIds.languages)"
        *ngSwitchCase="'languages'">
        <app-language-svg [fillColor]="fillColor"></app-language-svg>
      </span>
      <span
        (click)="scroll(sectionIds.experiences)"
        *ngSwitchCase="'experiences'">
        <app-experience-svg [fillColor]="fillColor"></app-experience-svg>
      </span>
    </ng-container>
  `,
  styleUrls: ['./progress-svg-component.scss']
})
export class ProgressSvgComponent {
  sectionIds = SectionIds;
  @Input() item: ProgressBar;
  @Input() fillColor: string;
  @Input() header: HTMLDivElement;
  @Input() cvEditor?: HTMLElement | null;
  private readonly scrollEvent: Subject<string> = new Subject<string>();

  public constructor() {
    this.scrollEvent.pipe(debounceTime(200)).subscribe((anchor: string) => this.onIconClick(anchor));
  }

  public scroll(anchorName: string): void {
    this.scrollEvent.next(anchorName);
  }

  private onIconClick(anchorName: string): void {
    if (this.header) {
      const headerHeight = this.header.clientHeight;
      scrollIntoViewWithOffset(`#${anchorName}`, this.cvEditor, headerHeight);
    }
  }
}
