<div
  class="dropdown"
  (mouseover)="isOpened = true"
  (mouseleave)="isOpened = false">
  <ng-container *ngFor="let language of SUPPORTED_LANGUAGES | keyvalue">
    <p
      [class.active]="isOpened"
      *ngIf="actualLanguage === language.value">
      {{ language.key | uppercase }}
    </p>
  </ng-container>

  <div
    class="active-dropdown"
    *ngIf="isOpened">
    <ng-container *ngFor="let language of SUPPORTED_LANGUAGES | keyvalue">
      <p
        class="cursor-pointer"
        *ngIf="actualLanguage !== language.value"
        (click)="changeLanguage(language.value)">
        {{ language.key | uppercase }}
      </p>
    </ng-container>
  </div>
</div>
