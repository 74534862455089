<div
  class="paginated-view font-calibri"
  #paginatedView></div>
<div *ngIf="data">
  <app-personal-details
    #elements
    class="left"
    [firstName]="data.firstName"
    [lastName]="data.lastName"
    [jobTitle]="data.jobTitle">
  </app-personal-details>
  <div
    #elements
    class="left picture-container">
    <img
      *ngIf="data.picture"
      class="picture"
      [src]="data.picture"
      referrerpolicy="no-referrer" />
  </div>
  <div *ngIf="checkData(this.data.educations)">
    <p
      #elements
      class="title left">
      Degrees
    </p>
    <div *ngFor="let education of data.educations">
      <app-education
        *ngIf="checkData(education)"
        #elements
        class="left"
        [education]="education"></app-education>
    </div>
  </div>
  <div
    *ngIf="checkData(this.data.languages)"
    #elements
    class="left">
    <p class="title">Languages</p>
    <div *ngFor="let language of data.languages">
      <app-language
        *ngIf="checkData(language)"
        [language]="language"></app-language>
    </div>
  </div>
  <p
    #elements
    class="right font-calibri no-margin word-wrap"
    *ngIf="data.summary">
    {{ data.summary }}
  </p>
  <div *ngIf="checkData(this.data.certifications)">
    <p
      #elements
      class="title right">
      Certifications
    </p>
    <div *ngFor="let certificate of data.certifications">
      <app-certificate
        class="right"
        #elements
        [certificate]="certificate">
      </app-certificate>
    </div>
  </div>
  <div *ngIf="checkData(data.technicalSkills)">
    <p
      #elements
      class="title right">
      Technical skills
    </p>
    <div *ngFor="let skill of data.technicalSkills">
      <app-skill
        class="right"
        #elements
        [skill]="skill">
      </app-skill>
    </div>
  </div>
  <div *ngIf="checkData(data.softSkills)">
    <p
      #elements
      class="title right">
      Soft skills
    </p>
    <div *ngFor="let skill of data.softSkills">
      <app-skill
        #elements
        class="right"
        [skill]="skill">
      </app-skill>
    </div>
  </div>
  <div *ngIf="checkData(this.data.experiences)">
    <p
      #elements
      class="title experience recent-experience">
      Recent professional experience
    </p>
    <div *ngFor="let experience of data.experiences; let i = index; let last = last">
      <div *ngIf="checkData(experience)">
        <p
          #elements
          class="company experience">
          {{ experience.company }}
        </p>
        <p
          #elements
          class="experience-subtitle experience">
          {{ experience.role }}
        </p>
        <p
          #elements
          class="experience-subtitle experience">
          {{ experience.start | formatDateString }} - {{ experience.end | formatDateString }}
        </p>
        <p
          #elements
          class="title experience">
          Context
        </p>
        <p
          #elements
          class="experience-subtitle experience">
          {{ experience.projectName }}
        </p>
        <p
          #elements
          class="experience font-10">
          {{ experience.projectDescription }}
        </p>
        <p
          #elements
          class="title experience">
          Responsibilities
        </p>
        <p
          #elements
          class="experience font-10">
          {{ experience.projectRoleDescription }}
        </p>
        <p
          #elements
          class="title experience">
          Achievements
        </p>
        <ul
          #elements
          class="experience">
          <li
            *ngFor="let task of experience.tasks"
            class="word-wrap">
            {{ task.name }}
          </li>
        </ul>
        <p
          #elements
          class="title experience">
          Technical environment
        </p>
        <p
          #elements
          [ngClass]="{ environment: !last, experience: true }">
          {{ experience.environments }}
        </p>
      </div>
    </div>
  </div>
</div>
