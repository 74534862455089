import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';

import { Toast } from './toast';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))])
    ])
  ]
})
export class ToastComponent implements OnInit, OnDestroy {
  toasts: Toast[] = [];
  private readonly subs = new Subscription();

  constructor(private readonly toastService: ToastService) {}

  ngOnInit(): void {
    const toast$ = this.toastService.toast$.subscribe({
      next: (toast: Toast) => {
        this.toasts.push(toast);
        this.deleteToast(toast.id, toast.delay);
      }
    });
    this.subs.add(toast$);
  }

  private deleteToast(id: number, delay: number): void {
    setTimeout(() => {
      const index = this.toasts.findIndex((value) => value.id === id);
      this.toasts.splice(index, 1);
    }, delay);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
