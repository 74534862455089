import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent {
  @Input() label: string | undefined;
  @Input() downloadOptions: string[];
  @Output() downloadSelected = new EventEmitter<string>();
  showDropdown = false;
  @Input() disabled?: boolean = false;

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  download(option: string) {
    this.downloadSelected.emit(option);
    this.toggleDropdown();
  }
}
