<div class="my-4">
  <div class="position-fixed top-1 end-0 toast-container">
    <app-alert
      *ngFor="let toast of toasts"
      [title]="toast.title"
      [id]="toast.id"
      [type]="toast.type"
      [@inOutAnimation]="toast.delay">
    </app-alert>
  </div>
</div>
