import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { certifications } from 'src/app/pages/certifications/certifications';
import { QuizService } from 'src/app/services/quiz.service';

interface Question {
  question_text: string;
  options: { [key: string]: { option_text: string; explanation?: string } };
  correct_answers: string[];
  category: string;
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  public quizName: string | null = null;
  public categories: string[] = [];
  public currentQuestion: Question | null = null;
  public selectedAnswer: string = '';
  public selectedCheckboxes: string[] = [];
  public isSubmitted: boolean = false;
  public showExplanation: boolean = false;
  public correctAnswersCount: number = 0;
  public totalQuestionsAnswered: number = 0;
  public isMultipleChoice: boolean = false;

  private quizId: string | null = null;
  private questions: Question[] = [];
  private filteredQuestions: Question[] = [];
  private selectedCategory: string = '';
  private answeredQuestions: string[] = [];

  public constructor(
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly quizService: QuizService
  ) {}

  // Calculate the success percentage
  public get successPercentage(): number {
    return this.totalQuestionsAnswered > 0
      ? Math.floor((this.correctAnswersCount / this.totalQuestionsAnswered) * 100)
      : 0;
  }

  // Total number of questions in the filtered list
  public get totalQuestions(): number {
    return this.filteredQuestions.length;
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.quizId = params.get('id');
      if (this.quizId) {
        this.setQuizName();
        this.fetchQuestions(this.quizId);
      }
    });
  }

  // Handle category selection change
  public handleCategoryChange(event: Event): void {
    const category: string = (event.target as HTMLSelectElement).value;
    this.selectedCategory = category;
    this.applyCategoryFilter();
  }

  // Handle answer checkbox change for multiple-choice questions
  public handleCheckboxChange(key: string): void {
    if (this.selectedCheckboxes.includes(key)) {
      this.selectedCheckboxes = this.selectedCheckboxes.filter((answer) => answer !== key);
    } else {
      this.selectedCheckboxes.push(key);
    }
  }

  // Handle row click to select an answer
  public handleRowClick(key: string): void {
    if (this.isSubmitted) return;

    if (this.isMultipleChoice) {
      this.handleCheckboxChange(key);
    } else {
      this.selectedAnswer = key;
    }
  }

  // Handle quiz submission
  public handleSubmit(): void {
    this.isSubmitted = true;
    this.showExplanation = true;

    this.isMultipleChoice = this.currentQuestion!.correct_answers.length > 1;

    let isCorrect: boolean = false;
    if (this.isMultipleChoice) {
      const selectedCorrect: string = this.selectedCheckboxes.sort().toString();
      const correctAnswers: string = this.currentQuestion!.correct_answers.sort().toString();
      isCorrect = selectedCorrect === correctAnswers;
    } else {
      isCorrect = this.currentQuestion!.correct_answers.includes(this.selectedAnswer);
    }

    if (isCorrect) {
      this.correctAnswersCount++;
    }

    this.totalQuestionsAnswered++;
    this.saveToLocalStorage();

    // Add current question to the list of answered questions
    if (this.currentQuestion && !this.answeredQuestions.includes(this.currentQuestion.question_text)) {
      this.answeredQuestions.push(this.currentQuestion.question_text);
    }

    this.updateQuizStats(isCorrect);
  }

  // Reset the quiz stats only for the frontend (local storage)
  public resetQuizStats(): void {
    // Reset only local stats in the frontend (local storage)
    localStorage.removeItem(this.getLocalStorageKey('correctAnswersCount'));
    localStorage.removeItem(this.getLocalStorageKey('totalQuestionsAnswered'));

    // Reset the local state
    this.correctAnswersCount = 0;
    this.totalQuestionsAnswered = 0;
    this.answeredQuestions = [];
  }

  // Handle new question (reset current question and answer selections)
  public handleNewQuestion(): void {
    this.selectedAnswer = '';
    this.selectedCheckboxes = [];
    this.isSubmitted = false;
    this.showExplanation = false;
    this.currentQuestion = this.getRandomQuestion();
  }

  // Update quiz stats on the backend
  private updateQuizStats(isCorrect: boolean): void {
    const updatePayload = {
      quizId: this.quizId!,
      isCorrect
    };

    this.quizService.updateQuizStats(updatePayload).subscribe(
      (_response) => {},
      (error) => {
        console.log('API error:', error);
      }
    );
  }

  // Set the quiz name based on quizId
  private setQuizName(): void {
    const certification = certifications.find((cert) => cert.id === this.quizId);
    this.quizName = certification ? certification.name : null;
  }

  // Fetch questions for the quiz based on quizId
  private fetchQuestions(id: string): void {
    const url = `/assets/quiz/${id}.json`;
    this.http.get<Question[]>(url).subscribe(
      (data) => {
        this.questions = data;
        this.extractCategories();
        this.applyCategoryFilter();
      },
      (error) => console.error('Error loading questions:', error)
    );
  }

  // Extract categories from the list of questions
  private extractCategories(): void {
    this.categories = Array.from(new Set(this.questions.map((q) => q.category)));
  }

  // Apply category filter to the list of questions
  private applyCategoryFilter(): void {
    this.filteredQuestions = this.selectedCategory
      ? this.questions.filter((q) => q.category === this.selectedCategory)
      : [...this.questions];

    this.loadQuizStats(); // Load the quiz stats into local state

    this.currentQuestion = this.getRandomQuestion();
  }

  // Retrieve value from local storage
  private getFromLocalStorage(key: string): number {
    const value = localStorage.getItem(this.getLocalStorageKey(key));
    return value ? parseInt(value, 10) : 0;
  }

  // Save quiz stats to local storage
  private saveToLocalStorage(): void {
    localStorage.setItem(this.getLocalStorageKey('correctAnswersCount'), this.correctAnswersCount.toString());
    localStorage.setItem(this.getLocalStorageKey('totalQuestionsAnswered'), this.totalQuestionsAnswered.toString());
  }

  // Load quiz stats from local storage
  private loadQuizStats(): void {
    this.correctAnswersCount = this.getFromLocalStorage('correctAnswersCount');
    this.totalQuestionsAnswered = this.getFromLocalStorage('totalQuestionsAnswered');
  }

  // Generate a unique key for local storage based on quizId and category
  private getLocalStorageKey(stat: string): string {
    return `${this.quizId}_${this.selectedCategory}_${stat}`;
  }

  // Get a random question from the filtered list
  private getRandomQuestion(): Question | null {
    if (this.filteredQuestions.length === 0) return null;

    // Get unanswered questions
    let unansweredQuestions: Question[] = this.filteredQuestions.filter(
      (q: Question): boolean => !this.answeredQuestions.includes(q.question_text)
    );

    // Reset if all questions have been answered
    if (unansweredQuestions.length === 0) {
      this.answeredQuestions = [];
      unansweredQuestions = [...this.filteredQuestions];
    }

    // Generate a random index based on local time for added randomness
    const randomIndex: number = Math.floor((Date.now() * Math.random()) % unansweredQuestions.length);
    const question: Question = unansweredQuestions[randomIndex];

    // Set multiple choice flag based on the selected question
    this.isMultipleChoice = question.correct_answers.length > 1;
    return question;
  }
}
