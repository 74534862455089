import { Component, Input } from '@angular/core';
import { ListItem } from '../menu';

/**
 * The main menu of the navigation bar contains the basic routing options of the application
 */
@Component({
  selector: 'app-navbar-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  @Input()
  public listItems!: ListItem[];

  @Input()
  public isAuth!: boolean;

  constructor() {}
}
