import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {}

  login(): void {
    this.authService.checkUserVisitedAbout();
  }

  get isAuthorized(): boolean {
    return this.authService.isAuthorized;
  }
}
