import { AfterViewChecked, Component, Input } from '@angular/core';
import { ComponentStatus } from 'src/app/models/component-status';
import { Profile } from 'src/app/models/profile';

@Component({
  selector: 'app-general-info-card',
  templateUrl: './general-info-card.component.html',
  styleUrls: ['./general-info-card.component.scss']
})
export class GeneralInfoCardComponent implements AfterViewChecked {
  @Input() public profile!: Profile;
  @Input() public status: ComponentStatus;
  @Input() public statusCode: number | null;
  public errorMessage: string;

  public ComponentStatus = ComponentStatus;

  public constructor() {}

  public ngAfterViewChecked(): void {
    this.errorMessage = `dashboard.errors.${this.statusCode}`;
  }
}
