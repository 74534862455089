import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatDateString' })
export class FormatDateStringPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return '';
    }
    return value.split('-').reverse()
.join('-');
  }
}
