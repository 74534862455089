export interface Certification {
  id: string;
  name: string;
  logo: string;
  examTimeLimit: number;
  totalQuestions: number;
  passingScore: number;
}

export const certifications: Certification[] = [
  {
    id: 'clf_c02',
    name: 'AWS Certified Cloud Practitioner',
    logo: 'assets/quiz/clf_c02.png',
    examTimeLimit: 90,
    totalQuestions: 65,
    passingScore: 70
  },
  {
    id: 'aif_c01',
    name: 'AWS Certified AI Practitioner',
    logo: 'assets/quiz/aif_c01.png',
    examTimeLimit: 90,
    totalQuestions: 65,
    passingScore: 70
  },
  {
    id: 'dva_c02',
    name: 'AWS Certified Developer - Associate',
    logo: 'assets/quiz/dva_c02.png',
    examTimeLimit: 130,
    totalQuestions: 65,
    passingScore: 72
  },
  {
    id: 'saa_c03',
    name: 'AWS Certified Solutions Architect - Associate',
    logo: 'assets/quiz/saa_c03.png',
    examTimeLimit: 130,
    totalQuestions: 65,
    passingScore: 72
  },
  {
    id: 'gcp_cdl',
    name: 'GCP Certified Cloud Digital Leader',
    logo: 'assets/quiz/gcp_cdl.png',
    examTimeLimit: 90,
    totalQuestions: 50,
    passingScore: 70
  },
  {
    id: 'sf_aia',
    name: 'Salesforce Certified AI Associate',
    logo: 'assets/quiz/sf_aia.png',
    examTimeLimit: 90,
    totalQuestions: 60,
    passingScore: 65
  },
  {
    id: 'hc_ta_003',
    name: 'HashiCorp Certified Terraform Associate',
    logo: 'assets/quiz/hc_ta_003.png',
    examTimeLimit: 60,
    totalQuestions: 57,
    passingScore: 70
  }
];

