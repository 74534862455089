import { Component, Input } from '@angular/core';
import { ListItemChild } from '../../menu';
import { ListItem } from '../../menu';

@Component({
  selector: 'app-navbar-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements ListItem {
  @Input() id!: number;
  @Input() title!: string;
  @Input() link!: string;
  @Input() children!: ListItemChild[];

  /**
   * Returns whether the list-item contains any dropdown children.
   */
  protected isDropdownMenu(): boolean {
    return this.children.length > 0;
  }
}
