export interface Certification {
  id: string;
  name: string;
  logo: string;
}

export const certifications: Certification[] = [
  { id: 'clf_c02', name: 'AWS Certified Cloud Practitioner', logo: 'assets/quiz/clf_c02.png' },
  { id: 'aif_c01', name: 'AWS Certified AI Practitioner', logo: 'assets/quiz/aif_c01.png' },
  { id: 'dva_c02', name: 'AWS Certified Developer - Associate', logo: 'assets/quiz/dva_c02.png' },
  { id: 'saa_c03', name: 'AWS Certified Solutions Architect - Associate', logo: 'assets/quiz/saa_c03.png' },
  { id: 'gcp_cdl', name: 'GCP Certified Cloud Digital Leader', logo: 'assets/quiz/gcp_cdl.png' },
  { id: 'sf_aia', name: 'Salesforce Certified AI Associate', logo: 'assets/quiz/sf_aia.png' }
];
