import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.scss']
})
export class SupportButtonComponent implements OnInit {
  public showChat = false;

  public constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {}

  openChat(): void {
    this.showChat = !this.showChat;
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent): void {
    if (event.target instanceof HTMLElement) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.close(false);
      }
    }
  }

  close(value: boolean) {
    this.showChat = value;
  }
}
