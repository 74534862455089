<div
  id="navbarMainMenu"
  *ngIf="isAuth"
  class="collapse navbar-collapse me-auto width">
  <ul
    id="main-menu"
    class="navbar-nav me-auto mb-2 mb-lg-0 pt-2 pb-5 py-lg-0">
    <app-navbar-list-item
      *ngFor="let item of listItems; index as i"
      [id]="i"
      [title]="item.title"
      [link]="item.link"
      [children]="item.children"></app-navbar-list-item>
  </ul>
</div>
