import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { switchMap, catchError } from 'rxjs/operators';
import { UsersService } from '../services/users.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  public constructor(
    private readonly authService: AuthService,
    private readonly jwtHelper: JwtHelperService,
    private readonly usersService: UsersService,
    private readonly router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token: string = this.jwtHelper.tokenGetter().toString();

    if (token && !this.isOldJwt(token) && !this.jwtHelper.isTokenExpired(token)) {
      const decoded = this.jwtHelper.decodeToken(token);
      const email = decoded.email;
      return this.usersService.getUserRole(email).pipe(
        switchMap((response) => {
          if (response.role === 'admin') {
            return [true];
          }
          return [false];
        }),
        catchError(() => {
          // If the API call fails (e.g., user doesn't exist), redirect to login or error page
          this.router.navigate(['/server-error']);
          return [false];
        })
      );
    }

    // If the token is invalid or expired, redirect to login
    this.authService.login(state.url);
    return false;
  }

  private isOldJwt(token: string): boolean {
    const decoded: { id?: string; email?: string; role?: string } | null = this.jwtHelper.decodeToken(token);
    if (!decoded) return true;
    return !decoded.id || !decoded.email;
  }
}
