import { Component, ElementRef, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  private backdrop!: HTMLElement;
  public style: { display: 'block' | 'none' } = { display: 'none' };

  @Input() title?: string;

  constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly elementRef: ElementRef) {}

  open(): void {
    this.elementRef.nativeElement.classList.add('modal-open');
    this.style = { display: 'block' };
    this.showBackdrop();
  }

  close(): void {
    this.elementRef.nativeElement.classList.remove('modal-open');
    this.style = { display: 'none' };
    this.hideBackdrop();
  }

  protected showBackdrop(): void {
    this.backdrop = this.document.createElement('div');
    this.backdrop.classList.add('modal-backdrop');
    this.backdrop.classList.add('show');
    this.document.body.appendChild(this.backdrop);
  }

  protected hideBackdrop(): void {
    this.backdrop?.remove();
  }

  clickOutSide(event: MouseEvent): void {
    const targetElement: HTMLElement | null = event.target as HTMLElement;
    const isReadyToClose = targetElement?.classList?.value.indexOf('modal') >= 0;
    if (isReadyToClose) {
      this.close();
    }
  }
}
