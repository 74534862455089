import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {
  constructor() {}
  activateBackToTop: boolean;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.activateBackToTop = window.scrollY > 100 ? true : false;
  }

  scrollToTop() {
    return window.scrollTo(0, 0);
  }
  ngOnInit(): void {
    this.activateBackToTop = false;
  }
}
