<div class="page">
  <div class="header">
    <div class="title-section">
      <h1>Admin Dashboard</h1>
      <h2>User Quiz Stats</h2>
    </div>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>User Email</th>
            <th>Quiz ID</th>
            <th>Total Questions Answered</th>
            <th>Correct Answers</th>
            <th>Success Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stat of quizStats">
            <td>{{ stat.userEmail }}</td>
            <td>{{ stat.quizId }}</td>
            <td>{{ stat.totalQuestionsAnswered }}</td>
            <td>{{ stat.correctAnswersCount }}</td>
            <td>{{ stat.successPercentage }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
