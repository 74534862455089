<header
  id="masthead"
  class="site-header headroom main-masthead">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
      <!-- Devoteam Logo-->
      <h1 class="m-0">
        <a
          routerLink="/"
          rel="home"
          class="navbar-brand logo-devoteam-baseline d-inline-block">
          <app-component-logo-main></app-component-logo-main>
          <span class="baseline">Creative tech for Better Change</span>
        </a>
      </h1>

      <!-- Main Manu in the center-->
      <div
        id="navbarMainMenu"
        class="collapse navbar-collapse me-auto width">
        <app-navbar-main-menu
          [listItems]="listItems"
          [isAuth]="isAuth"></app-navbar-main-menu>
      </div>

      <!-- Additional Menu on the right-->
      <app-additional-menu
        [listItems]="listItems"
        [isAuth]="isAuth"
        (login)="login.emit()"
        (logout)="logout.emit()"
        [supportedLanguages]="supportedLanguages"
        [currentLanguage]="currentLanguage"
        (changeLanguage)="changeLanguage.emit($event)"></app-additional-menu>
    </div>
  </nav>
</header>
