import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends ModalComponent implements OnInit {
  @ViewChild('confirmationComponent') modalComponent!: ModalComponent;
  @Input() description?: string;

  @Output() confirmed = new EventEmitter<MouseEvent>();
  @Output() closed = new EventEmitter<MouseEvent>();

  constructor(@Inject(DOCUMENT) document: Document, elementRef: ElementRef) {
    super(document, elementRef);
  }

  ngOnInit(): void {}

  onCloseClicked(event: MouseEvent) {
    this.modalComponent.close();
    this.closed.emit(event);
  }

  onConfirmClicked(event: MouseEvent) {
    this.modalComponent.close();
    this.confirmed.emit(event);
  }

  override open() {
    this.modalComponent.open();
  }
}
