<div class="colleague-card">
  <div class="content-wrapper">
    <img
      src="{{ colleague.picture || '../../../../../assets/img/image-3.png' }}"
      alt=""
      class="colleague-photo" />
    <div class="colleague-info">
      <p class="name">{{ colleague.name }}</p>
      <span class="email-wrapper">
        <p>
          <a href="mailto:{{ colleague.email }}">{{ colleague.email }}</a>
        </p>
        <span
          class="copy-icon"
          (click)="copyEmail(colleague.email)"
          [tooltip]="'dashboard.copy-email' | translate">
          <app-icon
            [type]="'regular'"
            [icon]="'clone'"
            [size]="'14'"></app-icon>
        </span>
      </span>
      <p
        *ngIf="showPractice"
        class="practice">
        {{ colleague.practice }}
      </p>
      <p
        *ngIf="showUnit"
        class="unit">
        {{ colleague.unit }}
      </p>
      <p *ngIf="showClient">
        {{ colleague.commonClients ? colleague.commonClients.join(',') : colleague.client }}
      </p>
    </div>
  </div>
</div>
