import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class ExcludeFilterPipe implements PipeTransform {
  transform(list: any[], exclude: any[]): any[] {
    if (!list || !list.length) return [];
    if (!exclude || !exclude.length) return list;

    return list.filter((item) => !exclude.includes(item));
  }
}
