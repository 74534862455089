<app-modal
  [title]="title"
  #confirmationComponent>
  <app-modal-body>{{ description }}</app-modal-body>
  <app-modal-footer>
    <div class="d-flex">
      <button
        type="button"
        class="btn btn-close"
        (click)="onCloseClicked($event)">
        {{ 'shared.cancel' | translate }}
      </button>
      <app-button
        [label]="'shared.confirm' | translate"
        (clicked)="onConfirmClicked($event)">
      </app-button>
    </div>
  </app-modal-footer>
</app-modal>

<!--
<app-confirmation [title]="'Title'"
                  #modalComponentRef
                  description="description"
                  (onClose)="close()"
                  (onConfirmed)="confirmed()">
</app-confirmation>


<button type="button" class="btn btn-primary" (click)="modalComponentRef.open()">open</button>
-->
