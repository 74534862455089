import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { delay, retry } from 'rxjs';
import { ComponentStatus } from './../../models/component-status';
import { ToastService } from './../../shared/components/toast/toast.service';
import { ResumesService } from './../../services/resumes.service';
import { Resume } from './../../models/resume';

@Component({
  selector: 'app-resumes',
  templateUrl: './resumes.component.html',
  styleUrls: ['./resumes.component.scss']
})
export class ResumesComponent implements OnInit {
  public resumes!: Resume[];

  public status!: ComponentStatus;
  public ComponentStatus: typeof ComponentStatus = ComponentStatus;

  public constructor(
    private readonly resumeService: ResumesService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.loadResumes();
  }

  public removeResume(resourceIndex: number): void {
    this.resumes.splice(resourceIndex, 1);
  }

  public refreshResumes(): void {
    this.loadResumes();
  }

  private loadResumes(): void {
    this.status = ComponentStatus.LOADING;

    this.resumeService
      .getAllResumes()
      .pipe(retry(3), delay(500))
      .subscribe({
        next: (data) => {
          this.resumes = data;
          this.status = ComponentStatus.OK;
          if (this.resumes?.length) {
            this.toastService.showSuccess(this.translateService.instant('resume.toast.success'));
          }
        },
        error: () => {
          this.status = ComponentStatus.ERROR;
          this.toastService.showError(this.translateService.instant('resume.toast.error'));
        }
      });
  }

  public unsetResumes($resumeAsMaster: Resume): void {
    for (const resume of this.resumes) {
      if (resume.id !== $resumeAsMaster.id) {
        resume.master = false;
      }
    }
  }
}
