// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  dsn: 'https://adcfc0a53e8b48b08e1d7f02cc323365@o4504101522767872.ingest.sentry.io/4504101527420928',
  apiUrl: 'https://my-dev.devoteam.be/api',
  authClientId: '1162470820-satldln5b13u1v164kngq6858nkf5165.apps.googleusercontent.com',

  cvGeneratorUrl: 'https://my-dev.devoteam.be/cv-generator',
  wordTemplateUrl: './assets/files/CV_-_Lastname_Firstname_-_YYYY_MM.docx',
  onePageCvUrl: './assets/files/CV_One_Page.pptx',
  googleDocsUrl: 'https://drive.google.com/drive/folders/1JwK4Lgp4ZUaicF7G8ta8GLjm3BI7H1wN',
  referralUrl: 'https://www.smartrecruiters.com/referrals-portal/Devoteam/global/refer/manual',
  intranetBeUrl: 'https://sites.google.com/devoteam.com/devoteam-belgium-employees/home',
  intranetEmeaUrl: 'https://sites.google.com/devoteam.com/group-intranet',
  portalUrl: 'https://portal.devoteam.be/',
  appsPortalUrl: 'https://appsportal.devoteam.com/',
  supportEmailAddress: 'mailto:be.my@devoteam.com',

  showMasterCV: true,
  showGuidelines: true,
  showWhoIsWorkingAtMyClient: true,
  showCertificates: true,
  showChatbot: true,
  showCv: true,
  showColleaguesInUnitAndPractice: true,
  showOnboardingChecklist: true,
  showColleaguesOnTheMap: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// Import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
