<section class="error-404 not-found">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-xxl-5">
        <header class="page-header">
          <h1 class="page-title">
            <span class="text-primary"><strong>#404</strong></span>
            <br />
            {{ '404.pageNotFound' | translate }}
          </h1>
        </header>
        <!-- .page-header -->

        <div class="page-content">
          <h2 class="sub-title mt-4">
            {{ '404.subtitle' | translate }}
          </h2>

          <p>
            {{ '404.solution' | translate }}
            <a [routerLink]="LANDING_PAGE_URL">{{ '404.goHome' | translate }}</a
            >.
          </p>
        </div>
        <!-- .page-content -->
      </div>
    </div>
  </div>
</section>
<!-- .error-404 -->
