<form
  [formGroup]="dorpDownForm"
  class="input-wrapper">
  <label
    [for]="label"
    [ngClass]="focused ? 'active' : ''"
    >{{ label }}</label
  >
  <select
    [id]="id"
    formControlName="inputValue"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [name]="label">
    <option
      disabled
      selected
      value="">
      Choose a level
    </option>
    <option
      *ngFor="let item of list; let i = index"
      [value]="item">
      {{ item }}
    </option>
  </select>
</form>
