<!-- certifications.component.html -->
<div class="certifications-container">
  <h1>Certifications</h1>
  <div class="certification-grid">
    <div
      *ngFor="let certification of certifications"
      class="certification-card"
      (click)="navigateToCertification(certification.id)">
      <img
        [src]="certification.logo"
        [alt]="certification.name + ' Logo'" />
      <p>{{ certification.name }}</p>
    </div>
  </div>
</div>
