import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { JwtConfig } from '@auth0/angular-jwt/lib/angular-jwt.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxFileDropModule } from 'ngx-file-drop';
import * as Sentry from '@sentry/angular';

import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { Router } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CertificationCardComponent } from './components/certification-card/certification-card.component';
import { CreateResumeCardComponent } from './components/create-resume-card/create-resume-card.component';
import { GeneralInfoCardComponent } from './components/general-info-card/general-info-card.component';
import { ResumeCardComponent } from './components/resume-card/resume-card.component';
import { UploadComponent } from './components/upload/upload.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AdditionalMenuComponent } from './navigation-bar/additional-menu/additional-menu.component';
import { LanguageMenuComponent } from './navigation-bar/additional-menu/language-menu/language-menu.component';
import { ListItemChildComponent } from './navigation-bar/main-menu/list-item/list-item-child/list-item-child.component';
import { ListItemComponent } from './navigation-bar/main-menu/list-item/list-item.component';
import { MainMenuComponent } from './navigation-bar/main-menu/main-menu.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { NotFoundPageComponent } from './page-not-found/page-not-found.component';
import { AppOfflineComponent } from './pages/app-offline/app-offline.component';
import { CertificatesComponent } from './pages/certificates/certificates.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ResumesComponent } from './pages/resumes/resumes.component';
import { LoginService } from './services/login.service';
import { ActionCardComponent } from './shared/components/action-card/action-card.component';
import { AlertModule } from './shared/components/alert/alert.module';
import { ButtonComponent } from './shared/components/button/button.component';
import { ButtonModule } from './shared/components/button/button.module';
import { ChangeLanguageComponent } from './shared/components/change-language/change-language.component';
import { ConfirmationModule } from './shared/components/confirmation/confirmation.module';
import { IconComponent } from './shared/components/icons/icon.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { MainLogoLightComponent } from './shared/components/logos/main-logo-light/main-logo-light.component';
import { MainLogoOnlyComponent } from './shared/components/logos/main-logo-only/main-logo-only.component';
import { MainLogoComponent } from './shared/components/logos/main-logo.component.ts/main-logo.component';
import { ModalModule } from './shared/components/modal/modal.module';
import { TextViewComponent } from './shared/components/text-view/text-view.component';
import { ToastModule } from './shared/components/toast/toast.module';
import { TranslationModule } from './shared/translations/translation.module';
import { GuidelinesComponent } from './pages/guidelines/guidelines.component';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { ServerErrorComponent } from './shared/components/server-error/server-error.component';
import { EmailNotSupportedComponent } from './shared/components/email-not-supported/email-not-supported.component';
import { ColleaguesComponent } from './components/colleagues/colleagues.component';
import { DirectivesModule } from './shared/directives/directives.module';
import { TooltipComponent } from './shared/components/tooltip/tooltip/tooltip.component';
import { IntroComponent } from './pages/intro/intro.component';
import { ScrollToTopComponent } from './shared/components/scroll-to-top/scroll-to-top.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepperItemComponent } from './components/stepper/stepper-item/stepper-item.component';
import { StepperHeaderComponent } from './components/stepper/stepper-header/stepper-header.component';
import { NewGuidelinesComponent } from './pages/new-guidelines/new-guidelines.component';
import { RemoveDashPipe } from './shared/pipes/remove-dash';
import { ExcludeFilterPipe } from './shared/pipes/filter';
import { ColleagueCardsComponent } from './components/colleagues/colleague-cards/colleague-cards.component';
import { MapComponent } from './components/map/map.component';
import { ColleagueCardComponent } from './components/colleagues/colleague-cards/colleague-card/colleague-card.component';
import { SupportButtonComponent } from './shared/components/support-button/support-button.component';
import { ChatbotComponent } from './shared/components/chatbot/chatbot.component';
import { QuickLinksComponent } from './navigation-bar/main-menu/quick-links/quick-links.component';
import { PaginatedViewComponent } from './components/cv-generator/preview/paginated-view/paginated-view.component';
import { PersonalDetailsComponent } from './components/cv-generator/preview/personal-details/personal-details.component';
import { SkillComponent } from './components/cv-generator/preview/skill/skill.component';
import { CvGeneratorComponent } from './pages/cv-generator/cv-generator.component';
import { CvEditorComponent } from './components/cv-generator/cv-editor/cv-editor.component';
import { TextInputComponent } from './shared/components/cv-inputs/text-input/text-input.component';
import { EducationComponent } from './components/cv-generator/preview/education/education.component';
import { LanguageComponent } from './components/cv-generator/preview/language/language.component';
import { CvPageNavigatorComponent } from './components/cv-generator/preview/cv-page-navigator/cv-page-navigator.component';
import { TextAreaComponent } from './shared/components/cv-inputs/text-area/text-area.component';
import { CertificateComponent } from './components/cv-generator/preview/certificate/certificate.component';
import { AutocompleteInputComponent } from './shared/components/cv-inputs/autocomplete-input/autocomplete-input.component';
import { DropdownComponent } from './shared/components/cv-inputs/dropdown/dropdown.component';
import { DatepickerComponent } from './shared/components/cv-inputs/datepicker/datepicker.component';
import { PersonSvgComponent } from './components/cv-generator/cv-editor/icons/person.component';
import { SummerySvgComponent } from './components/cv-generator/cv-editor/icons/summary.component';
import { EducationSvgComponent } from './components/cv-generator/cv-editor/icons/education.component';
import { LanguageSvgComponent } from './components/cv-generator/cv-editor/icons/language.component';
import { SkillsSvgComponent } from './components/cv-generator/cv-editor/icons/skills.component';
import { ExperienceSvgComponent } from './components/cv-generator/cv-editor/icons/experience.component';
import { CertificationSvgComponent } from './components/cv-generator/cv-editor/icons/certification.component';
import { ProgressSvgComponent } from './components/cv-generator/cv-editor/progress-svg.component';
import { MultiSelectComponent } from './shared/components/multi-select/multi-select.component';
import { YearDatepickerComponent } from './shared/components/cv-inputs/year-datepicker/year-datepicker.component';
import { DropdownButtonComponent } from './shared/components/cv-inputs/dropdown-button/dropdown-button.component';
import { DoubleArrowUpSvgComponent } from './components/cv-generator/cv-editor/icons/double-arrow-up.component';
import { FormatDateStringPipe } from './shared/pipes/format-date-string-pipe';
import { OnboardingChecklistComponent } from './pages/onboarding-checklist/onboarding-checklist.component';
import { PopupComponent } from './shared/components/custom-element/popup/popup.component';
import { PopupModule } from './shared/components/custom-element/popup/popup.module';
import { SupportEmailComponent } from './shared/components/support-email/support-email.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { CertificationsComponent } from './pages/certifications/certifications.component';
import { AdminComponent } from './pages/admin/admin.component';

export function jwtOptionsFactory(loginService: LoginService): JwtConfig {
  const uri = new URL(environment.apiUrl);
  return {
    tokenGetter: () => loginService.token(),
    allowedDomains: [uri.host]
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ChangeLanguageComponent,
    NavigationBarComponent,
    MainMenuComponent,
    ListItemComponent,
    AdditionalMenuComponent,
    LanguageMenuComponent,
    FooterComponent,
    LoginComponent,
    ResumesComponent,
    CertificatesComponent,
    CertificationsComponent,
    NotFoundPageComponent,
    EmailNotSupportedComponent,
    CreateResumeCardComponent,
    ResumeCardComponent,
    ListItemChildComponent,
    LoadingComponent,
    UploadComponent,
    TextViewComponent,
    MainLogoComponent,
    MainLogoLightComponent,
    MainLogoOnlyComponent,
    CertificationCardComponent,
    IconComponent,
    ActionCardComponent,
    GeneralInfoCardComponent,
    DashboardComponent,
    AppOfflineComponent,
    HomeComponent,
    GuidelinesComponent,
    ServerErrorComponent,
    ColleaguesComponent,
    TooltipComponent,
    IntroComponent,
    ScrollToTopComponent,
    StepperComponent,
    StepperItemComponent,
    StepperHeaderComponent,
    NewGuidelinesComponent,
    RemoveDashPipe,
    ExcludeFilterPipe,
    ColleagueCardsComponent,
    ColleagueCardComponent,
    SupportButtonComponent,
    ChatbotComponent,
    QuickLinksComponent,
    CvGeneratorComponent,
    PaginatedViewComponent,
    PersonalDetailsComponent,
    SkillComponent,
    TextInputComponent,
    CvGeneratorComponent,
    CvEditorComponent,
    EducationComponent,
    LanguageComponent,
    CvPageNavigatorComponent,
    TextAreaComponent,
    CertificateComponent,
    AutocompleteInputComponent,
    DropdownComponent,
    DatepickerComponent,
    PersonSvgComponent,
    SummerySvgComponent,
    EducationSvgComponent,
    LanguageSvgComponent,
    CertificationSvgComponent,
    SkillsSvgComponent,
    ExperienceSvgComponent,
    ProgressSvgComponent,
    MultiSelectComponent,
    YearDatepickerComponent,
    DropdownButtonComponent,
    DoubleArrowUpSvgComponent,
    FormatDateStringPipe,
    OnboardingChecklistComponent,
    MapComponent,
    PopupComponent,
    SupportEmailComponent,
    QuizComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslationModule,
    BsDatepickerModule,
    AppRoutingModule,
    FormsModule,
    NgxFileDropModule,
    NgxHideOnScrollModule,
    ModalModule,
    UiSwitchModule,
    ConfirmationModule,
    ToastModule,
    OAuthModule.forRoot(),
    ButtonModule,
    AlertModule,
    DirectivesModule,
    ClipboardModule,
    ReactiveFormsModule,
    DragDropModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [LoginService]
      }
    }),
    LeafletModule,
    PopupModule,
    LeafletMarkerClusterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [ButtonComponent]
})
export class AppModule {}
