import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { delay, forkJoin, map, Observable, retry, tap } from 'rxjs';
import { ColleaguesService } from '../../services/colleagues.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { Colleague } from '../../models/colleague';
import { ComponentStatus } from '../../models/component-status';
import { COLLEAGUES_SERVICE_DELAY, COLLEAGUES_SERVICE_RETRY } from '../../shared/utils/constants';
import { environment } from '../../../environments/environment';
import { ColleaguesTabNames } from '../../shared/enums/unit-practice-client-tab-names.enum';

interface ColleaguesComponentTab {
  name: string;
  title: string;
  counter: number;
  colleagues: Colleague[];
  showUnit: boolean;
  showPractice: boolean;
  showClient: boolean;
}

@Component({
  selector: 'app-colleagues',
  templateUrl: './colleagues.component.html',
  styleUrls: ['./colleagues.component.scss']
})
export class ColleaguesComponent implements OnInit {
  public tabs: ColleaguesComponentTab[];
  public activeTab: ColleaguesComponentTab;
  public status: ComponentStatus;
  public allColleagues: Colleague[];
  private colleagues: Colleague[];

  private readonly defaultColleaguesTab: ColleaguesComponentTab = {
    name: '',
    title: '',
    counter: 0,
    colleagues: [],
    showUnit: false,
    showPractice: false,
    showClient: false
  };

  public constructor(
    private readonly colleaguesService: ColleaguesService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {
    this.tabs = [];
    this.colleagues = [];
    this.generateTabs();
  }

  public ngOnInit(): void {
    this.activeTab = this.tabs[0];
    this.getAllColleagues();
  }

  public setActivateTab(tab: ColleaguesComponentTab): void {
    this.activeTab = tab;
  }

  private getAllColleagues(): void {
    this.status = ComponentStatus.LOADING;

    // TODO: Check best practices and performance
    this.colleaguesService.getAllColleagues().subscribe({
      next: (data: Colleague[]): void => {
        this.allColleagues = data;
      },
      error(): void {}
    });

    const colleaguesInSameUnitOrPractice$: Observable<Colleague[]> =
      this.colleaguesService.getAllColleaguesInSameUnitOrPractice();
    const colleaguesWithSameClient$: Observable<Colleague[]> = this.colleaguesService.getAllColleaguesWithSameClient();
    const combinedColleagues$: Observable<[Colleague[], Colleague[]]> = forkJoin([
      colleaguesInSameUnitOrPractice$,
      colleaguesWithSameClient$
    ]);
    combinedColleagues$
      .pipe(
        retry(COLLEAGUES_SERVICE_RETRY),
        delay(COLLEAGUES_SERVICE_DELAY),
        tap((value) => console.log(value)),
        map(([colleaguesInSameUnitOrPractice, colleaguesWithSameClient]) => [
          ...colleaguesInSameUnitOrPractice,
          ...colleaguesWithSameClient
        ])
      )
      .subscribe({
        next: (data: Colleague[]): void => {
          this.colleagues = data;
          this.updateTabsWithColleagues();
          this.status = ComponentStatus.OK;
          this.toastService.showSuccess(this.translateService.instant('dashboard.colleagues.toast.success'));
        },
        error: (): void => {
          this.status = ComponentStatus.ERROR;
          this.toastService.showError(this.translateService.instant('dashboard.colleagues.toast.error'));
        }
      });
  }

  private filterColleagues(field: string): Colleague[] {
    return this.colleagues.filter((colleague: Colleague): boolean => colleague.team === field);
  }

  private updateTabsWithColleagues(): void {
    for (const tab of this.tabs) {
      const filteredColleagues: Colleague[] = this.filterColleagues(tab.name);
      tab.colleagues = filteredColleagues;
      if (tab.name === ColleaguesTabNames.NEIGHBOURS.toString()) {
        tab.counter = this.allColleagues ? this.allColleagues.length : 0;
      } else tab.counter = filteredColleagues.length;
    }
  }

  private generateTabs(): void {
    if (environment.showWhoIsWorkingAtMyClient) {
      this.tabs.push({
        ...this.defaultColleaguesTab,
        name: ColleaguesTabNames.CLIENT,
        title: 'dashboard.colleagues.client.title',
        showUnit: false,
        showPractice: true,
        showClient: true
      });
    }
    if (environment.showColleaguesInUnitAndPractice) {
      this.tabs.push(
        ...[
          {
            ...this.defaultColleaguesTab,
            name: ColleaguesTabNames.PRACTICE,
            title: 'dashboard.colleagues.practice.title',
            showUnit: false,
            showPractice: false,
            showClient: true
          },
          {
            ...this.defaultColleaguesTab,
            name: ColleaguesTabNames.UNIT,
            title: 'dashboard.colleagues.unit.title',
            showUnit: false,
            showPractice: true,
            showClient: true
          }
        ]
      );
    }
    if (environment.showColleaguesOnTheMap) {
      this.tabs.push({
        ...this.defaultColleaguesTab,
        name: ColleaguesTabNames.NEIGHBOURS,
        title: 'dashboard.neighbours',
        showUnit: true,
        showPractice: true,
        showClient: true
      });
    }
  }

  protected readonly ColleaguesTabNames = ColleaguesTabNames;
}
