<div
  class="d-flex align-items-center justify-content-center"
  style="height: 90vh">
  <div>
    <div class="content">
      <h1 class="m-0">
        <app-main-logo-only class="logo-devoteam-only-baseline d-inline-block"></app-main-logo-only>
      </h1>
    </div>
    <div class="content">
      <app-text-view
        type="pageSubTitle"
        text="offline.line1"></app-text-view>
      <app-text-view text="offline.line2"></app-text-view>
    </div>
  </div>
</div>
