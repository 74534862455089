import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { TranslationService, SUPPORTED_LANGUAGES } from './../../translations/translation.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent {
  public actualLanguage: string;
  public isOpened: boolean = false;
  public SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES;

  constructor(
    private readonly translationService: TranslationService,
    private readonly translateService: TranslateService
  ) {
    this.actualLanguage = this.translateService.currentLang;
  }

  public changeLanguage(language: SUPPORTED_LANGUAGES): void {
    this.isOpened = false;
    this.translationService.proceedUsing(language);
    this.actualLanguage = language;
  }
}
