import { Component } from '@angular/core';

import { LANDING_PAGE_URL } from '../../utils/constants';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-email-not-supported',
  templateUrl: './email-not-supported.component.html',
  styleUrls: ['./email-not-supported.component.scss']
})
export class EmailNotSupportedComponent {
  LANDING_PAGE_URL = LANDING_PAGE_URL;
  constructor(private readonly authService: AuthService) {}

  login(): void {
    this.authService.login(LANDING_PAGE_URL);
  }
}
